/* eslint-disable */
import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Clipboard from 'clipboard';
import { saveAs } from 'file-saver';
import OptionMenu from './OptionMenu';
import { dataSlice } from './features/data/dataSlice';
import { ValidityType, textareaSlice } from './features/textarea/textareaSlice';
import { reduxForm, Field } from 'redux-form';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@mui/material/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { reset } from 'redux-form';
import style from './ControlsArea.module.scss';
import SegmentIcon from '@mui/icons-material/Segment';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';

import DialogContentText from '@mui/material/DialogContentText';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';

const ControlsArea = props => {
  const data = useSelector(state => state.data.data);
  const ftsfilter = useSelector(state => state.data.ftsfilter);
  const saveAsPlainJson = useSelector(state => state.data.saveAsPlainJson);
  const saveDocumentHandler = useSelector(
    state => state.data.saveDocumentHandler
  );
  const filter = useSelector(state => state.data.filter);
  const invalidSchemaKey = useSelector(state => state.data.invalidSchemaKey);

  useEffect(() => {
    // init clipboard
    new Clipboard('#copy-to-clipboard');
  }, []);

  const downloadJson = text => {
    const blob = new Blob([text], { type: 'application/json;charset=utf-8' });
    saveAs(blob, 'data.json');
  };

  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {
      schemaName: '',
      schemaDescription: '',
      embeddedType: false,
    },
  });

  const dispatch = useDispatch();
  const { setData } = dataSlice.actions;
  const { setLocalText } = textareaSlice.actions;
  const [recievedMessage, setRecievedMessage] = useState('');
  const [notifyFilter, setNotifyFilter] = useState();
  const [filterAlert, setFilterAlert] = useState(false);
  const [isSyntaxAlertOpen, setIsSyntaxAlertOpen] = useState(false);
  const [
    isEditSchemaWarningModalOpen,
    setIsEditSchemaWarningModalOpen,
  ] = useState(false);
  const [schemaMetadata, setSchemaMetadata] = useState(null);

  const fetchSchemaDescriptionDataById = async schemaId => {
    if (!schemaId) {
      return;
    }
    let res = null;
    try {
      res = await props.fetchSchemaMetadataById(schemaId);
      if (res) {
        setValue('schemaName', res.name || '');
        setValue('schemaDescription', res.description || '');
        setValue('embeddedType', !!res.embeddedType);
      }
    } catch (err) {
      console.error(err);
    }
    setSchemaMetadata(res);
  };

  const schemaId = useMemo(() => {
    /* if (props.hideSave) {
      return null;
    } */
    const schemaId = window.location.pathname.split('/')[2];
    if (schemaId) {
      if (!saveAsPlainJson) {
        fetchSchemaDescriptionDataById(schemaId);
      }
      return schemaId;
    }
    return null;
  }, [window?.location?.pathname]);

  const isEditMode = useMemo(() => {
    if (!schemaId) {
      return false;
    }
    /* if (schemaMetadata && schemaMetadata.type) {
      return true;
    } */
    return true;
  }, [schemaId, schemaMetadata?.type]);

  // useEffect(() => {
  //   window.addEventListener('message', (e) => {
  //     if (e.origin !== 'http://localhost:3000') return;
  //     setRecievedMessage(e.data);
  //   });
  // }, []);

  // const sendMessage = () => {
  //   window.parent.postMessage('Hi json!', 'http://localhost:3000');
  // };

  const text = useSelector(state => state.textarea.localText);
  const isEmpty = useMemo(() => {
    if (data) return Object.keys(data).length === 0;
    return text === 'null' || data === null;
  }, [text && text.length, data]);
  const isValid = useSelector(
    state => state.textarea.validity === ValidityType.Valid
  );

  const onDeleteButtonClicked = useCallback(() => {
    // dispatch(setData(null));
    dispatch(setLocalText('{}'));
    setRecievedMessage('{}');
  }, [dispatch, setData, setLocalText]);

  useEffect(() => {
    dispatch(setLocalText(recievedMessage));
  }, [recievedMessage]);

  // console.log(recievedMessage?.length);

  // if (recievedMessage?.length !== 0){
  //   setTimeout(function() {
  //     window.location.reload(false);
  //   },7000)
  // }

  const handleSave = () => {
    if (saveAsPlainJson) {
      console.log('saveAsPlainJson: ', saveAsPlainJson);
      if (!saveDocumentHandler) {
        return;
      }
      console.log('save data: ', data);
      saveDocumentHandler(data);
      return;
    }
    if (ftsfilter && ftsfilter.length < 1 && filter && filter.length < 1) {
      toggleNotifyFilterOpen();
      setNotifyFilter('FTS Filter and Filter');
    } else if (!ftsfilter || ftsfilter.length < 1) {
      toggleNotifyFilterOpen();
      setNotifyFilter('FTS Filter');
    } else if (!filter || filter.length < 1) {
      toggleNotifyFilterOpen();
      setNotifyFilter('Filter');
    } else if (invalidSchemaKey || invalidSchemaKey === '') {
      setIsSyntaxAlertOpen(true);
    } else props.handleModalOpen();
  };

  const handleSaveSchema = () => {
    const data = { ...getValues() };
    data['schemaId'] = schemaId;
    console.log('data: ', data);
    props.handleSubmit(data, isEditMode);
  };

  const toggleNotifyFilterClose = () => {
    setFilterAlert(false);
  };

  const toggleNotifyFilterOpen = () => {
    setFilterAlert(true);
  };

  const renderInvalidJsonSyntaxModal = () => {
    return (
      <Dialog
        open={isSyntaxAlertOpen}
        onClose={toggleNotifyFilterClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">The schema is invalid</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {'The Schema field "'}
            <b>{invalidSchemaKey}</b>
            {'"\n'}
            {' 1) Cannot be empty\n'}
            {' 2) An array should have only one item\n'}
            {' 3) Cannot start with a number\n'}
            {' 4) Cannot be "fts"\n'}
            {
              " 5) Cannot have special characters like '.', '-', '_', '/', '[' or ']'"
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSyntaxAlertOpen(false)} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderEditSchemaWarningModal = () => {
    return (
      <Dialog
        open={isEditSchemaWarningModalOpen}
        onClose={() => setIsEditSchemaWarningModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ whiteSpace: 'pre-wrap' }}
          ></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSyntaxAlertOpen(false)} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderNotifyFilter = () => {
    return (
      <Dialog
        open={filterAlert}
        onClose={toggleNotifyFilterClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Save schema could not be completed
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Atleast one <b>{notifyFilter}</b> field is required.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleNotifyFilterClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <nav className="navbar px-1 py-0" style={{ overflow: 'visible' }}>
        <ul className={['nav', style['controls-row']].join(' ')}>
          {/* <li className="nav-item">
                <button
                  id="copy-to-clipboard"
                  data-clipboard-target="#json-text"
                  disabled={!isValid}
                  className="btn btn-link"
                  title="Copy"
                >
                  <i className="far fa-copy" />
                </button>
              </li>
          */}
          {/* <button onClick={sendMessage}>S</button> */}
          <li className="nav-item">
            <button
              id="download"
              disabled={isEmpty || !isValid}
              className="btn btn-link"
              onClick={() => downloadJson(text)}
              title="Download"
            >
              <i className="fa fa-file-download" />
              {/* <FileDownloadOutlinedIcon /> */}
            </button>
          </li>
          <li className="nav-item">
            <button
              className="btn btn-link"
              disabled={isEmpty}
              onClick={onDeleteButtonClicked}
              title="Clear"
            >
              <i className="far fa-trash-alt" />
              {/* <DeleteOutlineOutlinedIcon /> */}
              {/* <NotInterestedOutlinedIcon style={{ fontSize: '20px' }} /> */}
            </button>
          </li>
          {/* <li className="nav-item">
            <button
              className="btn btn-link"
              disabled={isEmpty}
              onClick={() => props.formatJson()}
              title="Format JSON"
            >
              <SegmentIcon />
            </button>
          </li> */}
          <li className="nav-item">
            <button
              className="btn btn-link"
              onClick={() => {
                const { pasteSample } = dataSlice.actions;
                dispatch(pasteSample());
              }}
              title="Paste Sample JSON"
            >
              {/* <ContentPasteOutlinedIcon style={{ fontSize: '20px' }} /> */}
              <i className={['fa fa-paste', style['']].join(' ')}></i>
            </button>
          </li>
          {/* <li className="dropdown nav-item">
            <button
              className="btn btn-link"
              title="More"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="dropdownMenuButton"
            >
              <i className="fas fa-ellipsis-v" />
            </button>
            <OptionMenu ariaLabelledby="dropdownMenuButton" />
          </li> */}
          <li className="nav-item">
            {(saveAsPlainJson ||
              !isEditMode ||
              (isEditMode && schemaMetadata?.type === 1)) && (
              <button
                className={['btn btn-primary h-30 lh_1', style['btn--ml']].join(
                  ' '
                )}
                disabled={isEmpty}
                onClick={handleSave}
                title={
                  saveAsPlainJson
                    ? 'Save Document'
                    : (isEditMode ? 'Edit' : 'Save') + ' Schema'
                }
              >
                Save
              </button>
            )}
          </li>
        </ul>
        <ul className="nav justify-content-end">
          <li className="control-count nav-item">
            <span
              className={`text-count ${
                text && text.length > 0 && !isValid ? 'invalid' : ''
              }`}
            >
              {text && text.length}
            </span>
          </li>
        </ul>
      </nav>
      {renderNotifyFilter()}
      {renderInvalidJsonSyntaxModal()}
      <Dialog
        open={props.openModal}
        sx={{ '& .MuiDialog-paper': { width: '20%' } }}
      >
        <DialogTitle className="mb-11">Schema Details</DialogTitle>
        <form className="w-100">
          <DialogContent>
            <List
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                padding: 0,
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <div className="mb-10">
                    <Controller
                      name={'schemaName'}
                      control={control}
                      render={({ field: { onChange, value, name } }) => (
                        <TextField
                          className="Underline"
                          id={name}
                          name={name}
                          value={value}
                          onChange={onChange}
                          label={'Schema Name'}
                          variant="standard"
                          size="small"
                          disabled={isEditMode}
                        />
                      )}
                    />
                  </div>
                  <div className="my-10px">
                    <Controller
                      name={'schemaDescription'}
                      control={control}
                      render={({ field: { onChange, value, name } }) => (
                        <TextField
                          className="Underline"
                          id={name}
                          name={name}
                          value={value}
                          onChange={onChange}
                          label={'Schema Description'}
                          variant="standard"
                          size="small"
                        />
                      )}
                    />
                  </div>
                  <div className="my-10px">
                    <Controller
                      name={'embeddedType'}
                      control={control}
                      render={({ field: { onChange, value, name } }) => (
                        <FormControlLabel
                          label="Save as type"
                          control={
                            <Checkbox
                              id={name}
                              name={name}
                              value={value}
                              onChange={onChange}
                              type="checkbox"
                              color="primary"
                              label={'Save as type'}
                            />
                          }
                        />
                      )}
                    />
                  </div>
                </Grid>
              </Grid>
            </List>
          </DialogContent>
          <DialogActions className="mb-12">
            <Button variant="outlined" onClick={props.handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSaveSchema}>
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default reduxForm({
  form: 'addDetailsForm',
  enableReinitialize: true,
  // validate
})(ControlsArea);
