export const UPLOAD_FILE_REQUESTED = 'berrydb/UPLOAD_FILE_REQUESTED';
export const UPLOAD_FILE_SUCCESS = 'berrydb/UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'berrydb/UPLOAD_FILE_FAILURE';

const initialState = {
  file: null,
  databaseList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_FILE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPLOAD_FILE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }

    default:
      return state;
  }
};

export const uploadFileData = (formData) => {
  return {
    types: [UPLOAD_FILE_REQUESTED, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAILURE],
    promise: (client) => client.post('uploadFileData', {
      data: formData,
    })
  };
};
