/* eslint-disable */
import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextArea from './TextArea';
import VisualizedData from './VisualizedData/VisualizedData';
import { Header } from './Header';
import { textareaSlice } from './features/textarea/textareaSlice';
import { dataSlice } from './features/data/dataSlice';
import { getSchemaDetails } from './reducers/schema';
import { getSchemasList, getSchemasDataTypes } from './reducers/database';
import { getPipelinesExtractTypes } from './reducers/pipelines';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { data } from 'jquery';
import { reduxForm } from 'redux-form';
import NotificationMessage from '@components/NotificationMessage';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CircularProgress from '@material-ui/core/CircularProgress';

const minFrameWidth = 100;
const rectifyWidth = 204;

const Page = props => {
  const isDragging = useSelector(state => state.textarea.isDragging);
  const width = useSelector(state => state.textarea.width);

  const { setWidth, setDragging } = textareaSlice.actions;
  const [openModal, setModal] = useState(false);
  const [msg, setMsg] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    setData,
    setInitialFilter,
    setDisplayTextField,
    setDisplayImageField,
  } = dataSlice.actions;
  const { setLocalText } = textareaSlice.actions;

  // write here
  const onMouseDown = useCallback(() => {
    dispatch(setDragging(true));
  }, [setDragging, dispatch]);
  const onMouseUpOrLeave = useCallback(() => {
    dispatch(setDragging(false));
  }, [setDragging, dispatch]);
  const onMouseMove = useCallback(
    event => {
      if (isDragging) {
        const newWidth = event.clientX - rectifyWidth;
        if (newWidth > minFrameWidth) {
          dispatch(setWidth(newWidth));
        }
      }
    },
    [isDragging, setWidth, dispatch]
  );
  const [showOverlay, setShowOverlay] = useState(false);

  const primitiveDataTypes = [
    { id: 0, name: 'boolean', primitive: true, default: false },
    { id: 0, name: 'string', primitive: true, default: '' },
    { id: 0, name: 'number', primitive: true, default: 0 },
    { id: 0, name: 'array', primitive: true, default: [] },
    { id: 0, name: 'object', primitive: true, default: {} },
    // { 'id': 0, 'name': 'reference', primitive: true, default: "<reference>" },
    { id: 0, name: 'date', primitive: true, default: '<date>' },
  ];
  const [schemaInputTypeList, setSchemaInputTypeList] = useState(
    primitiveDataTypes
  );
  const [pipelinesExtractTypes, setPipelinesExtractTypes] = useState({});
  const [pipelineFields, setPipelineFields] = useState({});
  const [rawSchemaCache, setRawSchemaCache] = useState({});

  const getTypeRawJson = schemaId => {
    if (rawSchemaCache[schemaId]) {
      console.log('raw schema: ', rawSchemaCache[schemaId]);
      return rawSchemaCache[schemaId];
    }
    return getRawSchemaBySchemaId(schemaId);
  };

  const getRawSchemaBySchemaId = async schemaId => {
    setShowOverlay(true);
    try {
      const rawSchema = (await props.getSchemaDetails(schemaId)) || [];
      if (
        rawSchema &&
        rawSchema.responseList &&
        rawSchema.responseList.length &&
        rawSchema.responseList[0].rawSchema
      ) {
        const res = rawSchema.responseList[0].rawSchema;
        setRawSchemaCache(prevMap => {
          prevMap[schemaId] = res;
          return prevMap;
        });
        return res;
      }
      return;
    } finally {
      setShowOverlay(false);
    }
  };

  const leadingStyle = useMemo(() => {
    return {
      width,
    };
  }, [width]);

  useEffect(() => {
    if (window.innerWidth < 576) {
      dispatch(setWidth(window.innerWidth));
    }
  }, [dispatch, setWidth]);

  useEffect(() => {
    fetchAllEmbeddedSchemaTypes();
    getPipelinesExtractTypes();
  }, []);

  const handleAttachPipeline = (
    pipelinePath,
    dataType,
    extractionType,
    labels = null
  ) => {
    if (!pipelinePath || !dataType || !extractionType) {
      return;
    }
    const newPipelineFields = { ...(pipelineFields || {}) };
    const newPipelineObj = {
      dataType: dataType,
      extract: extractionType,
    };
    if (labels?.length) {
      newPipelineObj['labels'] = labels;
    }
    if (newPipelineFields[pipelinePath]) {
      newPipelineFields[pipelinePath] = [
        ...newPipelineFields[pipelinePath]?.filter(
          pf => pf.extract !== extractionType
        ),
        newPipelineObj,
      ];
    } else {
      newPipelineFields[pipelinePath] = [newPipelineObj];
    }
    setPipelineFields(newPipelineFields);
  };

  const handleRemovePipeline = (pipelinePath, extractionType) => {
    if (!pipelinePath || !extractionType || !pipelineFields[pipelinePath]) {
      return;
    }
    const newPipelineFields = { ...(pipelineFields || {}) };
    newPipelineFields[pipelinePath] = [
      ...newPipelineFields[pipelinePath]?.filter(
        pf => pf.extract !== extractionType
      ),
    ];
    if (!newPipelineFields[pipelinePath]?.length) {
      delete newPipelineFields[pipelinePath];
    }
    setPipelineFields(newPipelineFields);
  };

  const getPipelinesExtractTypes = dataType => {
    const apiKey = window.localStorage.getItem('apiKey');
    props
      .getPipelinesExtractTypes(apiKey, dataType)
      .then(data => {
        setPipelinesExtractTypes(data);
      })
      .catch(e => console.error(e));
  };

  const fetchAllEmbeddedSchemaTypes = () => {
    const userId = window.localStorage.getItem('userSurrogateId');
    if (!userId) {
      history.push('/');
    }
    setShowOverlay(true);
    props
      .getSchemasDataTypes(userId)
      .then(data => {
        setSchemaInputTypeList([
          ...primitiveDataTypes,
          ...(data.responseList || []).sort((a, b) => a.type - b.type),
        ]);
      })
      .finally(() => setShowOverlay(false));
  };

  useEffect(() => {
    const schemaId = window.location.pathname.split('/')[2];
    props.getSchemaDetails(schemaId > 0 ? schemaId : null).then(data => {
      const res =
        data.responseList && data.responseList.length
          ? data.responseList[0]
          : {};
      const filters = {
        filterValues: res.filterFields || [],
        ftsFilterValues: res.indexFields || [],
      };
      dispatch(setInitialFilter(filters));
      dispatch(setDisplayTextField({ displayTextField: res.displayTextField }));
      dispatch(
        setDisplayImageField({ displayImageField: res.displayImageField })
      );
      const newPipelineFields = data?.responseList?.[0]?.pipelineFields || {};
      if (newPipelineFields && Object.keys(newPipelineFields)?.length) {
        const sanitizedPipelineFields = {};
        Object.keys(newPipelineFields).map(key => {
          sanitizedPipelineFields[key.replace(/[0]\./g, '.0.')] =
            newPipelineFields[key];
        });
        setPipelineFields(sanitizedPipelineFields);
      }
    });
  }, [props.schemaList.length]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setModal(false);
  };

  const handleOpen = msg => {
    setModal(true);
    setMsg(msg);
  };

  return (
    <>
      {/* <Header /> */}
      {showOverlay && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#00000055',
            // 1300 as the sidebar is 1299
            zIndex: '1300',
          }}
        >
          <CircularProgress
            style={{ position: 'absolute', bottom: '40%', right: '45%' }}
          />
        </div>
      )}
      <div className="mt-40 p-10 pb-2" style={{ fontSize: '16px' }}>
        <WarningAmberIcon
          style={{
            color: '#ffc107',
            fontSize: '24px',
            marginBottom: '3px',
            marginRight: '5px',
          }}
        />
        <b>{'Note: '}</b>A schema should have at least one full text search
        (FTS) index field and one Filter field selected.{' '}
        {/* The fields selected
        must be top level fields in the JSON document */}
      </div>
      <main
        className="d-flex flex-sm-row flex-column flex-grow-1"
        style={{ height: '80vh' }}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUpOrLeave}
        onMouseLeave={onMouseUpOrLeave}
      >
        <div
          className="leading-side d-flex flex-column p-2"
          style={leadingStyle}
        >
          {props.schemaList.length > 0 && (
            <TextArea
              handleSuccessNotification={handleOpen}
              pipelineFields={pipelineFields}
            />
          )}
        </div>
        <div
          className="adjuster d-flex justify-content-center align-items-center"
          onMouseDown={onMouseDown}
        >
          <i className="fas fa-ellipsis-v" />
        </div>
        <div className="trailing-side d-flex flex-column flex-grow-1 pr-2 pl-1">
          <div className="flex-grow-1">
            <div className="scroll-wrapper">
              {props.schemaList.length > 0 && (
                <VisualizedData
                  schemaDetails={props.schemaList[0]}
                  schemaInputTypeList={schemaInputTypeList}
                  getTypeRawJson={getTypeRawJson}
                  fetchAllEmbeddedSchemaTypes={fetchAllEmbeddedSchemaTypes}
                  pipelinesExtractTypes={pipelinesExtractTypes}
                  getPipelinesExtractTypes={getPipelinesExtractTypes}
                  pipelineFields={pipelineFields}
                  handleAttachPipeline={handleAttachPipeline}
                  handleRemovePipeline={handleRemovePipeline}
                />
              )}
            </div>
          </div>
        </div>
        <NotificationMessage
          open={openModal}
          msg={msg}
          msgType={msg ? 'success' : 'error'}
          handleClose={handleClose}
        />
      </main>
    </>
  );
};

const mapStateToProps = state => {
  return {
    schemaList: state.schema.schemaDetails,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSchemaDetails,
      getSchemasList,
      getSchemasDataTypes,
      getPipelinesExtractTypes,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page));
