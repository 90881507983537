/* eslint-disable */
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Box from '@mui/material/Box';
import cn from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
// import { withRouter } from 'react-router';
import camera from '@assets/icons/c1.png';
import userIcon from '@assets/images/default-image.png';
import NotificationMessage from '@components/NotificationMessage';
import CommonTab from '@components/common/CommonTabs';
import TableForm from '@components/common/TableForm';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { fabric } from 'fabric';
import moment from 'moment-timezone';
import ReactJson from 'react-json-view';
import TextArea from '../../TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { textareaSlice } from '../../features/textarea/textareaSlice';
import VisualizedData from '../../VisualizedData/VisualizedData';
import Page from '../../Page';
import App from '../../App';
import JsonEditor from '../../components/JsonEditor/JsonEditor';
import JsonEditorContainer from '../../components/JsonEditor/JsonEditorContainer';
import { dataSlice } from '../../features/data/dataSlice';
import ReactPlayer from 'react-player';
import { Field, reduxForm } from 'redux-form';
import { capitalize } from '../../helpers';
import { Wrapper } from '../../components';
import { countUploadTime, getValueByPath } from '../../helpers/utils';
import styles from './Details.module.scss';
import EN from '../../localization/EN';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles(theme => ({
  card: {
    overflow: 'visible',
  },
  imageHeader: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    },
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(1) / -2,
      left: theme.spacing(1) / -2,
      boxShadow: theme.shadows[5],
      borderRadius: theme.shape.borderRadius,
    },
    // minHeight: '243px',
    // height: '100%',
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${process.env.PUBLIC_URL}/static/images/unsplash/fashion3.jpg)`,
    backgroundPosition: 'top',
  },
  tabContainer: {
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
  },
  tabRoot: {
    textTransform: 'initial',
    color: theme.palette.text.primary,
  },
  tabsRoot: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  inactive: {
    color: theme.palette.text.secondary,
  },
  group: {
    display: 'inline-block',
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
  },
  groupButton: {
    borderRadius: 0,
    boxShadow: 'none',
    minWidth: '40px',
    '&:first-child': {
      borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
    },
    '&:last-child': {
      borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
    },
  },
  optionTitle: {
    minWidth: '50px',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      top: 20,
    },
  },
};

const Detail = props => {
  const canvasRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [primaryValue, setValue] = useState([]);
  const [urlList, updateUrlList] = useState([]);
  const theme = useTheme();
  const { onSubmit, initialValues } = props;
  const isEditMode = props.isEditDetailsEnabled;

  const history = useHistory();

  const {
    setData,
    setInitialFilter,
    setSaveAsPlainJson,
    setSaveDocumentHandler,
  } = dataSlice.actions;
  const metadata = props.metadata && props.metadata.fieldsByDataType;

  const renderTextField = ({
    input,
    label,
    value,
    meta: { touched, error },
    ...custom
  }) => (
    <TextField
      variant="standard"
      id={label}
      name={label}
      label={label}
      floatingLabelText={label}
      errorText={touched && error}
      defaultValue={value}
      disabled={!props.editable ? true : false}
      {...input}
      {...custom}
    />
  );

  const renderSelectField = ({
    input,
    label,
    defaultValue,
    meta: { touched, error },
    children,
    ...custom
  }) => (
    <Select
      id={label}
      name={label}
      displayEmpty
      className={'py-10'}
      value={defaultValue}
      onChange={handleChange}
      variant="outlined"
      disabled={!props.editable ? true : false}
      {...input}
      renderValue={selected => {
        if (selected.length === 0) {
          return <Typography>{defaultValue ? defaultValue : label}</Typography>;
        }
        return selected;
      }}
      MenuProps={MenuProps}
      inputProps={{ 'aria-label': 'Without label' }}
      children={children}
      {...custom}
    ></Select>
  );

  const handleChange = event => {
    const {
      target: { value },
    } = event;
    setValue(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const renderProfile = () => {
    const itemType = props.itemDetails && props.itemDetails.type;
    const urls = props.itemDetails && props.itemDetails.urls;
    const thumbnail =
      (metadata &&
        metadata.displayImageField &&
        metadata.displayImageField.length &&
        getValueByPath(props.itemDetails, metadata.displayImageField[0])) ||
      props.thumbnail;
    return (
      <>
        <div className={cn(styles.img_normal)}>
          <div className={cn(styles.profile_image)}>
            <img
              src={
                !thumbnail
                  ? itemType === 'image'
                    ? urls[0]
                    : userIcon
                  : thumbnail
              }
              className={cn(styles.profile_image)}
              alt=""
            />
          </div>
          {props.editable && (
            <div
              data-toggle="dropdown"
              className={cn(styles.top_right, 'px-8 cursor')}
            >
              <img className={styles.cameraStyle} src={camera} alt="camera" />
            </div>
          )}
          <div className="pt-0 dropdown-menu dropdown-menu-right">
            <label
              htmlFor="thumbnail"
              className={cn(styles.dropdown_Item, 'dropdown-item')}
            >
              Upload Photo
            </label>
            <input
              id="thumbnail"
              type="file"
              accept=".jpg, .jpeg, .png"
              className={styles.upload_photo}
              onChange={props.uploadThumbnail}
            />
            <div
              role="button"
              className={cn(styles.dropdown_Item, 'dropdown-item')}
              onClick={() => props.removeProfilePhoto()}
              tabIndex={0}
              onKeyPress={() => props.removeProfilePhoto()}
            >
              {EN.REMOVE_PHOTO}
            </div>
          </div>
        </div>
      </>
    );
  };

  const getTabContent = () => {
    return (
      <>
        {props.activeTabId === 0 && getFirstTabContent()}
        {props.activeTabId === 1 && getAnnotationTabContent()}
        {props.activeTabId === 2 && getRawDataTabContent()}
      </>
    );
  };

  const removeItemFromUrl = key => {
    const tempUrlList =
      urlList.length > 0
        ? [...urlList]
        : [...(props.itemDetails && props.itemDetails.urls)];
    tempUrlList.splice(key, 1);
    updateUrlList(tempUrlList);
  };

  const getRawDataTabContent = () => {
    return (
      <Grid container spacing={0} direction="row" alignItems="stretch">
        <Grid item xs={12} sm={12} md={12} className="">
          {renderDetailsAsJson(props.itemDetails)}
        </Grid>
      </Grid>
    );
  };

  const getAnnotationTags = annotation => {
    const annotationRespList = [];
    annotation.result.map((el, index) => {
      const tagKey = el.type;
      const text = (el.value && el.value.text) || '';
      // const text = el.value && el.value.text ? (el.value.text.length ? el.value.text[0] : el.value.text) : '';
      const valueText =
        (el.value && el.value[tagKey] && el.value[tagKey]) || '';
      if (text) {
        annotationRespList.push({
          type: valueText || tagKey,
          value: text,
          result: el,
        });
      } else {
        annotationRespList.push({
          type: tagKey,
          value: valueText,
          result: el,
        });
      }
    });
    console.log(annotation);
    console.log(props.annotationTags);
    let respObj = { ...props.annotationTags };
    respObj.responseList = respObj.responseList.concat(annotationRespList);
    console.log(respObj);
    return respObj;
  };

  const getAnnotationTabContent = () => {
    const annotationList = props.itemDetails && props.itemDetails.annotations;
    //const annotation = annotationList.length > 0 && annotationList[0] ;

    // const annotationsDetails = annotations && props.itemDetails.annotations[0];
    // const itemUploadedBy = props.itemDetails && props.itemDetails.userEmail;
    return annotationList && annotationList.length > 0 ? (
      <Grid container spacing={0} direction="row" alignItems="stretch">
        <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '1px' }}>
          {renderDetailsAsJson(annotationList, true, true)}
        </Grid>
        <Grid item xs={12} sm={12} md={12} className="">
          {annotationList.map(annotation => (
            <>
              <div className="my-10">
                <Typography variant="subtitle" gutterBottom>
                  <b>Annotated By: &nbsp;</b>
                  {props.itemDetails && props.itemDetails.userEmail}
                </Typography>
              </div>
              <div className="my-10">
                <Typography variant="subtitle" gutterBottom>
                  <b>Created At: &nbsp;</b>
                  {countUploadTime(annotation.created_at)}
                </Typography>
              </div>
              {/* <Grid container>
                <Grid item xs={12} sm={6} md={10} className="my-10">
                  <div>
                    <TableForm
                      formItem={getAnnotationTags(annotation)}
                      apiCallhappened={props.apiCallhappened}
                      enableEdit={props.editable}
                      expanded
                    />
                  </div>
                </Grid>
              </Grid> */}
              {/* {renderAnnotationsOnImage()} */}
              {/* <div className="my-10">
                {renderAnnotationsOnText()}
              </div> */}
            </>
          ))}
        </Grid>
      </Grid>
    ) : (
      <div className="my-10">
        <Typography variant="subtitle" gutterBottom>
          No annotations available
        </Typography>
      </div>
    );
  };

  const renderAnnotationsOnImage = () => {
    const imageHeight =
      props?.annotationTags?.responseList?.[0]?.result?.original_height;
    const imageWidth =
      props?.annotationTags?.responseList?.[0]?.result?.original_width;
    return (
      <div className="my-10">
        <canvas
          ref={canvasRef}
          height={imageHeight}
          width={imageWidth}
        ></canvas>
      </div>
    );
  };

  const textBasedAnnotaions = useMemo(() => {
    if (
      !(
        props.annotationTags &&
        props.annotationTags.responseList &&
        props.annotationTags.responseList.length
      )
    ) {
      return null;
    }
    return props.annotationTags.responseList.filter(r => r.type === 'labels');
  }, [props.annotationTags?.responseList]);

  const renderAnnotationsOnText = () => {
    if (!(textBasedAnnotaions && textBasedAnnotaions.length)) {
      return <></>;
    }
    const result = [];
    textBasedAnnotaions.forEach((anno, i) => {
      if (!(anno.result && anno.result.value)) {
        return;
      }
      const typeKey = 'labels';
      const { start, end } = anno.result.value;
      const label = anno.result.value[typeKey];

      result.push({ start, end, label });
    });

    return (
      <div className={classes.textAnnotations}>
        {result &&
          result.map(res => (
            <div className={classes.textAnnotation}>{res.label}</div>
          ))}
      </div>
    );
  };

  const handleDetailsSave = jsonData => {
    const databaseId = props.itemDetails.databaseId;
    if (!databaseId) {
      return;
    }
    /* if (props.activeTabId == 1) {
      const annotations = jsonData;
      const newJsonData = props.itemDetails;
      newJsonData['annotations'] = annotations;
      jsonData = newJsonData;
    } */
    onSubmit([jsonData], databaseId);
  };

  useEffect(() => {
    if (!props.itemDetails) {
      return;
    }
    dispatch(setData(props.itemDetails));
    // dispatch(setData(props.activeTabId === 1 ? props.itemDetails.annotations : props.itemDetails));
    dispatch(setSaveAsPlainJson(true));
    dispatch(setSaveDocumentHandler(handleDetailsSave));
    return () => {
      dispatch(setData(null));
      dispatch(setSaveAsPlainJson(false));
      dispatch(setSaveDocumentHandler(null));
    };
  }, [props.itemDetails, props.activeTabId]);

  useEffect(() => {
    if (!isEditMode) {
      return;
    }
    if (props.activeTabId != 2) {
      props.setEditDetailsEnabled(false);
    }
  }, [props.activeTabId, isEditMode]);

  useEffect(() => {
    if (
      !(
        props.activeTabId == 1 &&
        canvasRef &&
        props.annotationTags &&
        props.annotationTags.responseList &&
        props.annotationTags.responseList.length
      )
    ) {
      return;
    }
    if (
      !(
        props &&
        props.itemDetails &&
        metadata &&
        metadata.image &&
        metadata.image.length &&
        metadata.image[0] === 'image' &&
        props.itemDetails.url
      )
    ) {
      return;
    }

    const canvas = new fabric.Canvas(canvasRef.current);

    // console.log('props.itemDetails: ', props.itemDetails);
    console.log(
      'props.annotationTags.responseList: ',
      props.annotationTags.responseList
    );

    if (!props.annotationTags.responseList[0].result) {
      return;
    }

    const imageHeight =
      props.annotationTags.responseList[0].result.original_height;
    const imageWidth =
      props.annotationTags.responseList[0].result.original_width;
    const h_multiplier = imageHeight * 0.01;
    const w_multiplier = imageWidth * 0.01;

    console.log('Image URL: ', props.itemDetails.url);

    fabric.Image.fromURL(props.itemDetails.url, function(img) {
      img.set({
        height: imageHeight,
        width: imageWidth,
        selectable: false,
        evented: false,
      });
      canvas.add(img);
      canvas.sendToBack(img);
    });

    const getRandomColor = () => {
      const opacity = 0.3;
      const colors = [
        `rgba(255, 0, 0, ${opacity})`,
        `rgba(0, 255, 0, ${opacity})`,
        `rgba(0, 0, 255, ${opacity})`,
        `rgba(155, 0, 155, ${opacity})`,
        `rgba(195, 20, 105, ${opacity})`,
        `rgba(15, 60, 55, ${opacity})`,
        `rgba(15, 250, 55, ${opacity})`,
        `rgba(111, 11, 255, ${opacity})`,
      ];
      return colors[Math.floor(Math.random() * 10) % colors.length];
    };

    const addRect = (rX, rY, width, height, label) => {
      const color = getRandomColor();
      rX = rX * w_multiplier;
      rY = rY * h_multiplier;
      width = width * w_multiplier;
      height = height * h_multiplier;

      const rect = new fabric.Rect({
        left: rX,
        top: rY,
        width: width,
        height: height,
        fill: color,
        stroke: color,
        strokeWidth: 2,
        selectable: false,
        evented: false,
      });

      var text = new fabric.Textbox(label || '', {
        left: rX,
        top: rY,
        width: width,
        fontSize: 14,
        // backgroundColor: 'white',
        fill: 'black',
        textAlign: 'left',
        selectable: false,
        evented: false,
        editable: false,
      });

      canvas.add(text);
      canvas.add(rect);
    };

    const addPolygons = (polygonPoints, label) => {
      const color = getRandomColor();

      var polygon = new fabric.Polygon(polygonPoints, {
        fill: color,
        stroke: color,
        strokeWidth: 2,
        selectable: false,
        evented: false,
        editable: false,
      });
      canvas.add(polygon);
    };

    const annotationTypes = {
      RECT: 'rectanglelabels',
      POLY: 'polygonlabels',
    };

    // Add the annotions from here
    props.annotationTags.responseList.map(a => {
      const value = a.result && a.result.value;
      if (!value) {
        return;
      }
      const typeKey = a.result.type;
      const label = (value[typeKey] && value[typeKey][0]) || '';
      if (typeKey === annotationTypes.RECT) {
        addRect(value.x, value.y, value.width, value.height, label);
      } else if (typeKey === annotationTypes.POLY) {
        const points = (value.points || []).map(p => {
          return {
            x: (p[0] || 0) * w_multiplier,
            y: (p[1] || 0) * h_multiplier,
          };
        });
        addPolygons(points, label);
      }
    });
    canvas.renderAll();
  }, [canvasRef, props.itemDetails, props.activeTabId]);

  const getLeftPortionContent = () => {
    const itemUploadedTime =
      props.itemDetails && props.itemDetails.uploadedTime;
    const itemUploadedBy =
      props.itemDetails &&
      props.itemDetails._meta &&
      props.itemDetails._meta.userEmail;
    const itemType = props.itemDetails && props.itemDetails.type;
    const itemUploadType =
      props.itemDetails &&
      props.itemDetails._meta &&
      props.itemDetails._meta.uploadType;
    const itemDHash = props.itemDetails && props.itemDetails.dHash;
    const objectType = [];
    if (metadata) {
      metadata.image && metadata.image.length ? objectType.push('Image') : null;
      metadata.video && metadata.video.length ? objectType.push('Video') : null;
      metadata.audio && metadata.audio.length ? objectType.push('Audio') : null;
      (metadata.text && metadata.text.length) || !objectType.length
        ? objectType.push('Text')
        : null;
    }
    return (
      <Grid container className="mt-15">
        <Grid item xs={12} sm={12} md={12}>
          <div className={`my-10 ${styles['back-button-container']}`}>
            {/* <Button
                // variant="contained"
                // color="primary"
                type="button"

              >
              </Button> */}
            <ArrowBackIcon onClick={() => history.push(`/search`)} />
          </div>
          <div className={classes.imageHeader}>{renderProfile()}</div>
          <div className="my-10">
            <Typography variant="subtitle" gutterBottom>
              <b>Uploaded Time: &nbsp;</b>
              {moment
                .utc(itemUploadedTime)
                .tz(moment.tz.guess(true))
                .format('ll')}
            </Typography>
          </div>
          <div className="my-10">
            <Typography variant="subtitle" gutterBottom>
              <b>Object Type: &nbsp;</b>
              {objectType.join(', ')}
            </Typography>
          </div>
          <div className="my-10">
            <Typography variant="subtitle" gutterBottom>
              <b>Upload Type: &nbsp;</b>
              {itemUploadType}
            </Typography>
          </div>
          <div className="my-10">
            <Typography variant="subtitle" gutterBottom>
              <b>Uploaded By: &nbsp;</b>
              {itemUploadedBy}
            </Typography>
          </div>
          <div className="my-10">
            <Typography variant="subtitle" gutterBottom>
              <b>Database Name: &nbsp;</b>
              {props.itemDetails.databaseName}
            </Typography>
          </div>
          <div className="my-10">
            <Typography variant="subtitle" gutterBottom>
              <b>Schema Name: &nbsp;</b>
              {props.metadata && props.metadata.schemaName}
            </Typography>
          </div>
          {itemDHash && (
            <div>
              <Typography variant="subtitle" gutterBottom>
                <b>Fingerprint: &nbsp;</b>
                {itemDHash}
              </Typography>
            </div>
          )}
          {!isEditMode && (
            <div className="my-10">
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={() => props.setEditDetailsEnabled(true)}
              >
                Edit
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    );
  };

  const getFirstTabContent = () => {
    const itemDetails = props.itemDetails;
    const itemTitle =
      metadata &&
      ((metadata.displayTextField &&
        metadata.displayTextField.length &&
        metadata.displayTextField[0]) ||
        (metadata.fts && metadata.fts.length && metadata.fts[0]) ||
        '');
    if (!metadata) {
      return <></>;
    }
    return (
      <>
        <Grid container spacing={0} direction="row" alignItems="stretch">
          <Grid item xs={12} sm={12} md={12} className="p-1r">
            <div className="my-10px">
              <Field
                name={itemTitle}
                component={renderTextField}
                defaultValue={itemTitle && props.itemDetails[itemTitle]}
                label={itemTitle}
                readOnly={!props.editable ? true : false}
                className="w-45"
              />
            </div>
            {/* <div>
            <Field
              name="description"
              component={renderTextField}
              label="Description"
              multiline
              maxRows={7}
              defaultValue={itemDesc}
              className="setting_option w-85" />
          </div> */}
            {/* <div className="my-10px d-flex alignItemsCenter">
              {props.filterDataList.length > 0 &&
                props.filterDataList.map(filterDataKey => {
                  return (
                    <>
                      <Typography variant="subtitle">
                        {' '}
                        <b>{filterDataKey[0]}: &nbsp;</b>
                      </Typography>
                      <Box className="w-25">
                        <FormControl className="w-100">
                          <Field
                            name={filterDataKey[0]}
                            component={renderSelectField}
                            label={`Select ${filterDataKey[0]}`}
                            defaultValue="abs"
                          >
                            <MenuItem disabled value="">
                              <em>{`Select ${filterDataKey[0]}`}</em>
                            </MenuItem>
                            {filterDataKey[1] &&
                              filterDataKey[1].map(item => (
                                <MenuItem
                                  key={item}
                                  value={item}
                                  style={getStyles(item, primaryValue, theme)}
                                >
                                  {item}
                                </MenuItem>
                              ))}
                          </Field>
                        </FormControl>
                      </Box>
                      &nbsp;
                    </>
                  );
                })}
            </div> */}

            <div className="my-10px">
              {metadata.image &&
                metadata.image.length &&
                itemDetails[metadata.image[0]] &&
                itemDetails[metadata.image[0]].length && (
                  <Grid container className="d-flex">
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      className="p-5x positionRelative"
                    >
                      <Typography variant="subtitle">
                        <b>{capitalize(metadata.image[0])}: &nbsp;</b>
                      </Typography>
                      <br />
                      {itemDetails[metadata.image[0]].map(img => {
                        return (
                          <img
                            src={img.url}
                            width="100px"
                            height="100px"
                            alt="url"
                            className={cn(styles.cardStyle, 'objectFit')}
                          ></img>
                        );
                      })}
                    </Grid>
                  </Grid>
                )}
              {metadata.video &&
                metadata.video.length &&
                itemDetails[metadata.video[0]] &&
                itemDetails[metadata.video[0]].length && (
                  <Grid container className="d-flex">
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      className="p-5x positionRelative"
                    >
                      <Typography variant="subtitle">
                        <b>{capitalize(metadata.video[0])}: &nbsp;</b>
                      </Typography>
                      {itemDetails[metadata.video[0]].map(video => {
                        return (
                          <ReactPlayer
                            id="sourceVideo"
                            style={{ zIndex: '0', position: 'relative' }}
                            className={cn('react-player pointerNone')}
                            width="100%"
                            height="100%"
                            controls={true}
                            url={video.url}
                          />
                        );
                      })}
                    </Grid>
                  </Grid>
                )}
              &nbsp;
              {metadata.audio &&
                metadata.audio.length &&
                itemDetails[metadata.audio[0]] &&
                itemDetails[metadata.audio[0]].length && (
                  <Grid container className="d-flex">
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      className="p-5x positionRelative"
                    >
                      <Typography variant="subtitle">
                        <b>{capitalize(metadata.audio[0])}: &nbsp;</b>
                      </Typography>
                      {itemDetails[metadata.audio[0]].map(audio => {
                        return (
                          <ReactPlayer
                            id="sourceVideo"
                            style={{ zIndex: '0', position: 'relative' }}
                            className={cn('react-player pointerNone')}
                            width="100%"
                            height="100%"
                            controls={true}
                            url={audio.url}
                          />
                        );
                      })}
                    </Grid>
                  </Grid>
                )}
              &nbsp;
            </div>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} className="">
                <div>
                  <TableForm
                    formItem={props.applicationTagsForForm}
                    enableEdit={props.editable}
                    filteredTags={props.filteredTags}
                    apiCallhappened={props.apiCallhappened}
                    expanded
                  />
                </div>
              </Grid>
            </Grid>
            {/* </> */}
          </Grid>
        </Grid>
      </>
    );
  };

  const renderDetailsAsJson = (
    jsonData,
    addMarginTop = false,
    readOnly = false
  ) => {
    if (!jsonData) {
      return <></>;
    }
    return isEditMode && !readOnly ? (
      <>
        <Grid item xs={6} sm={6} md={6} className="mb-2">
          {/* <Button
            variant="outlined"
            color="primary"
            type="button"
            className="ml-2 bg-white"
            onClick={() => props.setEditDetailsEnabled(false)}
          >
            Cancel Edit
          </Button> */}
          <div
            className={[styles['btn'], styles['btn--blue']].join(' ')}
            onClick={() => {
              props.setEditDetailsEnabled(false);
              dispatch(setData(props.itemDetails));
            }}
          >
            {/* <ClearIcon className={styles['btn--icon']} style={{ fontSize: '18px' }} /> */}
            Cancel Edit
          </div>
        </Grid>
        <JsonEditorContainer hideFilters={true} />
      </>
    ) : (
      <div
        className={[
          styles['json-container'],
          addMarginTop ? styles['json-container--mt-15'] : '',
        ].join(' ')}
      >
        <ReactJson
          src={jsonData}
          name={false}
          shouldCollapse={({ name }) =>
            name === '_meta' || name === 'annotations' || name === 'result'
          }
        />
      </div>
    );

    /* (
      <div
        className={[
          styles['json-container'],
          addMarginTop ? styles['json-container--mt-15'] : '',
        ].join(' ')}
      >
        <main
          className="d-flex flex-sm-row flex-column flex-grow-1"
          style={{ height: '100vh' }}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUpOrLeave}
          onMouseLeave={onMouseUpOrLeave}
        >
          <div
            className="leading-side d-flex flex-column p-2"
            style={leadingStyle}
          >
            <TextArea />
          </div>
          <div
            className="adjuster d-flex justify-content-center align-items-center"
            onMouseDown={onMouseDown}
          >
            <i className="fas fa-ellipsis-v" />
          </div>
          <div className="trailing-side d-flex flex-column flex-grow-1 pr-2 pl-1">
            <div className="flex-grow-1">
              <div className="scroll-wrapper">
                <VisualizedData />
              </div>
            </div>
          </div>
        </main>
      </div>) */
    return (
      <div
        className={[
          styles['json-container'],
          addMarginTop ? styles['json-container--mt-15'] : '',
        ].join(' ')}
      >
        <ReactJson src={jsonData} />
      </div>
    );
  };

  return (
    <Wrapper>
      <Card className={classes.card}>
        {/* <form onSubmit={handleSubmit} initialValues={initialValues}> */}
        <Grid
          container
          className={[isEditMode ? '' : 'px-10p', 'py-40'].join(' ')}
        >
          {props.isLoading ? (
            <CircularProgress
              style={{
                position: 'absolute',
                top: '40%',
                right: isEditMode ? '0%' : '45%',
              }}
            />
          ) : (
            <>
              {!isEditMode && (
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  {getLeftPortionContent()}
                  {/*!props.editable &&
        <form onSubmit={handleSubmit} initialValues={initialValues}>
          <Grid container className="px-10p py-40">
            {props.isLoading ? (
              <CircularProgress
                style={{ position: 'absolute', top: '40%', right: '45%' }}
              />
            ) : (
              <>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  {getLeftPortionContent()}
                  {/*!props.editable &&
                  <div className="my-10px">
                    <Button
                      variant="contained"
                      color="primary"
                      type="button"
                      size="small"
                      className={styles.customBtn}
                      onClick={props.enableEdit}
                    >
                       Edit
                    </Button>
                  </div>}
                {props.editable &&
                  <div className="my-10px d-flex justify-content-between">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="small"
                      className={'btnStyle'}
                    >
                      <b>Save</b>
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="reset"
                      size="small"
                      className={'btnStyle'}
                      onClick={() => { props.dispatch(reset('DetailForm')); props.dispatch(reset('applicationTags')); props.onCancelEnableEdit() }}
                    >
                      <b>Cancel</b>
                    </Button>
                  </div>*/}
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={12}
                md={isEditMode ? '' : 9}
                lg={isEditMode ? '' : 9}
              >
                <CommonTab
                  tablist={props.tabList}
                  getTabIndex={index => props.setActiveTab(index)}
                  tabContent={getTabContent()}
                  activeTab={props.activeTabId}
                />

                <NotificationMessage
                  open={props.open}
                  msg={props.successMsg ? props.successMsg : props.failureMsg}
                  msgType={props.successMsg ? 'success' : 'error'}
                  handleClose={props.handleClose}
                />
                <Backdrop
                  sx={{
                    color: '#fff',
                    zIndex: theme => theme.zIndex.drawer + 1,
                  }}
                  open={props.isUploadInProgress}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Grid>
            </>
          )}
        </Grid>
        {/* </form> */}
      </Card>
    </Wrapper>
  );
};

export default reduxForm({
  form: 'DetailForm',
  enableReinitialize: true,
})(Detail);
