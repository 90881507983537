/* eslint-disable */
import * as React from 'react';
// import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
// import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
// import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import FieldArray from './fieldArray';

const Item = styled(Paper)(({ theme }) => ({
  // ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: '#000',
  border: '0px',
  borderBottom: '1px solid #d3d3d3',
  borderRadius: 0,
  boxShadow: 'none',
  fontSize: '15px'
  // backgroundColor: '#000'
}));

const FormRow = (props) => {
  const data = props.obj && props.obj.formItem;
  const initialValues = data && data.responseList && props.obj.apiCallhappened ? { responseList: data.responseList } : props.obj.formItem.initialValues;
  return (
    <FieldArray
      form={props.obj.formItem.form}
      datafields={data}
      initialValues={props.filteredTags ? { responseList: props.filteredTags } : initialValues}
      onSubmit={props.obj.handleSubmit}
      onClickFinish={props.obj.onClickFinish}
      enableEdit={props.obj.enableEdit}
      rerenderOnEveryChange
    />
  );
};

function FormHeader(props) {
  const data = props.obj && props.obj.formItem;
  return (
    data && data.header.map((el, index) => {
      return (
        <>
          <Grid className="" item xs={data.colSpan[index].xs}>
            <Item>
              {el.headerName}
            </Item>
          </Grid>
        </>
      );
    })
  );
}

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function NestedGrid(props) {
  const [expanded, setExpanded] = React.useState(props.expanded && 'panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        {!props.formName
          ? (
            <Accordion className="w-100" expanded={props.expanded && expanded === 'panel1'} onChange={handleChange(props.expanded && "panel1")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid container direction="row" xs={12} sm={12} md={12} lg={12}>
                  <FormHeader obj={props} />
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                {/* {props.apiCallhappened && ( */}
                <FormRow obj={props} filteredTags={props.filteredTags} />
                {/* )} */}
              </AccordionDetails>
            </Accordion>
          )
          : (
            <>
              <Grid container direction="row" xs={12} sm={12} md={12} lg={12}>
                <FormHeader obj={props} />
              </Grid>
              <FormRow obj={props} />
            </>)}
        {/* {props.loading && (
          <Grid xs={12} className="text-center p-4">
            loading...
          </Grid>
        )} */}

      </Grid>
    </Box>
  );
}
