/* eslint-disable */
import React, { useEffect, useState } from 'react';
import JsonEditor from './JsonEditor';

const JsonEditorContainer = ({ json, hideFilters, height }) => {
  const [previousEvent, setPreviousEvent] = useState(null);

  useEffect(() => {
    let body = document.getElementsByTagName('body')[0];
    body.addEventListener('drop', e => {
      e.preventDefault();
      e.stopPropagation();
      setPreviousEvent('drop');
      body.classList.remove('dragging');
    });
    body.addEventListener('dragover', e => {
      if (previousEvent === 'dragenter') {
        body.classList.add('dragging');
      }
      setPreviousEvent('dragover');
      e.preventDefault();
      return false;
    });
    body.addEventListener('dragenter', () => {
      setPreviousEvent('dragenter');
    });
    body.addEventListener('dragleave', () => {
      if (previousEvent === 'dragover') {
        body.classList.remove('dragging');
      }
      setPreviousEvent('dragleave');
    });
  }, [previousEvent]);

  return <JsonEditor hideFilters={hideFilters} height={height} />;
};

export default JsonEditorContainer;
