/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@mui/material/CircularProgress';

function LinearProgressWithLabel(props) {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '15%' }}>
        <Box sx={{ width: '60%', mr: 1 }}>
          <LinearProgress color="success" variant="determinate" {...props} style={{ height:15, borderRadius:10 }} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          {props.value === 0 ? <CircularProgress color='inherit' size={25} /> :
          <Typography className="text-white" variant="body2">
            {`${Math.round(
              props.value,
            )}%`}
          </Typography>
          }
        </Box>
      </Box>
      <Grid container>
        <Grid item xs={3} />
        <Grid item xs={7} className="d-flex justify-content-center">
          <Typography className="text-white" variant="body2">
            {props.label}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function ProgressBar(props) {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    // const timer = setInterval(() => {
    //   setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    // }, 800);
    // return () => {
    //   clearInterval(timer);
    // };
    setProgress(props.progress)
  }, [props.progress]);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel label={progress.label} value={progress.count} />
    </Box>
  );
}
