import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import cn from 'classnames';
import style from './CommonTabs.module.css';

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
});

class FullWidthTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.activeTab,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.activeTab !== state.activeTab) {
      return {
        value: props.activeTab
      };
    }
    return null; // No change to state
  }

  handleChange = (event, value) => {
    this.setState({ value });
    this.props.getTabIndex(value);
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  render() {
    const {
      classes, theme, tablist, tabContent, disabledTab
    } = this.props;
    return (
      <div className={classes.root}>
        <AppBar position="static" color="white" className="shadow-none">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            fullWidth
            centered
            className="px-5"
          >
            {tablist && tablist.map((item, index) => {
              const isDisabled = disabledTab && disabledTab.includes(index);
              return (
                <Tab className={cn('flex-fill', style.material_tab_button)} label={item.name} disabled={isDisabled} />
              );
            })}
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
        >
          {tabContent.props.children.map(() => {
            return (
              <TabContainer dir={theme.direction}>{tabContent}</TabContainer>
            );
          })}
        </SwipeableViews>
      </div>
    );
  }
}

// FullWidthTabs.propTypes = {
//   classes: PropTypes.object.isRequired,
//   theme: PropTypes.object.isRequired,
// };

export default withStyles(styles, { withTheme: true })(FullWidthTabs);
// import React, { Component } from 'react';
// import cn from 'classnames';
// import styles from './CommonTabs.module.scss';

// class CommonTabs extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       activeTab: props.activeTab,
//     };
//   }

//   static getDerivedStateFromProps(props, state) {
//     if (props.activeTab !== state.activeTab) {
//       return {
//         activeTab: props.activeTab
//       };
//     }
//     return null; // No change to state
//   }

//   renderTablist = (tablist, customButton, customHeaderComponent, disabledTab) => {
//     return (
//       <>
//         {tablist && tablist.map((tabsHeading, index) => {
//           const isDisabled = disabledTab && disabledTab.includes(index);
//           return (
//             <button type="button" id={tabsHeading.id} className={cn(customButton ? `${styles.tabsButton} ${customButton}` : styles.tabsButton, this.state.activeTab === index ? styles.active : null)} onClick={() => this.getTabIndex(index)} disabled={isDisabled || this.state.activeTab === index}>
//               {tabsHeading.name}
//             </button>
//           );
//         })}
//         {customHeaderComponent && (
//           customHeaderComponent
//         )}
//       </>
//     );
//   }

//   getTabIndex = (data) => {
//     this.setState({
//       activeTab: data
//     });
//     this.props.getTabIndex(data);
//   }

//   renderTabContent = (tabContent, customTabContentContainer) => {
//     return (
//       // <div className={cn(customTabContentContainer, styles.tabContentContainer)}>
//       <div className={customTabContentContainer}>
//         {tabContent}
//       </div>
//     );
//   }

//   render() {
//     const {
//       disabledTab, tablist, tabContent, className, customButton, customTabContainer, customTabHeaderContainer, customTabContentContainer, customHeaderComponent
//     } = this.props;
//     return (
//       <div className={className}>
//         <div className={cn(customTabContainer, styles.tabContainer)}>
//           <div className={cn(customTabHeaderContainer, styles.tabsHeaderContainer, 'px-5 pb-5 pt-4')}>
//             {tablist && tabContent && this.renderTablist(tablist, customButton, customHeaderComponent, disabledTab)}
//           </div>
//         </div>
//         {tabContent && this.renderTabContent(tabContent, customTabContentContainer)}
//       </div>
//     );
//   }
// }
// export default CommonTabs;
