export const GET_TAGS_REQUESTED = 'tags/GET_TAGS_REQUESTED';
export const GET_TAGS_SUCCESS = 'tags/GET_TAGS_SUCCESS';
export const GET_TAGS_FAILURE = 'tags/GET_TAGS_FAILURE';

export const GET_APPTAGS_REQUESTED = 'tags/GET_APPTAGS_REQUESTED';
export const GET_APPTAGS_SUCCESS = 'tags/GET_APPTAGS_SUCCESS';
export const GET_APPTAGS_FAILURE = 'tags/GET_APPTAGS_FAILURE';

export const GET_CATTAGS_REQUESTED = 'tags/GET_CATTAGS_REQUESTED';
export const GET_CATTAGS_SUCCESS = 'tags/GET_CATTAGS_SUCCESS';
export const GET_CATTAGS_FAILURE = 'tags/GET_CATTAGS_FAILURE';

export const GET_CHANNEL_TAGS_REQUESTED = 'tags/GET_CHANNEL_TAGS_REQUESTED';
export const GET_CHANNEL_TAGS_SUCCESS = 'tags/GET_CHANNEL_TAGS_SUCCESS';
export const GET_CHANNEL_TAGS_FAILURE = 'tags/GET_CHANNEL_TAGS_FAILURE';

export const SAVE_APPTAGS_REQUESTED = 'tags/SAVE_APPTAGS_REQUESTED';
export const SAVE_APPTAGS_SUCCESS = 'tags/SAVE_APPTAGS_SUCCESS';
export const SAVE_APPTAGS_FAILURE = 'tags/SAVE_APPTAGS_FAILURE';

export const SAVE_CATTAGS_REQUESTED = 'tags/SAVE_CATTAGS_REQUESTED';
export const SAVE_CATTAGS_SUCCESS = 'tags/SAVE_CATTAGS_SUCCESS';
export const SAVE_CATTAGS_FAILURE = 'tags/SAVE_CATTAGS_FAILURE';

export const SAVE_CHANNELTAGS_REQUESTED = 'tags/SAVE_CHANNELTAGS_REQUESTED';
export const SAVE_CHANNELTAGS_SUCCESS = 'tags/SAVE_CHANNELTAGS_SUCCESS';
export const SAVE_CHANNELTAGS_FAILURE = 'tags/SAVE_CHANNELTAGS_FAILURE';

export const GENERATE_TEMPLATE_REQUESTED = 'tags/GENERATE_TEMPLATE_REQUESTED';
export const GENERATE_TEMPLATE_SUCCESS = 'tags/GENERATE_TEMPLATE_SUCCESS';
export const GENERATE_TEMPLATE_FAILURE = 'tags/GENERATE_TEMPLATE_FAILURE';

export const GET_DETAILS_REQUESTED = 'tags/GET_DETAILS_REQUESTED';
export const GET_DETAILS_SUCCESS = 'tags/GET_DETAILS_SUCCESS';
export const GET_DETAILS_FAILURE = 'tags/GET_DETAILS_FAILURE';

export const UPDATE_ITEM_DETAILS_REQUESTED = 'tags/UPDATE_ITEM_DETAILS_REQUESTED';
export const UPDATE_ITEM_DETAILS_SUCCESS = 'tags/UPDATE_ITEM_DETAILS_SUCCESS';
export const UPDATE_ITEM_DETAILS_FAILURE = 'tags/UPDATE_ITEM_DETAILS_FAILURE';

export const UPDATE_THUMBNAIL_REQUESTED = 'tags/UPDATE_THUMBNAIL_REQUESTED';
export const UPDATE_THUMBNAIL_SUCCESS = 'tags/UPDATE_THUMBNAIL_SUCCESS';
export const UPDATE_THUMBNAIL_FAILURE = 'tags/UPDATE_THUMBNAIL_FAILURE';

export const GET_ADDED_FILTER_TAGS_REQUESTED = 'tags/GET_ADDED_FILTER_TAGS_REQUESTED';
export const GET_ADDED_FILTER_TAGS_SUCCESS = 'tags/GET_ADDED_FILTER_TAGS_SUCCESS';
export const GET_ADDED_FILTER_TAGS_FAILURE = 'tags/GET_ADDED_FILTER_TAGS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  tagsList: [],
  channelList: [],
  applnTagsList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TAGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_TAGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_TAGS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_APPTAGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_APPTAGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        applnTagsList: action.result.responseList
      };
    }
    case GET_APPTAGS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_CATTAGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_CATTAGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        tagsList: action.result.responseList
      };
    }
    case GET_CATTAGS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_CHANNEL_TAGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_CHANNEL_TAGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        channelList: action.result.responseList
      };
    }
    case GET_CHANNEL_TAGS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SAVE_APPTAGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SAVE_APPTAGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SAVE_APPTAGS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SAVE_CATTAGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SAVE_CATTAGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SAVE_CATTAGS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SAVE_CHANNELTAGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SAVE_CHANNELTAGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SAVE_CHANNELTAGS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GENERATE_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GENERATE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GENERATE_TEMPLATE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }

    case GET_DETAILS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case UPDATE_ITEM_DETAILS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case UPDATE_ITEM_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_ITEM_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case UPDATE_THUMBNAIL_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case UPDATE_THUMBNAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_THUMBNAIL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_ADDED_FILTER_TAGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_ADDED_FILTER_TAGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_ADDED_FILTER_TAGS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    default:
      return state;
  }
};

export const getGenericTags = () => {
  return {
    types: [GET_TAGS_REQUESTED, GET_TAGS_SUCCESS, GET_TAGS_FAILURE],
    promise: (client) => client.get('getGenericTags?orgName=berrydb&databaseName=generic')
  };
};

export const getApplicationTags = (orgName, databaseName) => {
  return {
    types: [GET_APPTAGS_REQUESTED, GET_APPTAGS_SUCCESS, GET_APPTAGS_FAILURE],
    promise: (client) => client.get(`getApplicationTags?orgName=${orgName}&databaseName=${databaseName}`)
  };
};

export const getCategoryTags = (orgName, databaseName) => {
  return {
    types: [GET_CATTAGS_REQUESTED, GET_CATTAGS_SUCCESS, GET_CATTAGS_FAILURE],
    promise: (client) => client.get(`getCategoryTags?orgName=${orgName}&databaseName=${databaseName}`)
  };
};

export const getChannelTags = (orgName, databaseName) => {
  return {
    types: [GET_CHANNEL_TAGS_REQUESTED, GET_CHANNEL_TAGS_SUCCESS, GET_CHANNEL_TAGS_FAILURE],
    promise: (client) => client.get(`getChannelTags?orgName=${orgName}&databaseName=${databaseName}`)
  };
};

export const saveApplicationTags = (payload) => {
  return {
    types: [SAVE_APPTAGS_REQUESTED, SAVE_APPTAGS_SUCCESS, SAVE_APPTAGS_FAILURE],
    promise: (client) => client.post('saveApplicationTags', {
      data: payload
    })
  };
};

export const saveCategoryTags = (payload) => {
  return {
    types: [SAVE_CATTAGS_REQUESTED, SAVE_CATTAGS_SUCCESS, SAVE_CATTAGS_FAILURE],
    promise: (client) => client.post('saveCategoryTags', {
      data: payload
    })
  };
};

export const saveChannelTags = (payload) => {
  return {
    types: [SAVE_CHANNELTAGS_REQUESTED, SAVE_CHANNELTAGS_SUCCESS, SAVE_CHANNELTAGS_FAILURE],
    promise: (client) => client.post('saveChannelTags', {
      data: payload
    })
  };
};

export const generateUploadTemplate = (payload) => {
  return {
    types: [GENERATE_TEMPLATE_REQUESTED, GENERATE_TEMPLATE_SUCCESS, GENERATE_TEMPLATE_FAILURE],
    promise: (client) => client.post('generateUploadTemplate', {
      data: payload
    })
  };
};

export const getItemDetails = (payload) => {
  return {
    types: [GET_DETAILS_REQUESTED, GET_DETAILS_SUCCESS, GET_DETAILS_FAILURE],
    promise: (client) => client.post('getItemDetails', {
      data: payload
    })
  };
};

export const updateItemDetails = (payload, orgName, databaseName) => {
  return {
    types: [UPDATE_ITEM_DETAILS_REQUESTED, UPDATE_ITEM_DETAILS_SUCCESS, UPDATE_ITEM_DETAILS_FAILURE],
    promise: (client) => client.post(`updateItemDetails?orgName=${orgName}&databaseName=${databaseName}`, {
      data: payload
    })
  };
};

export const updateThumbnail = (payload, orgName, databaseName, objectId, type) => {
  return {
    types: [UPDATE_ITEM_DETAILS_REQUESTED, UPDATE_ITEM_DETAILS_SUCCESS, UPDATE_ITEM_DETAILS_FAILURE],
    promise: (client) => client.post(`updateThumbnail?orgName=${orgName}&databaseName=${databaseName}&objectId=${objectId}&type=${type}`, {
      data: payload
    })
  };
};

export const getAddedFilterApiData = (databaseId, schemaId) => {
  return {
    types: [GET_ADDED_FILTER_TAGS_REQUESTED, GET_ADDED_FILTER_TAGS_SUCCESS, GET_ADDED_FILTER_TAGS_FAILURE],
    promise: (client) => client.get(`getAddedFilterApiData?databaseId=${databaseId}&schemaId=${schemaId}`)
  };
};
