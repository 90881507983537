export const GET_ALLTAG_REQUESTED = 'tags/GET_ALLTAG_REQUESTED';
export const GET_ALLTAG_SUCCESS = 'tags/GET_ALLTAG_SUCCESS';
export const GET_ALLTAG_FAILURE = 'tags/GET_ALLTAG_FAILURE';

export const GET_QUERYRESULT_REQUESTED = 'tags/GET_QUERYRESULT_REQUESTED';
export const GET_QUERYRESULT_SUCCESS = 'tags/GET_QUERYRESULT_SUCCESS';
export const GET_QUERYRESULT_FAILURE = 'tags/GET_QUERYRESULT_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALLTAG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_ALLTAG_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_ALLTAG_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_QUERYRESULT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_QUERYRESULT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_QUERYRESULT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    default:
      return state;
  }
};

export const getAllTags = (orgName, dbName) => {
  return {
    types: [GET_ALLTAG_REQUESTED, GET_ALLTAG_SUCCESS, GET_ALLTAG_FAILURE],
    promise: (client) => client.get(`getAllTags?orgName=${orgName}&databaseName=${dbName}`)
  };
};

export const getQueryResult = (payload) => {
  return {
    types: [GET_QUERYRESULT_REQUESTED, GET_QUERYRESULT_SUCCESS, GET_QUERYRESULT_FAILURE],
    promise: (client) => client.post('getQueryResult', {
      data: payload
    })
  };
};
