/* eslint-disable */
import React, { Component } from 'react';
import DatabaseComponent from '@components/Database/Database';
import { getUserDetails } from '../../reducers/otp';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  createDatabase,
  getDatabaseList,
  deleteDatabase,
  getSchemasList,
} from '../../reducers/database';
import { isCorrectFile } from '@helpers/utils';
import AlertDialogDelete from './deleteAlertDialog';
import NotificationMessage from '@components/NotificationMessage';

import { APIConfig } from '@constants';
import { Context } from '../../components/AppProvider/AppProvider';

class DatabaseContainer extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      desc: '',
      schemaId: '',
      videoThumbnail: '',
      isLoading: true,
      isLoadingSchemas: true,
      pageNo: 1,
      pageSize: 10,
      loaderForTable: false,
      loaderForTableSchemas: false,
      loadingOnCreateDatabase: false,
      showAlertForDelete: false,
      open: false,
      dbToDelete: '',
      databaseList: [],
      schemasList: [],
      pageCount: '',
      totalCount: '',
      pageCountSchemas: '',
      open: false,
      userSurrogateId: window.localStorage.getItem('userSurrogateId'),
      dropdownToggle: false,
    };
  }
  componentDidMount() {
    const userEmail = window.localStorage.getItem('userEmail');
    this.props.getUserDetails(userEmail);
    this.getAvailableDatabaseList(this.state.pageNo, false);
    this.getAvailableSchemasList(0, false);
  }

  // componentWillReceiveProps = (nextProps) => {
  //     if (this.state.databaseList !== this.props.availableDatabases.responseList) {
  //         this.setState({
  //             databaseList: this.props.availableDatabases.responseList
  //         })
  //     }
  // }

  getAvailableDatabaseList = (pageNo, isLoading) => {
    const userId = this.state.userSurrogateId;
    if (isLoading) {
      this.setState({ loaderForTable: true });
    }
    this.props
      .getDatabaseList(userId, pageNo, this.state.pageSize, null, false)
      .then(data => {
        this.setState({
          isLoading: false,
          loaderForTable: false,
          databaseList: data.responseList,
          pageCount: data.totalCount,
        });
      });
  };

  getAutoCompleteDatabaseList = async searchKey => {
    const userId = this.state.userSurrogateId;
    return await this.props.getDatabaseList(userId, 1, 10, searchKey);
  };

  handleAutoCompleteClick = dbItem => {
    if (dbItem) {
      this.setState({ databaseList: [dbItem], pageCount: 1, pageNo: 1 });
    }
  };

  handleAutoCompleteReset = () => {
    this.getAvailableDatabaseList(0, true);
    this.setState({ pageNo: 1 });
  };

  //new API integration
  getAvailableSchemasList = (pageNoSchemas, isLoadingSchemas) => {
    const userId = this.state.userSurrogateId;
    if (isLoadingSchemas) {
      this.setState({ loaderForTableSchemas: true });
    }
    this.props.getSchemasList(userId, pageNoSchemas, 0).then(data => {
      const myList =
        data.responseList &&
        data.responseList.map(results => {
          return results.name;
        });
      this.setState({
        isLoadingSchemas: false,
        loaderForTableSchemas: false,
        schemasList: myList,
        pageCountSchemas: data.totalCount,
      });
    });
  };

  uploadThumbnail = e => {
    if (e.target.files[0]) {
      const isFile = isCorrectFile(e.target.files[0], 'image');
      if (isFile) {
        this.setState({
          videoThumbnail: e.target.files[0],
          dropdownToggle: !this.state.dropdownToggle,
        });
      }
    }
  };

  removeProfilePhoto = () => {
    this.setState({ videoThumbnail: '' });
  };

  success = data => {
    const userId = this.state.userSurrogateId;
    const databaseDetails = [{ name: this.state.name }, { userId: userId }];
    window.localStorage.setItem(
      'isDatabaseCreated',
      JSON.stringify(databaseDetails)
    );
    this.props.history.push('/Database');
    this.setState({
      databaseName: '',
      desc: '',
      videoThumbnail: '',
      schemaId: '',
    });
    this.getAvailableDatabaseList(this.state.pageNo, false);
  };

  failure = data => {
    this.setState({
      loadingOnCreateDatabase: !this.state.loadingOnCreateDatabase,
    });
    // console.log('failure', data);
  };

  createDatabase = () => {
    const [contextState, dispatch] = this.context;
    const userId = this.state.userSurrogateId;
    this.setState({
      loadingOnCreateDatabase: !this.state.loadingOnCreateDatabase,
    });
    const formData = new FormData();
    formData.append(
      'file',
      this.state.videoThumbnail ? this.state.videoThumbnail : null
    );
    // formData.append('userEmail', this.props.userDetails.email);
    formData.append('userId', this.state.userSurrogateId);
    // formData.append('username', this.props.userDetails.userName);
    // formData.append('orgName', orgName);
    formData.append('schemaId', this.state.schemaId);
    // formData.append('userId', this.props.userDetails.userSurrogateId);
    // formData.append('username', this.props.userDetails.userName);
    // formData.append('orgName', orgName);
    formData.append('databaseName', this.state.name);
    formData.append('databaseDesc', this.state.desc);
    this.props
      .createDatabase(formData)
      .then(data => {
        this.success(data);
        this.props.getDatabaseList(userId, 0, 0);
        dispatch({ type: 'dbName', payload: formData.get('databaseName') });
        dispatch({
          type: 'dbId',
          payload: data.databaseId,
        });
      })
      .catch(error => this.failure(error));
  };

  setDatabaseName = e => {
    this.setState({ name: e.target.value });
  };

  setDescription = e => {
    this.setState({ desc: e.target.value });
  };
  setSchemasId = e => {
    const userId = this.state.userSurrogateId;
    this.props.getSchemasList(userId, 0, 0).then(data => {
      const myListnew =
        data.responseList &&
        data.responseList
          .map(results => {
            if (results.name == e.target.value) {
              return results.id;
            }
          })
          .filter(function(element) {
            return element !== undefined;
          });
      this.setState({ isLoadingSchemas: false, loaderForTableSchemas: false });
      const SchemaIdString = myListnew.toString();
      // console.log("results",myListnew)
      // console.log("dataUndefined",dataUndefined)
      console.log('SchemaIdString', SchemaIdString);
      this.setState({ schemaId: SchemaIdString });
      // console.log("onchangeschemaId",schemaId);
    });
  };
  handleSubmit = (e, values) => {
    e.preventDefault();
  };

  handleChangePage = (event, value) => {
    this.setState({ pageNo: value });
    this.getAvailableDatabaseList(value, true);
  };

  handleDropdownToggle = dropdownToggle => {
    this.setState({ dropdownToggle: dropdownToggle });
  };

  toggleDeletePopup = (dbId, dbName) => {
    this.setState({
      showAlertForDelete: !this.state.showAlertForDelete,
      dbToDelete: dbId ? { id: dbId, name: dbName } : this.state.dbToDelete,
    });
  };

  onDeleteItem = () => {
    const userId = this.state.userSurrogateId;
    let list = this.state.databaseList.filter(
      el => el.id !== this.state.dbToDelete.id
    );
    this.setState({ loaderForTable: true });
    this.props
      .deleteDatabase(this.state.dbToDelete.id, userId)
      .then(() => {
        this.setState(
          {
            open: true,
            dbToDelete: '',
            databaseList: list,
            loaderForTable: false,
            showAlertForDelete: false,
          },
          () => {
            // this.toggleDeletePopup();
            this.getAvailableDatabaseList(this.state.pageNo, false);
          }
        );
      })
      .catch(() => {
        this.setState(
          {
            open: true,
            dbToDelete: '',
            loaderForTable: false,
          },
          () => {
            this.toggleDeletePopup();
          }
        );
      });
  };
  handleCount = () => {
    if (this.state.pageCount % this.state.pageSize == 0) {
      return this.state.pageCount / this.state.pageSize;
    } else {
      return Math.floor(this.state.pageCount / this.state.pageSize) + 1;
    }
  };
  render() {
    // console.log("onchangeschemaId",this.state.schemaId);
    return (
      <>
        <DatabaseComponent
          createDatabase={this.createDatabase}
          setDatabaseName={this.setDatabaseName}
          setDescription={this.setDescription}
          setSchemasId={this.setSchemasId}
          handleSubmit={this.handleSubmit}
          uploadThumbnail={this.uploadThumbnail}
          removeProfilePhoto={this.removeProfilePhoto}
          videoThumbnail={this.state.videoThumbnail}
          name={this.state.name}
          // isLoading={this.state.isLoading}
          databaseList={this.state.databaseList}
          schemasList={this.state.schemasList}
          pageCount={this.handleCount()}
          totalCount={this.handleCount()}
          loading={this.state.isLoading}
          handleChangePage={this.handleChangePage}
          pageNo={this.state.pageNo}
          loaderForTable={this.state.loaderForTable}
          loadingOnCreateDatabase={this.state.loadingOnCreateDatabase}
          toggleDeletePopup={this.toggleDeletePopup}
          getAutoCompleteDatabaseList={this.getAutoCompleteDatabaseList}
          handleAutoCompleteClick={this.handleAutoCompleteClick}
          handleAutoCompleteReset={this.handleAutoCompleteReset}
          dropdownToggle={this.state.dropdownToggle}
          handleDropdownToggle={this.handleDropdownToggle}
        />
        <AlertDialogDelete
          showAlertForDelete={this.state.showAlertForDelete}
          setOpen={() => this.toggleDeletePopup()}
          onDeleteItem={this.onDeleteItem}
          dbToDelete={this.state.dbToDelete.name}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    userDetails: state.otp.userDetailsOnLoad.userDetails,
    availableDatabases: state.database.databaseList,
    loading: state.database.loading,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUserDetails,
      createDatabase,
      getDatabaseList,
      getSchemasList,
      deleteDatabase,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DatabaseContainer);
