/* eslint-disable */
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import React, { useReducer, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { GoogleLogin } from '@react-oauth/google';
// import GoogleLogin from 'react-google-login';
import GoogleIcon from '@mui/icons-material/Google';
import LoginIcon from '@mui/icons-material/Login';
import CancelIcon from '@mui/icons-material/Cancel';
import EmailIcon from '@mui/icons-material/Email';
// import SendIcon from '@mui/icons-material/Send';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { setNotificationMessage } from '../../reducers/notification';
import {
  login,
  getUserDetails,
  saveFbGoogleUsers,
  sendotp,
  verifyotp,
  setPassword,
} from '../../reducers/otp';

import NotificationMessage from '@components/NotificationMessage';
import { CUSTOM_MESSAGES } from '@constants';
import Link from '@mui/material/Link';
import jwt_decode from 'jwt-decode';
import { usePostHog } from 'posthog-js/react';
import logMessages from '../../constants/logMessages';

const useStyles = makeStyles(() => ({
  signInBackground: {
    position: 'absolute',
    backgroundImage: `url('https://d1if8zms14qmj9.cloudfront.net/static-assets/signin-background.png')`,
    backgroundSize: '100% 100%',
    filter: 'blur(5px) brightness(50%)',
    minWidth: '100vw',
    minHeight: '100vh',
  },

  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },

  wrapper: {
    position: 'relative',
    width: '33rem',
    transition: 'height 0.5s ease-in-out',
  },

  signInBoxContent: {
    padding: '25px',
    display: 'flex',
    flexDirection: 'column',
  },

  btnField: {
    margin: '20px 0px',
    fontWeight: '600',
    '&:hover': {
      background: '#1976d2',
      color: 'white',
    },
  },

  googleBtnField: {
    margin: '10px 0px',
    fontWeight: '600',
    color: '#B13E17',
    borderColor: '#B13E17',
    '&:hover': {
      background: '#B13E17',
      color: 'white',
      borderColor: 'white',
    },
  },

  berryDBBtnContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },

  berryDBBtnField: {
    fontWeight: '600',
    color: '#65677B',
    borderColor: '#65677B',
    '&:hover': {
      background: '#65677B',
      color: 'white',
      borderColor: 'white',
    },
  },

  loginBtnField: {
    width: '50%',
    alignSelf: 'center',
    fontWeight: '600',
    marginTop: '10px',
    color: '#799351',
    borderColor: '#799351',
    '&:hover': {
      background: '#799351',
      color: 'white',
      borderColor: 'white',
    },
  },

  otpLoginBtnField: {
    fontWeight: '600',
    color: '#799351',
    borderColor: '#799351',
    '&:hover': {
      background: '#799351',
      color: 'white',
      borderColor: 'white',
    },
  },

  cancelBtnField: {
    borderColor: '#BB6464',
    fontWeight: '600',
    color: '#BB6464',
    '&:hover': {
      background: '#BB6464',
      color: 'white',
      borderColor: 'white',
    },
  },

  fullWidth: {
    width: '100%',
  },

  logo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#E9E7DF',
    padding: '20px',
  },

  loginSection: {
    padding: '10px 20px',
  },

  otpBtn: {
    display: 'flex',
    gap: '10px',
  },

  sendOTP: {
    fontWeight: '600',
    '&:hover': {
      background: '#1976d2',
      color: 'white',
    },
  },
  customInputLabel: {
    width: '100%',
  },

  berryBDLogin: {
    borderRadius: '10px',
    border: '1px solid #c0c0c0',
  },

  creatingDBsOverlay: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#ffffffee',
    display: 'grid',
    justifyContent: 'center',
    zIndex: '10',
    fontSize: '18px',
    fontWeight: '500',
    padding: '10px',
  },

  creatingDBsMsg: {
    fontSize: '18px',
    fontWeight: '500',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100px',
    flexDirection: 'column',
    //marginBottom: '30px',
    position: 'relative',
    lineHeight: '40px',
  },

  shimmer: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    height: '42px',
    transform: 'translateX(-100%)',
    backgroundImage:
      'linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0))',
    animation: '$shimmer 3s infinite',
    content: '',
  },
  '@keyframes shimmer': {
    '100%': {
      transform: 'translateX(100%)',
    },
  },

  creatingDBsMsgWrapper: {
    display: 'grid',
    alignItems: 'center',
    marginLeft: '20px',
    marginRight: '20px',
    //marginBottom: '10px'
  },

  creatingDBsHeaderMsg: {
    fontSize: '18px',
    fontWeight: '500',
    //display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '50px',
    //flexDirection: 'column',
    // display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '20px',
    marginRight: '20px',
    lineHeight: '30px',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    overflow: 'hidden',
    textAlign: 'center',
  },

  creatingDBsMsgHeaderWrapper: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  },

  successMsg: {
    fontSize: '18px',
    fontWeight: '500',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // minHeight: '100px',
    flexDirection: 'column',
  },

  successMsgWrapper: {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
    // marginBottom: '20px',
  },

  bdbIcon: {
    width: '100px',
    height: '160px',
    alignSelf: 'end',
    justifySelf: 'center',
    marginTop: '15px',
  },

  signIn_loader_container: {
    minHeight: '50px',
    alignSelf: 'end',
    justifySelf: 'center',
    marginTop: '50px',
  },

  signIn_loader: {
    width: '120px',
    height: '20px',
    WebkitMask: 'linear-gradient(90deg, #000 70%, #0000 0) left/20% 100%',
    background:
      'linear-gradient(#000 0 0) left -25% top 0 /20% 100% no-repeat #ddd',
    animation: '$l7 1s infinite steps(6)',
  },
  '@keyframes l7': {
    '100%': {
      backgroundPosition: 'right -25% top 0',
    },
  },

  '@keyframes floatIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(10px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },

  fadeinFloating: {
    opacity: 0,
    animation: '$floatIn 2s ease-in-out forwards',
    animationDelay: '2s',
  },

  link: {
    // paddingTop: '20px',
    color: 'darkblue',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '22px',
  },

  // loader: {
  //   width: '25px',
  //   padding: '8px',
  //   aspectRatio: '1',
  //   borderRadius: '50%',
  //   background: '#313b39',
  //   '--_m': 'conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box',
  //   WebkitMask: 'var(--_m)',
  //   mask: 'var(--_m)',
  //   WebkitMaskComposite: 'source-out',
  //   maskComposite: 'subtract',
  //   animation: '$l3 1s infinite linear',
  // },
  // '@keyframes l3': {
  //   to: {
  //     transform: 'rotate(1turn)',
  //   },
  // },

  loader: {
    width: '50px',
    height: '20px',
    background:
      'linear-gradient(90deg, #0001 33%, #0005 50%, #0001 66%) #f2f2f2',
    backgroundSize: '300% 100%',
    animation: '$l1 1s infinite linear',
  },
  '@keyframes l1': {
    '0%': {
      backgroundPosition: 'right',
    },
  },

  checkGreen: {
    display: 'inline-block',
    transform: 'rotate(45deg)',
    height: '24px',
    width: '16px',
    borderBottom: '7px solid #78b13f',
    borderRight: '7px solid #78b13f',
  },

  checkGrey: {
    display: 'inline-block',
    transform: 'rotate(45deg)',
    height: '24px',
    width: '16px',
    borderBottom: '7px solid #4d5249',
    borderRight: '7px solid #4d5249',
  },

  databaseCreationMessage: {
    display: 'flex',
    width: '100%',
    marginTop: '10px',
  },

  databaseCreationMessageGrey: {
    display: 'flex',
    width: '100%',
    marginTop: '10px',
    backgroundColor: '#DDDBDD',
    position: 'relative',
  },

  creatingDBMsg: {
    flex: '1',
    paddingLeft: '20px',
  },

  checkmarkWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    margin: 0,
  },
  checkmarkCircle: {
    strokeDasharray: '166',
    strokeDashoffset: '166',
    strokeWidth: '2',
    strokeMiterlimit: '10',
    stroke: '#7ac142',
    fill: 'none',
    animation: '$stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards',
  },

  checkmarkCircleGrey: {
    strokeDasharray: '166',
    strokeDashoffset: '166',
    strokeWidth: '2',
    strokeMiterlimit: '10',
    stroke: '#ccc',
    fill: 'none',
    animation: '$stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards',
  },

  checkmark: {
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    display: 'block',
    strokeWidth: '2',
    stroke: '#fff',
    strokeMiterlimit: '10',
    margin: '10% auto',
    boxShadow: 'inset 0px 0px 0px #7ac142',
    animation:
      '$fill .4s ease-in-out .4s forwards, $scale .3s ease-in-out .9s both',
  },
  checkmarkCheck: {
    transformOrigin: '50% 50%',
    strokeDasharray: '48',
    strokeDashoffset: '48',
    animation: '$stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards',
  },
  '@keyframes stroke': {
    '100%': { strokeDashoffset: 0 },
  },
  '@keyframes scale': {
    '0%, 100%': { transform: 'none' },
    '50%': { transform: 'scale3d(1.1, 1.1, 1)' },
  },
  '@keyframes fill': {
    '100%': { boxShadow: 'inset 0px 0px 0px 30px #7ac142' },
  },

  grayLoader: {
    background: '#ccc',
  },

  grayWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    paddingBottom: '15px',
    backgroundColor: '#eee',
  },
  grayCircle: {
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    border: '2px solid #eee',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  grayTickMark: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    border: '2px solid #ddd',
    borderTop: 'none',
    borderRight: 'none',
    transform: 'rotate(-45deg)',
    position: 'relative',
  },

  greyTick: {
    width: '28px',
    height: '28px',
  },

  tickShimmer: {
    position: 'relative',
    overflow: 'hidden',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background:
        'linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)',
      animation: '$shimmer 2s infinite',
    },
  },
  '@keyframes shimmer': {
    '0%': {
      transform: 'translateX(-100%)',
    },
    '100%': {
      transform: 'translateX(100%)',
    },
  },
}));

const Signin = props => {
  const classes = useStyles();
  const posthog = usePostHog();

  const logo = 'https://d1if8zms14qmj9.cloudfront.net/static-assets/logo.png';

  const [state, dispatch] = useReducer(signInReducer, signinState);

  const {
    username,
    password,
    msg,
    loginCode,
    msgSeverityType,
    // resendOTP,
    open,
    showOTPLogin,
    clickedOTPBtn,
    showBerryDBForm,
    newUserPwd,
    recvUserSurrogateId,
    showCreatingDBsOverlay,
    sendingCode,
    firstTimeUser = false,
  } = state;

  const [showFirstTick, setShowFirstTick] = useState(false);
  const [showSecondTick, setShowSecondTick] = useState(false);
  const [showThirdTick, setShowThirdTick] = useState(false);
  const [showFourthTick, setShowFourthTick] = useState(false);
  const [showFifthTick, setShowFifthTick] = useState(false);
  const [showSixthTick, setShowSixthTick] = useState(false);

  const [showFirstLoader, setShowFirstLoader] = useState(true);
  const [showSecondLoader, setShowSecondLoader] = useState(false);
  const [showThirdLoader, setShowThirdLoader] = useState(false);
  const [showFourthLoader, setShowFourthLoader] = useState(false);
  const [showFifthLoader, setShowFifthLoader] = useState(false);
  const [showSixthLoader, setShowSixthLoader] = useState(false);
  const [createUserCallEnded, setCreateUserCallEnded] = useState(false);
  const [timerMultiplier, setTimerMultiplier] = useState(0);

  const [usernameLogin, setUsernameLogin] = useState('');
  const [userEmailLogin, setUserEmailLogin] = useState('');

  useEffect(() => {
    if (window.localStorage.getItem('username')) {
      props.history.push('/Dashboard');
    } else {
      props.history.push('/');
    }
  }, []);

  useEffect(() => {
    if (showCreatingDBsOverlay) {
      const timer = setTimeout(() => {
        setShowFirstTick(true);
        setShowFirstLoader(false);
        setShowSecondLoader(true);
      }, timerMultiplier * 1000);
      return () => clearTimeout(timer);
    }
  }, [showCreatingDBsOverlay]);

  useEffect(() => {
    if (showCreatingDBsOverlay) {
      const timer = setTimeout(() => {
        setShowSecondTick(true);
        setShowSecondLoader(false);
        setShowThirdLoader(true);
      }, timerMultiplier * 2000);

      return () => clearTimeout(timer);
    }
  }, [showCreatingDBsOverlay]);

  useEffect(() => {
    if (showCreatingDBsOverlay) {
      const timer = setTimeout(() => {
        setShowThirdTick(true);
        setShowThirdLoader(false);
        setShowFourthLoader(true);
      }, timerMultiplier * 3000);

      return () => clearTimeout(timer);
    }
  }, [showCreatingDBsOverlay]);

  useEffect(() => {
    if (showCreatingDBsOverlay) {
      const timer = setTimeout(() => {
        setShowFourthTick(true);
        setShowFourthLoader(false);
        setShowFifthLoader(true);
      }, timerMultiplier * 4000);

      return () => clearTimeout(timer);
    }
  }, [showCreatingDBsOverlay]);

  useEffect(() => {
    if (showCreatingDBsOverlay) {
      const timer = setTimeout(() => {
        setShowFifthTick(true);
        setShowFifthLoader(false);
        setShowSixthLoader(true);
      }, timerMultiplier * 5000); // Hides after 5 seconds

      return () => clearTimeout(timer);
    }
  }, [showCreatingDBsOverlay]);

  useEffect(() => {
    if (showCreatingDBsOverlay) {
      const timer = setTimeout(() => {
        setShowSixthTick(true);
        setShowSixthLoader(false);
      }, timerMultiplier * 6000); // Hides after 5 seconds

      return () => clearTimeout(timer);
    }
  }, [showCreatingDBsOverlay]);

  const successLoginUser = (userName, userEmail) => {
    dispatch({ type: 'showCreatingDBsOverlay', payload: true });

    if (userEmail) {
      setUserEmailLogin(userEmail);
      posthog?.identify(userEmail, {
        email: userEmail,
        userName: userName,
      });
      // window.localStorage.setItem('userEmail', userEmail);
    }
    if (userName) {
      setUsernameLogin(userName);
      // window.localStorage.setItem('username', userName);
    }
    // window.localStorage.setItem('orgName', 'vidberrys');
    //props.history.push('/Dashboard');
    //dispatch({ type: 'showCreatingDBsOverlay', payload: false })
    // if(!showCreatingDBsOverlay) {
    // }
  };

  const successOTPLoginUser = (userName, userEmail) => {
    dispatch({ type: 'showCreatingDBsOverlay', payload: true });
    if (userEmail) {
      setUserEmailLogin(userEmail);
      posthog?.capture(logMessages.LOGIN_WITH_OTP_SUCCESS);
      // window.localStorage.setItem('userEmail', userEmail);
    }
    if (userName) {
      setUsernameLogin(userName);
      // window.localStorage.setItem('username', userName);
    }
    // window.localStorage.setItem('orgName', 'vidberrys');
    //props.history.push('/Dashboard');
    // dispatch({ type: 'showCreatingDBsOverlay', payload: false })
    // if(!showCreatingDBsOverlay) {
    // }
  };

  const registerNewUser = data => {
    dispatch({
      type: 'recvUserSurrogateId',
      payload: data.userData.userSurrogateId,
    });
    dispatch({ type: 'password', payload: '' });
    dispatch({ type: 'newUserPwd', payload: true });
    dispatch({ type: 'clickedOTPBtn', payload: false });
    if (data.userData && data.userData.fullName) {
      dispatch({
        type: 'username',
        payload: data.userData.fullName,
      });
    }
  };

  const failureLoginUser = data => {
    showErrorToast(CUSTOM_MESSAGES.INVALID_DATA);
  };

  const otpNotify = data => {
    dispatch({ type: 'notification', payload: true });
    dispatch({ type: 'msgSeverityType', payload: 'success' });
    dispatch({ type: 'msg', payload: CUSTOM_MESSAGES.CODE_NOTIFY });
  };

  const resendOTPMail = () => {
    if (username !== '' && typeof username !== 'undefined') {
      dispatch({ type: 'notification', payload: true });
      dispatch({ type: 'msgSeverityType', payload: 'success' });
      dispatch({ type: 'msg', payload: CUSTOM_MESSAGES.CODE_NOTIFY });
      otpRequest();
    } else {
      showErrorToast(CUSTOM_MESSAGES.EMAIL_ERROR);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({ type: 'notification', payload: false });
  };

  const responseGoogle = response => {
    var token = jwt_decode(response.credential);

    const { name, email, picture } = token;

    console.log(response);
    const jsonData = {
      userName: name, //response.profileObj.name,
      email: email,
      contactToken: null,
      type: 3,
    };
    dispatch({ type: 'showCreatingDBsOverlay', payload: true });
    props
      .saveFbGoogleUsers(jsonData)
      .then(data => {
        if (data.userSurrogateId) {
          dispatch({
            type: 'recvUserSurrogateId',
            payload: data.userSurrogateId,
          });
        }
        if (email) {
          posthog?.capture(logMessages.LOGIN_WITH_SOCIAL_SUCCESS);
          successLoginUser(name, email);
        } else {
          posthog?.capture(logMessages.LOGIN_WITH_SOCIAL_FAILURE);
          failureLoginUser();
        }
      })
      .catch(err => console.log('err', err));
    // .finally(() =>
    //   dispatch({ type: 'showCreatingDBsOverlay', payload: false })
    // );
  };

  const showOTPLoginForm = () => {
    dispatch({ type: 'showOTPLogin', payload: !showOTPLogin });
    dispatch({ type: 'showBerryDBForm', payload: !showBerryDBForm });
    dispatch({ type: 'clickedOTPBtn', payload: false });
    dispatch({ type: 'newUserPwd', payload: false });
    dispatch({ type: 'username', payload: '' });
    dispatch({ type: 'password', payload: '' });
  };

  const cancelUser = () => {
    dispatch({ type: 'showOTPLogin', payload: !showOTPLogin });
    dispatch({ type: 'showBerryDBForm', payload: !showBerryDBForm });
    dispatch({ type: 'clickedOTPBtn', payload: false });
    dispatch({ type: 'newUserPwd', payload: false });
    dispatch({ type: 'username', payload: '' });
    dispatch({ type: 'password', payload: '' });
  };

  const otpRequest = () => {
    const usrEmail = username;
    const jsonData = {
      email: usrEmail,
      mobileNumber: '',
      mobileNumberCountryCode: '',
      secret: '',
      type: 1,
      userName: '',
    };
    dispatch({ type: 'sendingCode', payload: true });
    posthog?.capture(`OTP requested for email : ${usrEmail}`);
    props
      .sendotp(jsonData)
      .then(data => {
        otpNotify(data);
        dispatch({ type: 'clickedOTPBtn', payload: true });
      })
      .catch(errorData => {
        posthog?.capture(`OTP request failed for email : ${usrEmail}`);
        failureLoginUser(errorData);
      })
      .finally(() => {
        dispatch({ type: 'sendingCode', payload: false });
      });
  };

  const loginOTPUser = email => {
    const usrEmail = email || username;
    const jsonData = {
      mobileNumber: '',
      mobileNumberCountryCode: '',
      email: usrEmail,
      type: 1,
      secret: loginCode,
      userName: '',
    };
    props
      .login(jsonData, true)
      .then(data => {
        console.log(data);
        if (data.status === 200) {
          // debugger;
          dispatch({ type: 'newUserPwd', payload: true });

          if (data.userData.isNewOrg === 'true') {
            setTimerMultiplier(2.5);
          }
          if (data.userData.isPwdSet === 'false') {
            registerNewUser(data);
            setCreateUserCallEnded(true);
            return;
          }
          if (data.userData.isPwdSet === 'true') {
            successOTPLoginUser(data.userData.fullName, usrEmail);
            return;
          }
        }
      })
      .catch(errorData => failureLoginUser(errorData));
  };

  useEffect(() => {
    console.log('username: ', username);
  }, [username]);

  const loginUser = () => {
    const jsonData = {
      mobileNumber: '',
      mobileNumberCountryCode: '',
      email: username,
      type: 1,
      secret: Buffer.from(password).toString('base64'),
      userName: '',
    };

    props
      .login(jsonData, false)
      .then(data => {
        console.log(data);
        successLoginUser(data.userData.fullName, username);
      })
      .catch(errorData => failureLoginUser(errorData));
  };

  const createNewUserPwd = () => {
    const jsonData = {
      userSurrogateId: recvUserSurrogateId,
      newPassword: password,
    };
    dispatch({ type: 'showCreatingDBsOverlay', payload: true });
    while (!createUserCallEnded) {
      setTimeout(1000); // Wait for 1 second
    }
    props
      .setPassword(jsonData)
      .then(data => {
        dispatch({ type: 'msgSeverityType', payload: 'success' });
        dispatch({
          type: 'msg',
          payload: CUSTOM_MESSAGES.SET_PASSWORD_SUCCESS,
        });
        loginUser();
      })
      .catch(errorData => failureLoginUser(errorData));
  };

  const closeSplashScreen = () => {
    setShowFirstTick(true);
    setShowSecondTick(true);
    setShowThirdTick(true);
    setShowFourthTick(true);
    setShowFifthTick(true);
    setShowSixthTick(true);
    if (userEmailLogin) {
      console.log('userEmailLogin', userEmailLogin);
      window.localStorage.setItem('userEmail', userEmailLogin);
    }
    if (usernameLogin) {
      console.log('usernameLogin', usernameLogin);
      window.localStorage.setItem('username', usernameLogin);
    }
    window.localStorage.setItem('orgName', 'vidberrys');
    props.history.push('/Dashboard');
    dispatch({ type: 'showCreatingDBsOverlay', payload: false });
  };

  const setUsernameForLogin = e => {
    dispatch({ type: 'username', payload: (e.target.value || '').trim() });
  };

  const setOTPForLogin = e => {
    dispatch({ type: 'loginCode', payload: e.target.value });
  };

  const setPasswordForLogin = e => {
    dispatch({ type: 'password', payload: e.target.value });
  };

  /* const onKeyDownEnter = e => {
    if (e.key === 'Enter' && username && loginCode !== '') {
      loginOTPUser();
    }
    if (e.key === 'Enter' && username && password) {
      // loginUser();
      createNewUserPwd();
    } else if (e.key === 'Enter' && !username && password) {
      dispatch({ type: 'notification', payload: true });
      dispatch({ type: 'msg', payload: CUSTOM_MESSAGES.ENTER_USERNAME });
    } else if (e.key === 'Enter' && !password && username) {
      dispatch({ type: 'notification', payload: true });
      dispatch({ type: 'msg', payload: CUSTOM_MESSAGES.ENTER_PASSWORD });
    }
  }; */

  const isEmailValid = email => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return regex.test(email);
  };

  const showErrorToast = msg => {
    console.log(msg);
    dispatch({
      type: 'notification',
      payload: true,
    });
    dispatch({
      type: 'msgSeverityType',
      payload: 'error',
    });
    dispatch({
      type: 'msg',
      payload: msg,
    });
  };

  const handleInfoNavigate = () => {
    window.open('https://berrydb.io/blog/getting-started'); // Replace 'https://example.com' with your desired URL
  };

  return (
    <div>
      {/* {showCreatingDBsOverlay && (
        <div className={classes.creatingDBsOverlay}>
          <img className={classes.bdbIcon} src={'/favicon.ico'}></img>
          Please wait while we create your example databases...
        </div>
      )} */}
      <div className={classes.signInBackground}></div>
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <Card className={classes.signInBox} sx={{ background: '#F7F7F7' }}>
            <div
              style={{ padding: '0px', transition: 'height 0.5s ease-in-out' }}
            >
              {
                <>
                  <div className={classes.logo}>
                    <img
                      src={logo}
                      fetchpriority="high"
                      alt=""
                      className="block objectFitContain"
                      height="70px"
                    />
                  </div>
                  {firstTimeUser ? (
                    <div>
                      <div className={classes.successMsgWrapper}>
                        <div className={classes.successMsg}>
                          <div>Thankyou for choosing BerryDB</div>
                          <div>Your Account has been created</div>
                          <div
                            className={classes.link}
                            onClick={handleInfoNavigate}
                          >
                            Please click here to use learn how to use BerryDB
                          </div>
                        </div>
                      </div>
                      <div className={classes.loginSection}>
                        <div
                          style={{
                            display: 'flex',
                            gap: '10px',
                            marginTop: '15px',
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            endIcon={<CancelIcon />}
                            className={classes.cancelBtnField}
                            fullWidth
                            type="button"
                            onClick={() => closeSplashScreen()}
                          >
                            Close
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {showCreatingDBsOverlay ? (
                        <>
                          <div className={classes.creatingDBsMsgHeaderWrapper}>
                            <div className={classes.creatingDBsHeaderMsg}>
                              Visit{' '}
                              <span
                                className={classes.link}
                                onClick={handleInfoNavigate}
                              >
                                Getting Started
                              </span>{' '}
                              page to learn more
                            </div>
                          </div>
                          <div className={classes.creatingDBsMsgWrapper}>
                            {/* <div className={classes.signIn_loader_container}>
                        <div className={classes.signIn_loader}></div>
                      </div> */}
                            <div className={classes.creatingDBsMsg}>
                              <div className={classes.databaseCreationMessage}>
                                {!showFirstTick && (
                                  <div
                                    className={`${classes.grayWrapper} ${
                                      showFirstLoader ? classes.tickShimmer : ''
                                    }`}
                                  >
                                    <div className={classes.greyTick}>
                                      <svg
                                        viewBox="149.881 106.636 33.176 32.582"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <ellipse
                                          style={{
                                            fill: 'rgb(216, 216, 216)',
                                            stroke: 'rgb(255, 255, 255)',
                                          }}
                                          cx="166.469"
                                          cy="122.927"
                                          rx="16.588"
                                          ry="16.291"
                                        />
                                        <path
                                          style={{
                                            fill: 'rgb(216, 216, 216)',
                                            stroke: 'rgb(255, 255, 255)',
                                          }}
                                          d="M 155.806 123.815 L 162.915 129.147 L 175.948 116.706"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                )}
                                {showFirstTick && (
                                  <div
                                    className={`${classes.checkmarkWrapper}`}
                                  >
                                    <svg
                                      className={classes.checkmark}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 52 52"
                                    >
                                      <circle
                                        className={classes.checkmark__circle}
                                        cx="26"
                                        cy="26"
                                        r="25"
                                        fill="none"
                                      />
                                      <path
                                        className={classes.checkmark__check}
                                        fill="none"
                                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                      />
                                    </svg>
                                  </div>
                                )}
                                <div className={classes.creatingDBMsg}>
                                  {' '}
                                  {showFirstTick
                                    ? 'Sample schemas available'
                                    : 'Creating sample schemas'}
                                </div>
                              </div>
                              <div className={classes.databaseCreationMessage}>
                                {!showSecondTick && (
                                  <div
                                    className={`${classes.grayWrapper} ${
                                      showSecondLoader
                                        ? classes.tickShimmer
                                        : ''
                                    }`}
                                  >
                                    <div className={classes.greyTick}>
                                      <svg
                                        viewBox="149.881 106.636 33.176 32.582"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <ellipse
                                          style={{
                                            fill: 'rgb(216, 216, 216)',
                                            stroke: 'rgb(255, 255, 255)',
                                          }}
                                          cx="166.469"
                                          cy="122.927"
                                          rx="16.588"
                                          ry="16.291"
                                        />
                                        <path
                                          style={{
                                            fill: 'rgb(216, 216, 216)',
                                            stroke: 'rgb(255, 255, 255)',
                                          }}
                                          d="M 155.806 123.815 L 162.915 129.147 L 175.948 116.706"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                )}
                                {showSecondTick && (
                                  <div className={classes.checkmarkWrapper}>
                                    <svg
                                      className={classes.checkmark}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 52 52"
                                    >
                                      <circle
                                        className={classes.checkmark__circle}
                                        cx="26"
                                        cy="26"
                                        r="25"
                                        fill="none"
                                      />
                                      <path
                                        className={classes.checkmark__check}
                                        fill="none"
                                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                      />
                                    </svg>
                                  </div>
                                )}
                                <div className={classes.creatingDBMsg}>
                                  {showSecondTick
                                    ? 'Sample Databases available'
                                    : 'Building sample DBs and docs, images and videos'}
                                </div>
                              </div>
                              <div className={classes.databaseCreationMessage}>
                                {!showThirdTick && (
                                  <div
                                    className={`${classes.grayWrapper} ${
                                      showThirdLoader ? classes.tickShimmer : ''
                                    }`}
                                  >
                                    <div className={classes.greyTick}>
                                      <svg
                                        viewBox="149.881 106.636 33.176 32.582"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <ellipse
                                          style={{
                                            fill: 'rgb(216, 216, 216)',
                                            stroke: 'rgb(255, 255, 255)',
                                          }}
                                          cx="166.469"
                                          cy="122.927"
                                          rx="16.588"
                                          ry="16.291"
                                        />
                                        <path
                                          style={{
                                            fill: 'rgb(216, 216, 216)',
                                            stroke: 'rgb(255, 255, 255)',
                                          }}
                                          d="M 155.806 123.815 L 162.915 129.147 L 175.948 116.706"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                )}
                                {showThirdTick && (
                                  <div className={classes.checkmarkWrapper}>
                                    <svg
                                      className={classes.checkmark}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 52 52"
                                    >
                                      <circle
                                        className={classes.checkmark__circle}
                                        cx="26"
                                        cy="26"
                                        r="25"
                                        fill="none"
                                      />
                                      <path
                                        className={classes.checkmark__check}
                                        fill="none"
                                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                      />
                                    </svg>
                                  </div>
                                )}
                                <div className={classes.creatingDBMsg}>
                                  {showThirdTick
                                    ? 'ML based annotation projects available'
                                    : 'Building ML based annotation projects for DBs'}
                                </div>
                              </div>
                              <div className={classes.databaseCreationMessage}>
                                {!showFourthTick && (
                                  <div
                                    className={`${classes.grayWrapper} ${
                                      showFourthLoader
                                        ? classes.tickShimmer
                                        : ''
                                    }`}
                                  >
                                    <div className={classes.greyTick}>
                                      <svg
                                        viewBox="149.881 106.636 33.176 32.582"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <ellipse
                                          style={{
                                            fill: 'rgb(216, 216, 216)',
                                            stroke: 'rgb(255, 255, 255)',
                                          }}
                                          cx="166.469"
                                          cy="122.927"
                                          rx="16.588"
                                          ry="16.291"
                                        />
                                        <path
                                          style={{
                                            fill: 'rgb(216, 216, 216)',
                                            stroke: 'rgb(255, 255, 255)',
                                          }}
                                          d="M 155.806 123.815 L 162.915 129.147 L 175.948 116.706"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                )}
                                {showFourthTick && (
                                  <div className={classes.checkmarkWrapper}>
                                    <svg
                                      className={classes.checkmark}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 52 52"
                                    >
                                      <circle
                                        className={classes.checkmark__circle}
                                        cx="26"
                                        cy="26"
                                        r="25"
                                        fill="none"
                                      />
                                      <path
                                        className={classes.checkmark__check}
                                        fill="none"
                                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                      />
                                    </svg>
                                  </div>
                                )}
                                <div className={classes.creatingDBMsg}>
                                  {showFourthTick
                                    ? 'Unified search with full text indexes available'
                                    : 'Creating unified search with full text indexes'}
                                </div>
                              </div>
                              <div className={classes.databaseCreationMessage}>
                                {!showFifthTick && (
                                  <div
                                    className={`${classes.grayWrapper} ${
                                      showFifthLoader ? classes.tickShimmer : ''
                                    }`}
                                  >
                                    <div className={classes.greyTick}>
                                      <svg
                                        viewBox="149.881 106.636 33.176 32.582"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <ellipse
                                          style={{
                                            fill: 'rgb(216, 216, 216)',
                                            stroke: 'rgb(255, 255, 255)',
                                          }}
                                          cx="166.469"
                                          cy="122.927"
                                          rx="16.588"
                                          ry="16.291"
                                        />
                                        <path
                                          style={{
                                            fill: 'rgb(216, 216, 216)',
                                            stroke: 'rgb(255, 255, 255)',
                                          }}
                                          d="M 155.806 123.815 L 162.915 129.147 L 175.948 116.706"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                )}
                                {showFifthTick && (
                                  <div className={classes.checkmarkWrapper}>
                                    <svg
                                      className={classes.checkmark}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 52 52"
                                    >
                                      <circle
                                        className={classes.checkmark__circle}
                                        cx="26"
                                        cy="26"
                                        r="25"
                                        fill="none"
                                      />
                                      <path
                                        className={classes.checkmark__check}
                                        fill="none"
                                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                      />
                                    </svg>
                                  </div>
                                )}
                                <div className={classes.creatingDBMsg}>
                                  {showFifthTick
                                    ? 'Unified search with DB and vector indexes available'
                                    : 'Creating unified search with DB and vector indexes'}
                                </div>
                              </div>
                              <div className={classes.databaseCreationMessage}>
                                {!showSixthTick && (
                                  <div
                                    className={`${classes.grayWrapper} ${
                                      showSixthLoader ? classes.tickShimmer : ''
                                    }`}
                                  >
                                    <div className={classes.greyTick}>
                                      <svg
                                        viewBox="149.881 106.636 33.176 32.582"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <ellipse
                                          style={{
                                            fill: 'rgb(216, 216, 216)',
                                            stroke: 'rgb(255, 255, 255)',
                                          }}
                                          cx="166.469"
                                          cy="122.927"
                                          rx="16.588"
                                          ry="16.291"
                                        />
                                        <path
                                          style={{
                                            fill: 'rgb(216, 216, 216)',
                                            stroke: 'rgb(255, 255, 255)',
                                          }}
                                          d="M 155.806 123.815 L 162.915 129.147 L 175.948 116.706"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                )}
                                {showSixthTick && (
                                  <div
                                    className={`${classes.checkmarkWrapper}`}
                                  >
                                    <svg
                                      className={classes.checkmark}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 52 52"
                                    >
                                      <circle
                                        className={classes.checkmark__circle}
                                        cx="26"
                                        cy="26"
                                        r="25"
                                        fill="none"
                                      />
                                      <path
                                        className={classes.checkmark__check}
                                        fill="none"
                                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                      />
                                    </svg>
                                  </div>
                                )}
                                <div className={classes.creatingDBMsg}>
                                  {showFifthTick
                                    ? 'GPT chat interface for DBs available'
                                    : 'Creating GPT chat interface for DBs'}
                                </div>
                              </div>
                              {/* <div className={classes.fadeinFloating}>Please wait as this may take a few minutes</div>
                               */}
                            </div>
                            <div className={classes.successMsgWrapper}>
                              {/* <div className={classes.successMsg}>
                        <div className={classes.link} onClick={handleInfoNavigate}>Please click here to use learn how to use BerryDB</div>
                      </div> */}
                            </div>
                            <div className={classes.loginSection}>
                              <div
                                style={{
                                  display: 'flex',
                                  gap: '10px',
                                  marginTop: '15px',
                                  marginBottom: '15px',
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  endIcon={<CancelIcon />}
                                  className={classes.cancelBtnField}
                                  disabled={!showSixthTick}
                                  fullWidth
                                  type="button"
                                  onClick={() => closeSplashScreen()}
                                >
                                  Close
                                </Button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {!showOTPLogin && (
                            <>
                              {showBerryDBForm ? (
                                <form
                                  className={classes.signInBoxContent}
                                  style={{
                                    transition: 'height 0.5s ease-in-out',
                                  }}
                                >
                                  <TextField
                                    id="username"
                                    label="Email"
                                    className={classes.textField}
                                    fullWidth
                                    margin="normal"
                                    onChange={e => setUsernameForLogin(e)}
                                    onKeyUp={e => {
                                      if (e.key === 'Enter') {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (
                                          !(
                                            username &&
                                            isEmailValid(username) &&
                                            password
                                          )
                                        ) {
                                          showErrorToast(
                                            'Enter valid email and password'
                                          );
                                          return;
                                        }
                                        loginUser();
                                      }
                                    }}
                                  />
                                  <TextField
                                    id="password"
                                    label="Password"
                                    className={classes.textField}
                                    type="password"
                                    fullWidth
                                    margin="normal"
                                    onChange={e => setPasswordForLogin(e)}
                                    onKeyUp={e => {
                                      if (e.key === 'Enter') {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (
                                          !(
                                            username &&
                                            isEmailValid(username) &&
                                            password
                                          )
                                        ) {
                                          showErrorToast(
                                            'Enter valid email and password'
                                          );
                                          return;
                                        }
                                        loginUser();
                                      }
                                    }}
                                  />
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    endIcon={<LoginIcon />}
                                    className={classes.loginBtnField}
                                    fullWidth
                                    type="button"
                                    onClick={() => loginUser()}
                                  >
                                    Login
                                  </Button>
                                </form>
                              ) : (
                                <div className={classes.berryDBBtnContent}>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.berryDBBtnField}
                                    fullWidth
                                    type="button"
                                    onClick={() => showOTPLoginForm()}
                                  >
                                    {CUSTOM_MESSAGES.SIGNIN_WITH_BERRYDB}
                                  </Button>
                                </div>
                              )}

                              <div style={{ display: 'flex', width: '100%' }}>
                                <hr width="40%" />
                                <span
                                  style={{ lineHeight: 2, fontWeight: 800 }}
                                >
                                  OR
                                </span>
                                <hr width="40%" />
                              </div>
                            </>
                          )}

                          <div className={classes.loginSection}>
                            {!showOTPLogin && (
                              <>
                                <GoogleLogin
                                  // clientId="585787995241-429f9o7t0o94p57248beh571940uvhdb.apps.googleusercontent.com"
                                  buttonText="LOGIN WITH GOOGLE"
                                  render={renderProps => (
                                    <Button
                                      variant="outlined"
                                      endIcon={<GoogleIcon />}
                                      color="primary"
                                      className={classes.googleBtnField}
                                      fullWidth
                                      type="button"
                                      onClick={renderProps.onClick}
                                    >
                                      {CUSTOM_MESSAGES.SIGNIN_WITH_GOOGLE}
                                    </Button>
                                  )}
                                  onSuccess={responseGoogle}
                                  onFailure={responseGoogle}
                                />
                                <Button
                                  variant="outlined"
                                  endIcon={<EmailIcon />}
                                  color="primary"
                                  className={classes.btnField}
                                  fullWidth
                                  type="button"
                                  onClick={() => showOTPLoginForm()}
                                >
                                  {CUSTOM_MESSAGES.SIGNIN_WITH_EMAIL}
                                </Button>
                              </>
                            )}

                            {showOTPLogin && (
                              <Card
                                style={{
                                  borderRadius: '10px',
                                  background: '#F7F7F7',
                                }}
                              >
                                <CardContent
                                  className={classes.berryBDLogin}
                                  style={{
                                    transition: 'height 0.5s ease-in-out',
                                  }}
                                >
                                  <div className={classes.signInBoxContent}>
                                    <TextField
                                      id="username"
                                      label="Email"
                                      className={classes.textField}
                                      fullWidth
                                      margin="normal"
                                      value={username}
                                      onChange={e => setUsernameForLogin(e)}
                                      disabled={newUserPwd || sendingCode}
                                      onKeyUp={e => {
                                        if (e.key === 'Enter') {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          if (
                                            !(
                                              username && isEmailValid(username)
                                            )
                                          ) {
                                            console.log(
                                              'Invalid email address'
                                            );
                                            dispatch({
                                              type: 'notification',
                                              payload: true,
                                            });
                                            dispatch({
                                              type: 'msgSeverityType',
                                              payload: 'error',
                                            });
                                            dispatch({
                                              type: 'msg',
                                              payload: 'Invalid email address',
                                            });
                                            return;
                                          }
                                          otpRequest();
                                        }
                                      }}
                                    />

                                    {clickedOTPBtn && !newUserPwd && (
                                      <div
                                        style={{
                                          marginTop: '15px',
                                        }}
                                      >
                                        <FormControl
                                          variant="outlined"
                                          className={classes.customInputLabel}
                                        >
                                          <InputLabel htmlFor="outlined-adornment-enterotp">
                                            Enter Code
                                          </InputLabel>

                                          <OutlinedInput
                                            id="outlined-adornment-enterotp"
                                            type="text"
                                            label="Enter Code"
                                            inputProps={{
                                              autocomplete: 'new-password',
                                              form: {
                                                autocomplete: 'off',
                                              },
                                            }}
                                            disabled={newUserPwd}
                                            onChange={e => setOTPForLogin(e)}
                                            onKeyUp={e => {
                                              if (e.key === 'Enter') {
                                                loginOTPUser();
                                                return;
                                              }
                                            }}
                                            endAdornment={
                                              <InputAdornment position="end">
                                                <Link
                                                  href="#"
                                                  // variant="subtitle2"
                                                  underline="hover"
                                                  onClick={() =>
                                                    resendOTPMail()
                                                  }
                                                >
                                                  {'Resend Code'}
                                                </Link>
                                              </InputAdornment>
                                            }
                                          />
                                        </FormControl>
                                      </div>
                                    )}
                                    {newUserPwd && (
                                      <div>
                                        <p
                                          style={{
                                            margin: '0px',
                                            fontStyle: 'Roboto',
                                          }}
                                        >
                                          As you are a new user, Please set the
                                          password.
                                        </p>
                                        <TextField
                                          id="setNewPassword"
                                          inputProps={{
                                            autocomplete: 'new-password',
                                            form: {
                                              autocomplete: 'off',
                                            },
                                          }}
                                          label="New Password"
                                          className={classes.textField}
                                          type="password"
                                          fullWidth
                                          margin="normal"
                                          onChange={e => setPasswordForLogin(e)}
                                          onKeyUp={e => {
                                            if (e.key === 'Enter') {
                                              if (
                                                isEmailValid(username) &&
                                                password
                                              ) {
                                                createNewUserPwd();
                                                return;
                                              }
                                              showErrorToast(
                                                'Invalid email or password'
                                              );
                                              return;
                                            }
                                          }}
                                        />
                                      </div>
                                    )}
                                    <div
                                      style={{
                                        display: 'flex',
                                        gap: '10px',
                                        marginTop: '15px',
                                      }}
                                    >
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        endIcon={<CancelIcon />}
                                        className={classes.cancelBtnField}
                                        fullWidth
                                        type="button"
                                        onClick={() => cancelUser()}
                                      >
                                        Cancel
                                      </Button>
                                      {clickedOTPBtn ? (
                                        <Button
                                          variant="outlined"
                                          color="primary"
                                          className={classes.otpLoginBtnField}
                                          fullWidth
                                          type="button"
                                          onClick={() => loginOTPUser()}
                                        >
                                          Verify Code
                                        </Button>
                                      ) : newUserPwd ? (
                                        <Button
                                          variant="outlined"
                                          color="primary"
                                          endIcon={<LoginIcon />}
                                          className={classes.sendOTP}
                                          fullWidth
                                          type="button"
                                          onClick={() => createNewUserPwd()}
                                        >
                                          Login
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="outlined"
                                          color="primary"
                                          // endIcon={<SendIcon />}
                                          className={classes.sendOTP}
                                          fullWidth
                                          type="button"
                                          disabled={sendingCode}
                                          onClick={() => {
                                            if (!isEmailValid(username)) {
                                              showErrorToast(
                                                'Please Invalid email address'
                                              );
                                              return;
                                            }
                                            otpRequest();
                                          }}
                                        >
                                          {sendingCode
                                            ? 'Sending code...'
                                            : 'Send Code'}
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </CardContent>
                              </Card>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              }
            </div>
          </Card>

          <NotificationMessage
            open={open}
            msg={msg}
            msgType={msgSeverityType}
            showDuration={3000}
            handleClose={handleClose}
          />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login,
      saveFbGoogleUsers,
      getUserDetails,
      setNotificationMessage,
      sendotp,
      verifyotp,
      setPassword,
    },
    dispatch
  );

// Reducer function of Signin

function signInReducer(state, action) {
  switch (action.type) {
    case 'username': {
      return {
        ...state,
        username: action.payload,
      };
    }
    case 'password': {
      return {
        ...state,
        password: action.payload,
      };
    }
    case 'loginCode': {
      return {
        ...state,
        loginCode: action.payload,
      };
    }
    case 'msg': {
      return {
        ...state,
        msg: action.payload,
      };
    }
    case 'msgSeverityType': {
      return {
        ...state,
        msgSeverityType: action.payload,
      };
    }
    case 'notification': {
      return {
        ...state,
        open: action.payload,
      };
    }
    case 'showOTPLogin': {
      return {
        ...state,
        showOTPLogin: action.payload,
      };
    }
    case 'showBerryDBForm': {
      return {
        ...state,
        showBerryDBForm: action.payload,
      };
    }
    case 'recvUserSurrogateId': {
      return {
        ...state,
        recvUserSurrogateId: action.payload,
      };
    }
    case 'clickedOTPBtn': {
      return {
        ...state,
        clickedOTPBtn: action.payload,
      };
    }

    case 'newUserPwd': {
      return {
        ...state,
        newUserPwd: action.payload,
      };
    }

    case 'showCreatingDBsOverlay': {
      return {
        ...state,
        showCreatingDBsOverlay: action.payload,
      };
    }

    case 'sendingCode': {
      return {
        ...state,
        sendingCode: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

// Initialing the state variable of Signin Index page

const signinState = {
  username: '',
  password: '',
  loginCode: '',
  msg: '',
  msgSeverityType: '',
  recvUserSurrogateId: '',
  // resendOTP: false,
  open: false,
  showOTPLogin: false,
  clickedOTPBtn: false,
  showBerryDBForm: true,
  newUserPwd: false,
  showCreatingDBsOverlay: false,
  sendingCode: false,
};

export default withRouter(connect(null, mapDispatchToProps)(Signin));

// export default Signin;
