/* eslint-disable */
import React, { useState } from 'react';
import { IconButton, Snackbar } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopyToClipboardButton = props => {
  const [open, setOpen] = useState(false);
  const iconSize = 'small';
  const snackbarMessage = 'Copied to clipboard';

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(props.text);
  };

  return (
    <>
      <IconButton onClick={handleClick} size={iconSize}>
        <ContentCopyIcon fontSize={iconSize} />
      </IconButton>
      <Snackbar
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
};

export default CopyToClipboardButton;
