/* eslint-disable */
import { VALUES } from '@constants';
import { getDatabaseList, getFilterData } from '@reducers/database';
import { updateDocuments } from '@reducers/documents';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getFormValues } from 'redux-form';
import {
  getApplicationTags,
  getCategoryTags,
  getChannelTags,
  getItemDetails,
  updateItemDetails,
  updateThumbnail,
} from '../../reducers/tags';
import DetailsComponent from './Detail';

class DetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabId: 0,
      isLoading: true,
      applicationTags: VALUES.data_applicationTags,
      annotationTags: VALUES.data_annotationTags,
      arrayOfApplnTags: [],
      dbName: this.props.match.url.split('/')[2],
      itemId: this.props.match.url.split('/')[3],
      dbId: '',
      schemaId: '',
      filterDataList: {},
      successMsg: '',
      failureMsg: '',
      open: false,
      thumbnail: '',
      editable: false,
      isUploadInProgress: false,
      apiCallhappened: false,
      thumbnailToUpload: '',
      filterTags: [],
      isEditDetailsEnabled: false
    };
  }

  componentDidMount() {
    this.renderApiData();
  }

  renderApiData = () => {
    const userId = window.localStorage.getItem('userSurrogateId');

    this.getItemDetails();

    // this.props.getDatabaseList(userId, 0, 0).then(data => {
    //   data.responseList.map(database => {
    //     if (database.id == this.props.match.url.split('/')[2]) {
    //       this.setState(
    //         { dbId: database.id, schemaId: database.schemaId },
    //         () => {
    //           this.props
    //             .getFilterData(this.state.dbId, this.state.schemaId)
    //             .then(results => {
    //               this.setState({
    //                 filterDataList: Object.entries(results.responseList[0]),
    //                 filterTags: results.responseList[0],
    //               });
    //               this.getItemDetails();
    //             });
    //         }
    //       );
    //     }
    //   });
    // });
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState(
        {
          dbName: this.props.match.url.split('/')[2],
          itemId: this.props.match.url.split('/')[3],
          isLoading: true,
          activeTabId: 0,
        },
        () => {
          this.renderApiData();
        }
      );
    }
  }

  getItemDetails = () => {
    let objData = {};
    const data = {
      databaseId: this.state.dbName,
      objectId: this.state.itemId,
      pageNo: 1,
      pageSize: 32,
    };
    this.props.getItemDetails(data).then(details => {
      let filterFtsTypes = Object.keys(details.items[0]).filter(
        x => !Object.keys(this.state.filterTags).includes(x)
      );
      let filterVideoTypes = filterFtsTypes.filter(
        x => x !== details.items[0]['videoType'] && x !== 'videoType'
      );
      let filterAudioTypes = filterVideoTypes.filter(
        x => x !== details.items[0]['audioType'] && x !== 'audioType'
      );
      let filterImageTypes = filterAudioTypes.filter(
        x => x !== details.items[0]['imageType'] && x !== 'imageType'
      );
      let filterTextTypes = filterImageTypes.filter(
        x =>
          x !== details.items[0]['textType'] &&
          x !== 'textType' &&
          x !== 'databaseName' &&
          x !== 'annotations' &&
          x !== 'uploadedTime' &&
          x !== 'schemaName' &&
          x !== 'userId' &&
          x !== 'ftsType' &&
          x !== 'itemPic' &&
          x !== 'databaseId' &&
          x !== 'uploadType' &&
          x !== 'userEmail' &&
          x !== 'userId' &&
          x !== 'id' &&
          x !== 'objectId' &&
          x !== '_meta' &&
          x !== details.items[0].ftsType
      );
      let tagsToBeFiltered = Object.keys(details.items[0]).filter(x =>
        filterTextTypes.includes(x)
      );

      Object.entries(details.items[0]).map(([key, value]) => {
        tagsToBeFiltered.map(each => {
          if (each === key) Object.assign(objData, { [key]: value });
        });
      });

      let filterTagArray = [];
      Object.entries(objData).map((el, index) => {
        const res = { key: el[0], value: (typeof (el[1]) == "string") ? el[1] : JSON.stringify(el[1], null, 2) };
        filterTagArray.push(res);
      });
      this.setState(
        {
          databaseDetails: details.items && details.items[0],
          metadata: details.metadata,
          thumbnail: details.items[0].itemPic,
          isLoading: false,
          apiCallhappened: true,
        },
        () => {
          const itemTags =
            this.state.databaseDetails &&
            this.state.databaseDetails.applicationTags;
          const applnTags =
            this.props.applicationTags && this.props.applicationTags;
          const filteredAnnotationTags =
            details &&
            details.items &&
            details.items[0].annotations &&
            details.items[0].annotations.length &&
            details.items[0].annotations[0].result;
          const annotationRespList = [];
          filteredAnnotationTags &&
            filteredAnnotationTags.map((el, index) => {
              const tagKey = el.type;
              const text = (el.value && el.value.text) || '';
              // const text = el.value && el.value.text ? (el.value.text.length ? el.value.text[0] : el.value.text) : '';
              const valueText =
                (el.value && el.value[tagKey] && el.value[tagKey]) || '';
              if (text) {
                annotationRespList.push({
                  type: valueText || tagKey,
                  value: text,
                  result: el,
                });
              } else {
                annotationRespList.push({
                  type: tagKey,
                  value: valueText,
                  result: el,
                });
              }
            });
          const applicationTags = Object.assign(this.state.applicationTags, {
            responseList: filterTagArray,
          });
          const annotationTags = Object.assign(this.state.annotationTags, {
            responseList: annotationRespList,
          });
          this.setState(prevState => ({
            ...prevState,
            applicationTags,
            annotationTags,
            arrayOfApplnTags: filterTagArray,
          }));

          const arrayWithTagValue = [];
          const arrayWithoutTagValue = [];
          let res1 = {};
          let res2 = {};
          if (itemTags) {
            applnTags &&
              applnTags.map(tag => {
                const tagName = tag.tagName;
                if (itemTags[tagName]) {
                  res1 = { key: tagName, value: itemTags[tagName] };
                  arrayWithTagValue.push(res1);
                } else {
                  res2 = { key: tagName, value: '' };
                  arrayWithoutTagValue.push(res2);
                }

                this.setState(
                  { arrayOfApplnTags: [...this.state.filterTagArray] },
                  () => {
                    const applicationTags = Object.assign(
                      this.state.applicationTags,
                      { responseList: this.state.filterTagArray }
                    );
                    const annotationTags = Object.assign(
                      this.state.annotationTags,
                      { responseList: annotationRespList }
                    );
                    this.setState(prevState => ({
                      ...prevState,
                      applicationTags,
                      annotationTags,
                    }));
                  }
                );
              });
          }
        }
      );
    });
  };

  enableEdit = () => {
    this.setState({ editable: true });
  };

  uploadThumbnail = e => {
    console.log('vavvvvv', e.target.files[0]);
    if (e.target.files[0]) {
      this.setState({
        thumbnail: URL.createObjectURL(e.target.files[0]),
        thumbnailToUpload: e.target.files[0],
      });
    }
  };

  removeProfilePhoto = () => {
    this.setState({ thumbnail: '' });
  };

  setActiveTab = index => {
    let id = '';
    switch (index) {
      case 0:
        id = 0;
        break;
      case 1:
        id = 1;
        break;
      case 2:
        id = 2;
        break;
      default:
        break;
    }
    this.setState({
      activeTabId: id,
    });
  };

  handleSubmit = (jsonData, databaseId) => {
    console.log('values', this.state.thumbnail);
    const apiKey = window.localStorage.getItem('apiKey');
    this.setState({ isUploadInProgress: true });
    /* const appTags =
      this.props.appTagsFormState &&
      this.props.appTagsFormState.responseList.reduce(
        (obj, cur) => ({ ...obj, [cur.key]: cur.value }),
        {}
      );
    const tagsToBeAdded = Object.assign(
      { applicationTags: appTags },
      { thumbnail: this.state.thumbnail ? this.state.thumbnail : '' }
    );
    delete values.applicationTags;
    if (this.state.thumbnail) {
      delete values.thumbnail;
      const formData = new FormData();
      formData.append('file', this.state.thumbnailToUpload);
      this.props.updateThumbnail(
        formData,
        orgName,
        this.state.dbName,
        values.id,
        values.type
      );
      // const thumbnailToBeAdded = Object.assign({ thumbnail: this.state.thumbnail })
      // data = Object.assign(thumbnailToBeAdded, values);
    }
    data = Object.assign(tagsToBeAdded, values); */
    this.props.updateDocuments(jsonData, apiKey, databaseId).then((successData) => this.successUpdate("Updated Successfully!")).catch((errorData) => this.failureUpdate("Failed to Update!"));
    /* this.props
      .updateItemDetails(data, orgName, this.state.dbName)
      .then(successData => this.successUpdate(successData))
      .catch(errorData => this.failureUpdate(errorData)); */
  };

  successUpdate = message => {
    this.setState({
      successMsg: message,
      open: true,
      editable: false,
      isUploadInProgress: false,
      isEditDetailsEnabled: false
    });
    this.getItemDetails();
  };

  failureUpdate = message => {
    this.setState({
      failureMsg: message,
      open: true,
      isUploadInProgress: false,
    });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
  };

  onCancelEnableEdit = () => {
    this.setState({ editable: false });
  };

  render() {
    return (
      <DetailsComponent
        tabList={VALUES.DETAIL_TAGS}
        setActiveTab={this.setActiveTab}
        activeTabId={this.state.activeTabId}
        channelTags={this.props.channelTags}
        categoryTagsList={this.props.categoryTags}
        itemDetails={this.state.databaseDetails && this.state.databaseDetails}
        metadata={this.state.metadata}
        applicationTags={this.props.applicationTags}
        isLoading={this.state.isLoading}
        applicationTagsForForm={this.state.applicationTags}
        onSubmit={this.handleSubmit}
        successMsg={this.state.successMsg}
        failureMsg={this.state.failureMsg}
        open={this.state.open}
        handleClose={this.handleClose}
        initialValues={this.state.databaseDetails}
        thumbnail={this.state.thumbnail}
        uploadThumbnail={this.uploadThumbnail}
        removeProfilePhoto={this.removeProfilePhoto}
        editable={this.state.editable}
        enableEdit={this.enableEdit}
        enableEditDetails={this.enableEditDetails}
        isUploadInProgress={this.state.isUploadInProgress}
        onCancelEnableEdit={this.onCancelEnableEdit}
        annotationTags={this.state.annotationTags}
        filteredTags={this.state.arrayOfApplnTags}
        apiCallhappened={this.state.apiCallhappened}
        filterDataList={{}}
        abc={this.state.abc}
        setEditDetailsEnabled={(enable) => {
          this.setState({ isEditDetailsEnabled: enable, activeTabId: enable ? 2 : this.state.activeTabId })
        }}
        isEditDetailsEnabled={this.state.isEditDetailsEnabled}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    channelTags: state.tags.channelList,
    categoryTags: state.tags.tagsList,
    applicationTags: state.tags.applnTagsList,
    appTagsFormState: getFormValues('applicationTags')(state),
    data: getFormValues('DetailForm')(state),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCategoryTags,
      getChannelTags,
      getItemDetails,
      getApplicationTags,
      updateItemDetails,
      updateThumbnail,
      getDatabaseList,
      getFilterData,
      updateDocuments
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetailsContainer)
);