/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { reduxForm } from 'redux-form';
import { getSchemasList, deleteSchema } from '../../reducers/database';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import TableContainer from '@mui/material/TableContainer';
import { styled } from '@material-ui/core/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '@mui/material/Pagination';
import EN from '../../localization/EN';
import Button from '@material-ui/core/Button';
import styles from './Schemas.module.scss';
import moment from 'moment-timezone';
import CircularProgress from '@mui/material/CircularProgress';
import NotificationMessage from '@components/NotificationMessage';
import { setNotificationMessage } from '../../reducers/notification';
import { TextField, debounce } from '@material-ui/core';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';

let id = 0;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Schemas = props => {
  const [schemasList, setSchemasList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(1);

  const [msg, setMsg] = useState('');
  const [open, setOpen] = useState(false);
  const [msgType, setMsgType] = useState('');
  // const [msg, setMsg] = useState();

  const [
    fetchingAutoCompleteSchemas,
    setFetchingAutoCompleteSchemas,
  ] = useState(false);
  const [autoCompleteSchemas, setAutoCompleteSchemas] = useState(null);
  const [searchKeyValid, setSearchKeyValid] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: 15,
    },
  }));

  const onDeleteSchema = schemaId => {
    props
      .deleteSchema(schemaId)
      .then(response => {
        setMsg(response.message);
        setOpen(true);
        setMsgType('success');
      })
      .catch(e => {
        setMsg(e.message);
        setOpen(true);
        setMsgType('error');
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  useEffect(() => {
    if (pageNo === null || pageNo === undefined) {
      return;
    }
    fetchSchemaList(pageNo, pageSize);
  }, [pageNo, msg]);

  const fetchSchemaList = (page, size) => {
    const userSurrogateId = window.localStorage.getItem('userSurrogateId');
    setIsLoading(true);
    props
      .getSchemasList(userSurrogateId, page, size)
      .then(results => {
        const schemaList =
          results.responseList &&
          results.responseList.map(schemaResult => {
            return schemaResult;
          });
        setSchemasList(schemaList);
        setTotalCount(results.totalCount);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchSchemaListForAutoComplete = (page, size, searchKey = null) => {
    const userSurrogateId = window.localStorage.getItem('userSurrogateId');
    return props.getSchemasList(userSurrogateId, -1, size, null, searchKey);
  };

  const handleChangePage = (event, value) => {
    setPageNo(value);
  };

  const redirectToSchemaPage = id => {
    if (id) {
      props.history.push(`/schemaDetails/${id}`);
    } else {
      props.history.push(`/schemaDetails`);
    }
  };

  const handleCount = () => {
    if (totalCount % pageSize == 0) {
      return totalCount / pageSize;
    } else {
      return Math.floor(totalCount / pageSize) + 1;
    }
  };

  const getAutoCompleteDatabaseList = debounce(key => {
    setFetchingAutoCompleteSchemas(true);
    fetchSchemaListForAutoComplete(pageNo, pageSize, key)
      .then(res => {
        const schemaList =
          res && res.responseList && res.responseList.length
            ? res.responseList
            : [];
        setAutoCompleteSchemas(schemaList);
      })
      .catch(err => {
        setAutoCompleteSchemas(null);
      })
      .finally(() => {
        setFetchingAutoCompleteSchemas(false);
      });
  }, 500);

  const renderAutoComplete = () => {
    if (!searchKeyValid) {
      return (
        <div className={[styles['item'], styles['item-msg']].join(' ')}>
          Enter your search key (3 characters Min.)
        </div>
      );
    }
    if (fetchingAutoCompleteSchemas) {
      return (
        <div className={[styles['item'], styles['item-msg']].join(' ')}>
          Fetching...
        </div>
      );
    }
    if (!(autoCompleteSchemas && autoCompleteSchemas.length)) {
      return (
        <div className={[styles['item'], styles['item-msg']].join(' ')}>
          No Schemas found
        </div>
      );
    }
    return (
      <div>
        {autoCompleteSchemas.map(sc => (
          <div
            className={styles['item']}
            onClick={() => redirectToSchemaPage(sc.id)}
          >
            {sc.name}
            <a
              href={`/schemaDetails/${sc.id}`}
              target="_blank"
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <OpenInNewIcon className={styles['icon']} />
            </a>
          </div>
        ))}
      </div>
    );
  };

  const renderSchemaList = () => {
    return (
      <Paper className="table-responsive">
        <TableContainer component={Paper} style={{ boxShadow: 3 }}>
          <Table sx={{ minWidth: 100 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">
                  <b>Name</b>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <b>Description</b>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <b>Type</b>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <b>Action</b>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schemasList.map(schemaResult => (
                <StyledTableRow key={schemaResult.id}>
                  <StyledTableCell
                    align="center"
                    className="cursor"
                    onClick={() => redirectToSchemaPage(schemaResult.id)}
                  >
                    <Typography>
                      <b>
                        {schemaResult.name.charAt(0).toUpperCase() +
                          schemaResult.name.slice(1)}
                      </b>
                    </Typography>
                    <Typography className="grayFont">
                      {moment
                        .utc(schemaResult.createdTime)
                        .tz(moment.tz.guess(true))
                        .format('ll')}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {schemaResult.description}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {schemaResult.type ? 'User generated' : 'Built-in'}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <div className={styles['actions-wrapper']}>
                      {/* {!!schemaResult.type && (
                        <NavLink
                          to={() => `schemaDetails/${schemaResult.id}`}
                          aria-label="Edit Schema"
                        >
                          <div
                            className={[
                              styles['btn'],
                              styles['btn--blue'],
                            ].join(' ')}
                          >
                            <EditOutlinedIcon
                              className={styles['btn--icon']}
                              style={{ fontSize: '18px' }}
                            />
                            Edit
                          </div>
                        </NavLink>
                      )} */}
                      {schemaResult.numDatabases === 0 && !!schemaResult.type && (
                        <IconButton
                          // disabled={true}
                          onClick={() => {
                            onDeleteSchema(schemaResult.id);
                          }}
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                      {/* {schemaResult.numDatabases === 0 && !!schemaResult.type && (
                        <div
                          onClick={() => {
                            onDeleteSchema(schemaResult.id);
                          }}
                          aria-label="Delete Schema"
                        >
                          <div
                            className={[styles['btn'], styles['btn--red']].join(
                              ' '
                            )}
                          >
                            <DeleteOutlinedIcon
                              className={styles['btn--icon']}
                              style={{ fontSize: '18px' }}
                            />
                            Delete
                          </div>
                        </div>
                      )} */}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  };
  return (
    <>
      <div className="schemalist">
        {isLoading ? (
          <CircularProgress
            style={{ position: 'absolute', top: '40%', right: '45%' }}
          />
        ) : (
          <Grid
            container
            direction="row"
            className="p-3r"
            style={{ width: 1300 }}
          >
            <Grid container spacing={1} style={{ marginTop: 10 }}>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="h5">
                  {EN.AVAILABLE_SCHEMAS_LIST}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} className="textEnd">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  className={styles.customBtn}
                  onClick={() => redirectToSchemaPage()}
                >
                  {`${EN.CREATE}`}
                </Button>
              </Grid>
            </Grid>
            <div className={styles['search-input-wrapper']}>
              <TextField
                id="auto-complete"
                label="Search"
                className={styles['search-input']}
                type="text"
                fullWidth
                margin="normal"
                onFocus={() => setIsSearchFocused(true)}
                onBlur={() => setTimeout(() => setIsSearchFocused(false), 300)}
                onChange={async e => {
                  const key = (e.target.value || '').trim();
                  if (key && key.length > 2) {
                    setSearchKeyValid(true);
                    setFetchingAutoCompleteSchemas(true);
                    getAutoCompleteDatabaseList(key);
                  } else {
                    setSearchKeyValid(false);
                    setAutoCompleteSchemas(null);
                  }
                }}
              />
              <div className={styles['search-autocomplete-wrapper']}>
                {isSearchFocused && (
                  <div className={styles['search-autocomplete']}>
                    {renderAutoComplete()}
                  </div>
                )}
              </div>
            </div>
            <Grid item xs={12} sm={12} md={12} className={'mt-15'}>
              {renderSchemaList()}
            </Grid>
            <Grid container className="justifyContentEnd my-10px">
              <Grid item>
                <Pagination
                  count={handleCount()}
                  color="primary"
                  page={pageNo}
                  onChange={handleChangePage}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>

      <NotificationMessage
        open={open}
        msg={msg}
        msgType={msgType}
        handleClose={handleClose}
      />
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSchemasList,
      deleteSchema,
      setNotificationMessage,
    },
    dispatch
  );

const ModuleForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Schemas)
);

export default reduxForm({
  form: 'moduleForm', // a unique name for this form
})(ModuleForm);
