/* eslint-disable */
export const PIPELINES_MODEL = 'PIPELINES_MODEL';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PIPELINES_MODEL: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export const getPipelinesExtractTypes = (apiKey, dataType) => {
  return {
    types: [PIPELINES_MODEL, PIPELINES_MODEL, PIPELINES_MODEL],
    promise: client =>
      client.get(
        `getPipelinesExtractTypes?apiKey=${apiKey}${
          dataType ? '&dataType=' + dataType : ''
        }`,
        {}
      ),
  };
};
