/* eslint-disable */
import React, { createContext } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
// import { ConnectedRouter } from 'react-router-redux';
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers, createStore } from 'redux';
import { textareaSlice } from './features/textarea/textareaSlice';
import { dataSlice } from './features/data/dataSlice';
import store from './store';
import PrimerRoutes from './PrimerRoutes';
import {
  GOOGLE_OAUTH_CLIENT_ID,
  POSTHOG_HOST,
  POSTHOG_KEY,
} from './constants/appConfig';

import './include/bootstrap';
import './index.scss';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';

const rootReducer = combineReducers({
  data: dataSlice.reducer,
  textarea: textareaSlice.reducer,
});

const ContextA = React.createContext();
const ContextB = React.createContext();

const target = document.querySelector('#root');
const jsonStore = configureStore({ reducer: rootReducer });
// store={window.location.pathname === '/jsonVisualEditor/' ? jsonStore : store}

// const storeA = createStore(store);
// const storeB = createStore(jsonStore);

posthog.init(POSTHOG_KEY, {
  api_host: POSTHOG_HOST,
});

render(
  <PostHogProvider client={posthog}>
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
      <Provider store={store}>
        {/* <Provider store={rootReducer} > */}
        <div>
          <PrimerRoutes />
          {/* {routes} */}
        </div>
      </Provider>
    </GoogleOAuthProvider>
  </PostHogProvider>,
  target
);
