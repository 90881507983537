/* eslint-disable */
import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import cn from 'classnames';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import customStyle from './TableForm.module.css';
import validate from './validate';
import { makeStyles } from '@material-ui/core/styles';
// import { array } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  input: {
    color: "#222222cc",
    textAlign: 'center'
  },
}));

const renderField = ({
  input, meta: { touched, error }, disable, isLast, enableTextField, isEditable, formName
}) => {
  console.log('check', )
  const classes = useStyles();
  return (
  <>
    <>
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
        id=""
        className={isLast && !disable ? 'align-self-end' : 'align-self-center'}
        InputProps={{
          disableUnderline: (disable || !isLast ||((formName === "applicationTags" || formName === "annotationTags") && enableTextField) || ((formName === "applicationTags" || formName === "annotationTags") && !isEditable)),
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          className: classes.input
        }}
        variant="standard"
        fullWidth
        disabled={disable || !isLast || ((formName === "applicationTags" || formName === "annotationTags") && enableTextField) || ((formName === "applicationTags" || formName === "annotationTags") && !isEditable)}
      />
      {touched && error && <span>{error}</span>}
    </>
  </>
  )
};

const renderMembers = ({
  fields, meta: { error, submitFailed }, myProps, col, action, disable, formName, enableEdit
}) => (
  <>
    {fields.map((member, index) => {
      return (
        <>
          {myProps.map((el, idx) => {
            return (
              <Grid className={cn('p-2 d-flex', customStyle.borderBottom, customStyle.itemHeight)} item xs={col[idx].xs}>
                <Field
                  name={`${member}.${el}`}
                  type="text"
                  component={renderField}
                  {...{
                    disable,
                    isLast: (formName !== "applicationTags" &&  formName !== "annotationTags") ? index === fields.length - 1 : true,
                    enableTextField: (formName === "applicationTags" ||  formName === "annotationTags") && idx === 0,
                    isEditable: (formName === "applicationTags" || formName === "annotationTags") && idx === 1 && enableEdit,
                    formName: formName
                  }}
                />
              </Grid>
            );
          })}
          {index !== fields.length - 1 && action && (
            <Grid xs={2} className={cn('d-flex px-2 justify-content-center', customStyle.borderBottom, customStyle.itemHeight)}>
              <IconButton onClick={() => fields.remove(index)} className="p-2 align-self-center" aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </Grid>
          )}
        </>
      );
    })}
    {action && (
    <Grid xs={2} sx={{display: 'flex'}} className={cn('d-flex justify-content-center px-2', customStyle.borderBottom, customStyle.itemHeight)}>
      <IconButton onClick={() => fields.push({})} className="p-2 align-self-center" aria-label="Delete">
        <AddIcon />
      </IconButton>
    </Grid>
    )}
    {submitFailed && error && <span>{error}</span>}
  </>
);

const FieldArraysForm = (props) => {
  const {
    handleSubmit, datafields
  } = props;
  return (
    <form onSubmit={handleSubmit} className="w-100">
      <Grid className={cn('rounded shadow-sm', customStyle.fieldArrayContainer)} container xs={12}>
        {datafields.fieldArrayRows && (
        <FieldArray
          name="responseList"
          component={renderMembers}
          {...{
            myProps: datafields.fieldArrayRows,
            col: datafields.colSpan,
            action: datafields.action,
            disable: datafields.disabled,
            formName: datafields.form,
            enableEdit: props.enableEdit
          }}
        />
        )}
      </Grid>
      {!datafields.hideSaveButton && (
        <Grid container direction="row-reverse" className="py-4">
          <Button className="ml-3" type="submit" variant="contained" color="primary">
            {datafields.isLastTab ? 'Save' : 'Save & Next'}
          </Button>
          <Button onClick={() => props.onClickFinish()} type="button" variant="outlined" color="primary">
            Finish
          </Button>
        </Grid>
      )}
    </form>
  );
};

export default reduxForm({
//   form: 'fieldArrays', // a unique identifier for this form
  enableReinitialize: true,
  validate
})(FieldArraysForm);
