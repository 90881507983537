/* eslint-disable */
export const CHAT_EMBED = 'EMBED_DATABASE';

export const CHAT_MODELS_LIST = 'CHAT_MODELS_LIST';
export const CHAT_MODELS_LIST_FAILURE = 'CHAT_MODELS_LIST_FAILURE';

export const PERSPECTIVE_LIST = 'PERSPECTIVE_LIST';
export const PERSPECTIVE_LIST_LOADING = 'PERSPECTIVE_LIST_LOADING';
export const PERSPECTIVE_LIST_FAILURE = 'PERSPECTIVE_LIST_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHAT_EMBED: {
      return {
        ...state,
      };
    }
    case CHAT_MODELS_LIST: {
      return {
        ...state,
      };
    }
    case CHAT_MODELS_LIST_FAILURE: {
      return {
        ...state,
      };
    }
    case PERSPECTIVE_LIST: {
      return {
        ...state,
      };
    }
    case PERSPECTIVE_LIST_LOADING: {
      return {
        ...state,
      };
    }
    case PERSPECTIVE_LIST_FAILURE: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export const chatEmbed = (apiKey, databaseName, openAiApiKey, k) => {
  return {
    types: [CHAT_EMBED],
    promise: client =>
      client.get(
        `chatEmbed?apiKey=${apiKey}&database=${databaseName}&openAIApiKey=${openAiApiKey}&k=${k}`,
        {}
      ),
  };
};

export const getChatModelList = apiKey => {
  return {
    types: [CHAT_MODELS_LIST, CHAT_MODELS_LIST_FAILURE],
    promise: client => client.get(`getChatModelList?apiKey=${apiKey}`, {}),
  };
};

export const getAllPerspectivesForOrg = apiKey => {
  return {
    types: [
      PERSPECTIVE_LIST,
      PERSPECTIVE_LIST_LOADING,
      CHAT_MODELS_LIST_FAILURE,
    ],
    promise: client =>
      client.get(`getAllPerspectivesForOrg?apiKey=${apiKey}`, {}),
  };
};

export const getPerspectivesForDatabase = (apiKey, dbId) => {
  return {
    types: [
      PERSPECTIVE_LIST,
      PERSPECTIVE_LIST_LOADING,
      CHAT_MODELS_LIST_FAILURE,
    ],
    promise: client =>
      client.get(
        `getAllPerspectivesForOrg?apiKey=${apiKey}&databaseId=${dbId}`,
        {}
      ),
  };
};

export const createPerspective = (apiKey, perspectiveObj) => {
  console.log('perspectiveObj', perspectiveObj);
  return {
    types: [
      PERSPECTIVE_LIST,
      PERSPECTIVE_LIST_LOADING,
      CHAT_MODELS_LIST_FAILURE,
    ],
    promise: client =>
      client.post(`createPerspective?apiKey=${apiKey}`, {
        data: perspectiveObj,
      }),
  };
};

export const deletePerspective = (apiKey, perspectiveId) => {
  return {
    types: [PERSPECTIVE_LIST],
    promise: client =>
      client.delete(
        `createPerspective?apiKey=${apiKey}&id=${perspectiveId}`,
        {}
      ),
  };
};
