/* eslint-disable */
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, { Component } from 'react';
import { Builder, Utils as QbUtils, Query } from 'react-awesome-query-builder';
// For AntDesign widgets only:
import 'antd/dist/antd.css'; // or import "react-awesome-query-builder/css/antd.less";
import AntdConfig from 'react-awesome-query-builder/lib/config/antd';
// For Material-UI widgets only:
import Divider from '@material-ui/core/Divider';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CreateIcon from '@mui/icons-material/Create';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import cn from 'classnames';
import { reduxForm } from 'redux-form';
import { ProductCardCompact } from '..';
import EN from '../../localization/EN';
import myStyles from './AnnotationFilter.module.css';

import 'react-awesome-query-builder/lib/css/compact_styles.css'; //optional, for more compact styles
import 'react-awesome-query-builder/lib/css/styles.css';
import { getValueByPath } from '../../helpers/utils';

// Choose your skin (ant/material/vanilla):
const InitialConfig = AntdConfig; // or MaterialConfig or BasicConfig

// You need to provide your own config. See below 'Config format'
// const config = {
//   ...InitialConfig,
//   fields: {
//     qty: {
//         label: 'Qty',
//         type: 'text',
//         // fieldSettings: {
//         //     min: 0,
//         // },
//         // valueSources: ['value'],
//         // preferWidgets: ['number'],
//     }
//   }
// };

// You can load query value from your backend storage (for saving see `Query.onChange()`)
const queryValue = { id: QbUtils.uuid(), type: 'group' };

class AnnotationBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), props.config),
      config: props.config,
      iv: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.config !== nextProps.config) {
      this.setState({
        tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), nextProps.config),
        config: nextProps.config,
      });
    }
    if (this.props.iv || this.props.databaseValue !== nextProps.databaseValue) {
      this.setState(
        {
          iv: true,
        },
        () => {
          setTimeout(() => {
            this.setState({ iv: false });
          }, 3000);
        }
      );
    }
  }

  render = () => (
    <div className="mt-5">
      <form className="w-100" onSubmit={this.props.handleSubmit}>
        <Grid container className="px-4 pb-2" spacing={2}>
          {/* <Grid item xs={2}>
              <FormControl className="w-100">
                <InputLabel
                  shrink={this.props.databaseList ? true : false}
                  htmlFor="age-native-simple"
                >
                  Database
                </InputLabel>
                <Select
                  name="database"
                  value={this.props.databaseValue}
                  onChange={this.props.handleChange('databaseName')}
                  placeholder={this.props.databaseValue}
                >
                  <MenuItem disabled value="">
                    <em>{EN.SELECT_DATABASE}</em>
                  </MenuItem>
                  {this.props.databaseList &&
                    this.props.databaseList.map((el, index) => {
                      return (
                        <MenuItem value={el.name} key={index}>
                          {el.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid> */}
          <Grid item xs={12}>
            {this.renderResult(this.state)}
          </Grid>
        </Grid>
      </form>
      <Divider />
      <div className={myStyles.displayItemContainer}>
        {this.props.enableQueryBuilder && (
          <>
            <label className={cn(myStyles.builderLabel, 'mb-0 ml-4')}>
              Query Builder
            </label>
            <Query
              {...this.state.config}
              value={this.state.tree}
              onChange={this.onChange}
              renderBuilder={this.renderBuilder}
            />
          </>
        )}
        {!this.props.enableQueryBuilder && (
          <div className={myStyles.disabledQueryBuilder} />
        )}
        <div className="annotation-filter-container">
          <div>
            <div className="annotations">
              <div className="annotation-select--label">
                {this.props.annotationLabels &&
                  this.props.annotationLabels.length > 0 && (
                    <FormControl className="w-100">
                      <InputLabel
                        shrink={this.props.annotationLabels ? true : false}
                        htmlFor="age-native-simple"
                      >
                        Annotation type
                      </InputLabel>
                      <Select
                        name="annotationlabel"
                        value={this.props.selectedAnnotationLabel}
                        onChange={this.props.handleLabelChange('labelName')}
                        placeholder={'Type'}
                      >
                        <MenuItem value={''} key={0.22}>
                          None
                        </MenuItem>
                        {this.props.annotationLabels &&
                          this.props.annotationLabels.map((el, index) => {
                            return (
                              <MenuItem value={el} key={index}>
                                {el}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  )}
              </div>
              <div className="annotation-select--value">
                {this.props.annotationLabelMap &&
                  this.props.annotationLabelMap[
                    this.props.selectedAnnotationLabel
                  ] &&
                  this.props.annotationLabelMap[
                    this.props.selectedAnnotationLabel
                  ].length > 0 && (
                    <FormControl className="w-100">
                      <InputLabel
                        shrink={
                          this.props.annotationLabelMap[
                            this.props.selectedAnnotationLabel
                          ]
                            ? true
                            : false
                        }
                        htmlFor="age-native-simple"
                      >
                        Annotation Key
                      </InputLabel>
                      <Select
                        name="annotationValue"
                        value={this.props.selectedAnnotationKey}
                        onChange={this.props.handleKeyChange('valueName')}
                        placeholder={'Type'}
                      >
                        <MenuItem value={''} key={0.21}>
                          None
                        </MenuItem>
                        {this.props.annotationLabelMap[
                          this.props.selectedAnnotationLabel
                        ] &&
                          this.props.annotationLabelMap[
                            this.props.selectedAnnotationLabel
                          ].map((el, index) => {
                            return (
                              <MenuItem value={el} key={index}>
                                {el}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  )}
              </div>
              <div className="annotation-select--value">
                {this.props.annotationLabelKeyMap &&
                  this.props.annotationLabelKeyMap[
                    this.props.selectedAnnotationKey
                  ] &&
                  this.props.annotationLabelKeyMap[
                    this.props.selectedAnnotationKey
                  ].length > 0 && (
                    <FormControl className="w-100">
                      <InputLabel
                        shrink={
                          this.props.annotationLabelKeyMap[
                            this.props.selectedAnnotationKey
                          ]
                            ? true
                            : false
                        }
                        htmlFor="age-native-simple"
                      >
                        Annotation Value
                      </InputLabel>
                      <Select
                        name="annotationValue"
                        value={this.props.selectedAnnotationValue}
                        onChange={this.props.handleValueChange('valueName')}
                        placeholder={'Type'}
                      >
                        <MenuItem value={''} key={0.23}>
                          None
                        </MenuItem>
                        {this.props.annotationLabelKeyMap[
                          this.props.selectedAnnotationKey
                        ] &&
                          this.props.annotationLabelKeyMap[
                            this.props.selectedAnnotationKey
                          ].map((el, index) => {
                            return (
                              <MenuItem value={el} key={index}>
                                {el}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  )}
              </div>
            </div>
            {
              <div>
                {
                  <Button
                    onClick={() =>
                      this.props.filterOnAnnotations(
                        this.state.tree,
                        this.state.config
                      )
                    }
                    className="ml-2"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Apply
                  </Button>
                }
                <Button
                  onClick={() => this.props.onReset(true)}
                  className="ml-2"
                  type="button"
                  variant="outlined"
                  color="primary"
                >
                  Reset
                </Button>
              </div>
            }
          </div>
        </div>
      </div>
      <Divider />
      {this.renderItem()}
    </div>
  );

  renderItem = () => {
    return (
      <div className="p-3 pb-5">
        <Grid container spacing={2}>
          {this.props.loading && !this.props.paginationLoading && (
            <CircularProgress
              style={{ position: 'absolute', bottom: '40%', right: '45%' }}
            />
          )}
          {/*this.props.annotationLabels.length*/}
          { this.props.products.length ? (
            this.props.products.map((product, index) => {
              const metadata = this.props.metadata.fieldsByDataType;
              const name =
                metadata &&
                ((metadata.displayTextField &&
                  metadata.displayTextField.length &&
                  getValueByPath(product, metadata.displayTextField[0])) ||
                  (metadata.fts &&
                    metadata.fts.length &&
                    getValueByPath(product, metadata.fts[0])) ||
                  '');
              const displayImageField =
                (metadata &&
                  metadata.displayImageField &&
                  metadata.displayImageField.length &&
                  getValueByPath(product, metadata.displayImageField[0])) ||
                null;
              const dHash =
                metadata &&
                metadata.image &&
                metadata.image.length &&
                product[metadata.image[0]] &&
                product[metadata.image[0]][0] &&
                product[metadata.image[0]][0].fingerprint;
              return (
                <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
                  <ProductCardCompact
                    id={product.id}
                    dbId={product.databaseId}
                    name={name}
                    type={product.type}
                    // price={product.price}
                    description={product.description}
                    // sale={product.sale}
                    discounted={product.discounted}
                    discount={product.discount}
                    // rating={product.rating}
                    thumbnail={product.itemPic}
                    displayImageField={displayImageField}
                    uploadedTime={
                      product && product._meta && product._meta.uploadedTime
                    }
                    databaseName={this.props.databaseValue}
                    onDeleteItem={this.props.showAlertForDelete}
                    // url={product.urls[0]}
                    dHash={dHash}
                    threedots={
                      metadata &&
                      metadata.image &&
                      metadata.image.length &&
                      metadata.image[0] === 'image' &&
                      true
                    }
                    onClickSimilar={this.props.onClickSimilar}
                  />
                </Grid>
              );
            })
          ) : (
            <></>
          )}
          {this.props.paginationLoading && this.props.products.length > 0 && (
            <Grid container>
              <Grid item xs={12}>
                <h5 className="text-center">Loading...</h5>
              </Grid>
            </Grid>
          )}
          {/*this.props.annotationLabels.length == 0 ||*/}
          {(
            this.props.products.length === 0) &&
            !this.props.loading && (
              <Grid className="dflex h-100 mt-5" container>
                <Grid className="align-self-center" item xs={12}>
                  <h2 className="text-center text-muted">
                    No Data Found For This Query Condition
                  </h2>
                </Grid>
              </Grid>
            )}
        </Grid>
      </div>
    );
  };

  renderBuilder = props => {
    return (
      <div className="query-builder-container" style={{ padding: '10px' }}>
        <div className="query-builder qb-lite">
          <Builder {...props} />
        </div>
      </div>
    );
  };

  renderResult = ({ tree: immutableTree, config }) => {
    return (
      <div className="query-builder-result">
        {this.props.enableQueryBuilder && (
          <div>
            <div className="annotation-query-builder-disabled-component">
              <div className="annotation-query-builder-disabled-component-text">
                {this.getCurrentQueryValue(this.state.tree, this.state.config)}
              </div>
              <div>
                <IconButton
                  onClick={() =>
                    this.props.onEdit(
                      this.getCurrentQueryValue(
                        this.state.tree,
                        this.state.config
                      )
                    )
                  }
                  aria-label="edit"
                >
                  {this.props.enableQueryBuilder ? (
                    <CreateIcon />
                  ) : (
                    <BorderColorIcon />
                  )}
                </IconButton>
              </div>
            </div>
            <div className="annotation-label">Query Editor</div>
          </div>
        )}
        {!this.props.enableQueryBuilder && (
          <div>
            <div className="annotation-query-input-container">
              <input
                className="annotation-query-input"
                type="text"
                value={this.props.queryString}
                onChange={event => this.props.onChange(event)}
              />
              <IconButton onClick={() => this.props.onEdit()} aria-label="edit">
                {this.props.enableQueryBuilder ? (
                  <CreateIcon />
                ) : (
                  <BorderColorIcon />
                )}
              </IconButton>
              {/* <FormControl className="w-100">
            <Textfield
              onChange={() => console.log('On change called')}
              //return this.props.queryString;
              name="queryField"
              label="Query Editor"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => this.props.onEdit()} aria-label="edit">
                      {this.props.enableQueryBuilder ? <CreateIcon /> : <BorderColorIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              ></Textfield>
            </FormControl> */}
              {/* <Textfield
              // id="queryEditor"
              // disabled={this.props.enableQueryBuilder}
              //onChange={this.props.setQuery(this.getCurrentQueryValue(immutableTree, config))}
              //onChange = {this.props.onChange}
              //value={this.props.queryString}
              defaultValue={this.props.queryString}
              onChange={this.props.onChange}
              //initialValue={ this.props.enableQueryBuilder && this.state.iv ? '' : this.getCurrentQueryValue(immutableTree, config)}
              // initialValue={this.state.iv ? '' : this.getCurrentQueryValue}
              label="Query Editor"
              name="queryField"
            /> */}
              {/* <div>Query string: <pre>{JSON.stringify(QbUtils.queryString(immutableTree, config))}</pre></div>
              <div>MongoDb query: <pre>{JSON.stringify(QbUtils.mongodbFormat(immutableTree, config))}</pre></div> */}
              {/* <div>SQL where: <pre>{JSON.stringify(QbUtils.sqlFormat(immutableTree, config))}</pre></div> */}
              {/* <div>JsonLogic: <pre>{JSON.stringify(QbUtils.jsonLogicFormat(immutableTree, config))}</pre></div> */}
            </div>
            <div className="annotation-label">Query Editor</div>
          </div>
        )}
      </div>
    );
  };

  getCurrentQueryValue = (immutableTree, config) => {
    let returnString = '';
    if (QbUtils.sqlFormat(immutableTree, config)) {
      returnString += QbUtils.sqlFormat(immutableTree, config);
    }
    if (this.props.selectedAnnotationLabel) {
      returnString += `${returnString ? ' and ' : ''}Filtered_On_Type(${
        this.props.selectedAnnotationLabel
      })`;
    }
    if (this.props.selectedAnnotationKey) {
      returnString += `${returnString ? ' and ' : ''}Filtered_On_Key(${
        this.props.selectedAnnotationKey
      })`;
    }
    if (this.props.selectedAnnotationValue) {
      returnString += `${returnString ? ' and ' : ''}Filtered_On_Value(${
        this.props.selectedAnnotationValue
      })`;
    }
    return returnString;
  };

  onChange = (immutableTree, config) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    this.setState({ tree: immutableTree, config: config });

    const jsonTree = QbUtils.getTree(immutableTree);
    // console.log(jsonTree);
    // `jsonTree` can be saved to backend, and later loaded to `queryValue`
  };
}

export default reduxForm({
  form: 'queryBuilderForm',
  enableReinitialize: true,
  // validate
})(AnnotationBuilder);
