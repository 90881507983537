export const UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS';
export const FAILURE = 'FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FAILURE: {
      return {
        ...state,
        error: true
      };
    }
    case UPDATE_DOCUMENTS: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export const updateDocuments = (documentsList, apiKey, databaseId) => {
  return {
    types: [UPDATE_DOCUMENTS, FAILURE],
    promise: (client) => client.post(`updateDocuments?apiKey=${apiKey}&databaseId=${databaseId}`, { data: documentsList })
  };
};
