/* eslint-disable */
export default {
  // This is ENGLISH language specific constant file
  LOGIN: 'Sign In',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  CONFIRM_PASSWORD: 'Confirm Password',
  MODAL_CANCEL: 'Cancel',
  UPLOAD_PHOTO: 'Upload Photo',
  REMOVED_PHOTO: 'Remove Phote',
  DATABASE: 'Database',
  BERRY_OBJECT: 'Berry Object Database',
  AVAILABLE_DATABASE_LIST: 'Available Database List',
  AVAILABLE_SCHEMAS_LIST: 'Available Schemas List',
  CREATE: 'Create',
  VIEW_ALL: 'View All',
  PROCESSING: 'Processing ...',
  POPULATE: 'Populate',
  SELECT_DATABASE: 'Database',
  SELECT_UPLOAD_TYPE: 'Upload option',
  UPLOAD: 'Upload',
  CATEGORY: 'Category',
  CHANNEL: 'Channel',
  SELECT_CATEGORY: 'Select Category',
  SELECT_CHANNEL: 'Select Channel',
  SELECT_TEMPLATE_TYPE: 'Data type',
  VERSION: 'V1.2',
  PRIVACY: 'Privacy',
  POLICY: 'Policy',
  REMOVE_PHOTO: 'Remove photo',
  DOWNLOAD_TEMPLATE: 'Download Template',
  SELECT_FILE: 'Select Files',
};
