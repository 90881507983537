/* eslint-disable */
import React, { Component, useState } from 'react';
import {
  Query,
  Builder,
  BasicConfig,
  Utils as QbUtils,
} from 'react-awesome-query-builder';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { styled, useTheme } from '@mui/material/styles';
// For AntDesign widgets only:
import AntdConfig from 'react-awesome-query-builder/lib/config/antd';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import 'antd/dist/antd.css'; // or import "react-awesome-query-builder/css/antd.less";
// For Material-UI widgets only:
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import EN from '../../localization/EN';
import { reduxForm, change } from 'redux-form';
import { ProductCardCompact } from '..';
import myStyles from './ChatWindow.module.css';
import Divider from '@material-ui/core/Divider';
import Drawer from '@mui/material/Drawer';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import SettingsIcon from '@mui/icons-material/Settings';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SendIcon from '@mui/icons-material/Send';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FilterListIcon from '@mui/icons-material/FilterList';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import cn from 'classnames';
import { Snackbar } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import { TextField, TextareaAutosize } from '@material-ui/core';

import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css'; //optional, for more compact styles
import RecordComponent from './RecordButton';
import PlayAudioButtonComponent from './PlayAudioButton';
import PerspectiveSettingsModal from './perspectiveSettingsModal';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

// Choose your skin (ant/material/vanilla):
const InitialConfig = AntdConfig; // or MaterialConfig or BasicConfig

// You need to provide your own config. See below 'Config format'
// const config = {
//   ...InitialConfig,
//   fields: {
//     qty: {
//         label: 'Qty',
//         type: 'text',
//         // fieldSettings: {
//         //     min: 0,
//         // },
//         // valueSources: ['value'],
//         // preferWidgets: ['number'],
//     }
//   }
// };

// You can load query value from your backend storage (for saving see `Query.onChange()`)

const drawerWidth = 350;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'left',
  color: '#3f51b5',
}));

class ChatScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      snackbarOpen: false,
      snackbarMessage: '',
      errorModalOpen: false,
      errorModalMessage: '',
      isEmbeddingDatabase: false,
      isDatabaseEmbedded: false,
      embeddedDatabase: null,
      isAnswerLoading: false,
      lastEmbeddedTime: null,
      isModelSettingsOpen: false,
      selectedModel: null,
      selectedNewPerspectiveModel: null,
      selectedPerspective: this.props.selectedPerspective,
      showCreateNewPerspectiveInterface: false,
      promptNameField: React.createRef(),
      modelField: React.createRef(),
      promptInputField: React.createRef(),
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (!(this.props.embeddedDatabases && this.props.databaseList.length)) {
      return;
    }
    let lastEmbeddedTime = this.props.embeddedDatabases[
      this.props.databaseNameMap[this.props.databaseValue]
    ];
    lastEmbeddedTime =
      (lastEmbeddedTime && lastEmbeddedTime.lastEmbeddedOn) || null;
    this.setState({
      isDatabaseEmbedded: !!lastEmbeddedTime,
      lastEmbeddedTime: lastEmbeddedTime,
    });
    /* if (this.state.modelField) {
      this.state.modelField.current.value = 'OpenAI';
    } */
  }

  componentDidUpdate(prevProps) {
    /* if () {

    } */
    if (!(this.props.embeddedDatabases && this.props.databaseList.length)) {
      return;
    }
    let lastEmbeddedTime = this.props.embeddedDatabases[
      this.props.databaseNameMap[this.props.databaseValue]
    ];
    let prevLastEmbeddedTime = null;
    if (
      prevProps.embeddedDatabases &&
      prevProps.databaseNameMap &&
      prevProps.databaseValue
    ) {
      prevLastEmbeddedTime =
        prevProps.embeddedDatabases[
          prevProps.databaseNameMap[prevProps.databaseValue]
        ];
    }
    if (prevLastEmbeddedTime == lastEmbeddedTime) {
      return;
    }
    lastEmbeddedTime =
      (lastEmbeddedTime && lastEmbeddedTime.lastEmbeddedOn) || null;
    this.setState({
      isDatabaseEmbedded: !!lastEmbeddedTime,
      lastEmbeddedTime: lastEmbeddedTime,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      (this.state.isEmbeddingDatabase && !nextProps.isEmbeddingDatabase) ||
      !this.state.isDatabaseEmbedded ||
      nextProps.databaseValue !== this.props.databaseValue
    ) {
      if (!(nextProps.databaseNameMap && nextProps.embeddedDatabases)) {
        return;
      }
      let lastEmbeddedTime =
        nextProps.embeddedDatabases[
          nextProps.databaseNameMap[nextProps.databaseValue]
        ];
      lastEmbeddedTime =
        (lastEmbeddedTime && lastEmbeddedTime.lastEmbeddedOn) || null;
      this.setState({
        isDatabaseEmbedded: !!lastEmbeddedTime,
        lastEmbeddedTime: lastEmbeddedTime,
      });
    }
  }

  render = () => {
    const lastEmbeddedOn = this.state.lastEmbeddedTime;
    return (
      <div className="chat-window-container">
        {this.renderDrawer()}
        <form className="w-100" onSubmit={() => {}}>
          {/* <Grid container className="px-4 pb-3 w-100" spacing={2}> */}
          <div className={myStyles['chat-header-container']}>
            <div className={myStyles['chat-header-container__row']}>
              <div className={myStyles['heading']}>Chats</div>
              {/* <div className="database-select">
                <FormControl className="w-100">
                  <InputLabel
                    shrink={this.props.databaseList ? true : false}
                    htmlFor="age-native-simple"
                  >
                    Database
                  </InputLabel>
                  <Select
                    name="database"
                    value={this.props.databaseValue}
                    onChange={this.props.handleChange('databaseName')}
                    placeholder={this.props.databaseValue}
                  >
                    <MenuItem disabled value="">
                      <em>{EN.SELECT_DATABASE}</em>
                    </MenuItem>
                    {this.props.databaseList &&
                      this.props.databaseList.map((el, index) => {
                        return (
                          <MenuItem value={el.name} key={index}>
                            {el.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div> */}
              <div className={myStyles['settings-container']}>
                <SettingsIcon
                  className={myStyles['settings-icon']}
                  onClick={this.handleDrawerOpen}
                ></SettingsIcon>
              </div>
            </div>
            <div className={myStyles['chat-header-container__row--end']}>
              <div className={myStyles['perspective-showcase-container']}>
                {this.props.chatPerspectives ? (
                  <div className={myStyles['perspective-showcase']}>
                    {this.props.chatPerspectives.map(p => (
                      <div
                        className={[
                          myStyles['perspective-showcase__item'],
                          this.props.selectedPerspective &&
                          this.props.selectedPerspective.id == p.id
                            ? myStyles['perspective-showcase__item--selected']
                            : '',
                        ].join(' ')}
                        onClick={() => {
                          this.props.handlePerspectiveSelection(p);
                        }}
                      >
                        <div className={myStyles['title-row']}>
                          <div className={myStyles['title']}>{p.name}</div>
                          {this.props.selectedPerspective &&
                            this.props.selectedPerspective.id == p.id && (
                              <CheckCircleOutlinedIcon
                                className={myStyles['check-icon']}
                              />
                            )}
                        </div>
                        <div className={myStyles['sub-title']}>{p.prompt}</div>
                      </div>
                    ))}
                    <div></div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {/* <Grid item xs={9}></Grid> */}
          {/* <Grid item xs={1} className="settings-container">
            </Grid> */}
        </form>
        <Divider />
        <div className="database-heading-container">
          <div className="database-name">
            {/* <div className="database-heading">
          {this.props.databaseValue}
          </div> */}
            {/* {this.state.embeddedDatabase || 'No database embedded'} */}
            <div
              className={[
                'status',
                this.state.isEmbeddingDatabase ? 'status__amber' : '',
                !lastEmbeddedOn && !this.state.isEmbeddingDatabase
                  ? 'status__red'
                  : '',
              ].join(' ')}
            >
              <FiberManualRecordIcon fontSize="small"></FiberManualRecordIcon>{' '}
              <div className="status-text">
                {this.state.isEmbeddingDatabase ? (
                  'Embedding...'
                ) : !lastEmbeddedOn ? (
                  'Click on Embed Database'
                ) : (
                  <>
                    <span>Ready</span>
                    {/* {this.state.lastEmbeddedTime && (
                    <span className="status__info">
                      {' Last Embedded Time: ' +
                        moment
                          .unix(this.state.lastEmbeddedTime)
                          .format('DD MMM YYYY hh:mm a')}
                    </span>
                  )} */}
                  </>
                )}
              </div>
            </div>
            {lastEmbeddedOn && (
              <div className="database-info">
                {' Last Embedded Time: ' +
                  moment.unix(lastEmbeddedOn).format('DD MMM YYYY hh:mm a')}
              </div>
            )}
          </div>
          <div className="spacer"></div>
          <div className="database-action">
            <Button
              disabled={
                this.state.isEmbeddingDatabase || this.state.isAnswerLoading
              }
              onClick={() => this.chatEmbed()}
              className="ml-2"
              type="submit"
              variant="contained"
              color="primary"
            >
              Embed Database
            </Button>
          </div>
        </div>
        <Divider />
        {this.renderChatScreen()}
        <Divider />
        <div className="chat-input-row">
          {/* <div className="chat-input-label">
          <AddCircleIcon></AddCircleIcon>
        </div> */}
          <div
            className={[
              'chat-input-container',
              !lastEmbeddedOn || this.state.isAnswerLoading
                ? 'chat-input-container__disabled'
                : '',
            ].join(' ')}
          >
            <input
              value={
                !lastEmbeddedOn
                  ? 'Embed a database to start chat'
                  : this.state.isAnswerLoading
                  ? 'Awaiting response...'
                  : this.props.chatString
              }
              disabled={!lastEmbeddedOn || this.state.isAnswerLoading}
              onKeyUp={event => {
                const key = event.key;
                if (key === 'Enter') {
                  event.preventDefault();
                  this.askChatQuestion();
                }
              }}
              onChange={event => this.props.onChange(event)}
            />
          </div>
          <div
            className={[
              'chat-input-action',
              !lastEmbeddedOn || this.state.isAnswerLoading
                ? 'chat-input-action__disabled'
                : '',
            ].join(' ')}
          >
            <RecordComponent
              inputValue={this.props.chatString}
              setInputValue={this.props.onChatString}
            />
            <IconButton
              disabled={!lastEmbeddedOn || this.state.isAnswerLoading}
              className={[
                'chat-input-action-button',
                !lastEmbeddedOn || this.state.isAnswerLoading
                  ? 'chat-input-action-button__disabled'
                  : '',
              ].join(' ')}
              onClick={() => {
                this.askChatQuestion();
              }}
              aria-label="edit"
            >
              <SendIcon></SendIcon>
            </IconButton>
          </div>
        </div>
        <Snackbar
          message={this.state.snackbarMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={2000}
          onClose={() => this.setSnackbarOpen(false)}
          open={this.state.snackbarOpen}
        />
      </div>
    );
  };

  setSnackbarOpen = (setToOpen = false, snackbarMessage = '') => {
    this.setState({
      snackbarMessage: snackbarMessage,
      snackbarOpen: setToOpen,
    });
  };

  handleNewPerspectiveModelChange = newModel => {
    this.setState({ selectedNewPerspectiveModel: newModel });
  };

  resetPerspectiveForm = () => {
    this.setState({
      selectedNewPerspectiveModel: '',
    });
    if (this.state.promptNameField) {
      this.state.promptNameField.current.value = '';
    }
    if (this.state.promptInputField) {
      this.state.promptInputField.current.value = '';
    }
    if (this.state.modelField) {
      this.state.modelField.current.value = '';
    }
  };

  selectedPerspectiveForm = per => {
    if (this.state.promptNameField) {
      this.state.promptNameField.current.value = per.name || '';
    }
    if (this.state.promptInputField) {
      this.state.promptInputField.current.value = per.prompt || '';
    }
    if (this.state.modelField) {
      this.state.modelField.current.value = per.chatModelName || '';
    }
  };

  renderChatScreen = () => {
    return (
      <div className="p-3 pb-5 chat-message-screen-container">
        <div className="chat-message-screen">
          {this.props.messages.map(m =>
            m.from == 'Bot' ? (
              <div className="bot-message-group">
                <div className="bot-message-row">
                  <div className="bot-start">
                    <FiberManualRecordIcon></FiberManualRecordIcon>
                  </div>
                  <div className="bot-message left-in">
                    {m.message}
                    <PlayAudioButtonComponent
                      message={m.message}
                      synth={this.props.synth}
                      voice={this.props.voice}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="user-message-group">
                <div className="user-message-row">
                  <div className="spacer"></div>
                  <div className="user-message">{m.message}</div>
                </div>
              </div>
            )
          )}
          {this.state.isAnswerLoading && (
            <div className="bot-message-group">
              <div className="bot-message-row">
                <div className="bot-start">
                  <FiberManualRecordIcon></FiberManualRecordIcon>
                </div>
                <div className="bot-message left-in">Fetching Answer...</div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  renderErrorModal = () => {
    return (
      <Dialog
        open={this.state.errorModalOpen || false}
        onClose={() => this.setState({ errorModalOpen: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.state.errorModalMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.setState({ errorModalOpen: false })}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderChatModelSettingsModal = () => {
    return (
      <PerspectiveSettingsModal
        setModelSettingsClose={this.setModelSettingsClose}
        handleCreatePerspective={this.handleCreatePerspective}
        handlePerspectiveSelection={this.props.handlePerspectiveSelection}
        selectedPerspectiveForm={this.selectedPerspectiveForm}
        selectedPerspective={this.props.selectedPerspective}
        isModelSettingsOpen={this.state.isModelSettingsOpen}
        chatPerspectives={this.props.chatPerspectives}
        handleNewPerspectiveModelChange={this.handleNewPerspectiveModelChange}
        chatModels={this.props.chatModels}
        chatModel={this.state.chatModel}
        databaseId={this.props.databaseId}
      />
    );
  };

  renderDrawer = () => (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          marginTop: '4rem',
          maxHeight: 'calc(100vh - 4rem)',
        },
      }}
      // ModalProps={{ onBackdropClick: () => this.setState({ open: false }) }}
      // variant="persistent"
      anchor="right"
      open={this.state.open}
      onClose={() => this.setState({ open: false })}
      // onBackdropClick={() => this.setState({ open: false })}
    >
      <DrawerHeader>
        <div className={myStyles['settings-header']}>
          <IconButton onClick={this.handleDrawerClose}>
            <ChevronRightIcon className="drawer-back-icon" />
          </IconButton>
          <Button
            disabled={
              this.state.isEmbeddingDatabase || this.state.isAnswerLoading
            }
            onClick={() => this.handleSelectPerspectiveBtn()}
            className={myStyles['select-perspective-btn']}
            variant="contained"
            color="primary"
          >
            Select Assistant
          </Button>
        </div>
      </DrawerHeader>
      <Divider />
      <form>
        <div className={myStyles['settings-container']}>
          <div
            className={[
              myStyles['settings-container__row'],
              'annotation-query-input-container',
            ].join(' ')}
          >
            <input
              id="Open AI Api Key"
              required
              className="annotation-query-input annotation-query-input__white"
              type="text"
              value={this.props.openAIApiKey}
              label="Open AI Api Key"
              name="open-ai-api-key"
              onChange={this.props.handleOpenAIApiKeyChange}
            />
            <div className="annotation-label annotation-label__abs">
              Open AI Api Key
            </div>
          </div>
          <div
            className={[
              myStyles['settings-container__row'],
              'annotation-query-input-container',
            ].join(' ')}
          >
            <input
              id="k-value"
              required
              className="annotation-query-input annotation-query-input__white"
              value={this.props.kValue}
              label="k"
              type="number"
              name="k-value"
              onChange={this.props.handleKValueChange}
            />
            <div className="annotation-label annotation-label__abs">K</div>
          </div>
          {/* <div className="drawer-container annotation-query-input-container">
          <input
            id="limit"
            required
            className="annotation-query-input annotation-query-input__white"
            value={this.props.limit}
            label="Limit"
            type="number"
            name="limit"
            onChange={this.props.handleLimitChange}
          />
          <div className="annotation-label annotation-label__abs">Limit</div>
        </div> */}
          {this.props?.voices?.length && (
            <div
              className={[
                myStyles['settings-container__row'],
                'annotation-query-input-container',
              ].join(' ')}
            >
              {/* <InputLabel
            htmlFor="age-native-simple"
          >
            Accent
          </InputLabel> */}
              <Select
                className="voice-select"
                fullWidth
                name="database"
                value={this.props.voice}
                onChange={this.props.handleVoiceChange}
                placeholder={this.props.voice.name}
              >
                {/* <MenuItem disabled value="">
              <em>{EN.SELECT_DATABASE}</em>
            </MenuItem> */}
                {this.props.voices &&
                  this.props.voices.map((el, index) => {
                    return (
                      <MenuItem value={el} key={index}>
                        {el.name}
                      </MenuItem>
                    );
                  })}
              </Select>
              <div className="annotation-label annotation-label__abs">
                Accent
              </div>
            </div>
          )}
          <div
            className={[
              myStyles['settings-container__row'],
              'annotation-query-input-container',
            ].join(' ')}
          >
            <Select
              variant="standard"
              name="Select model"
              value={this.props.selectedModel}
              onChange={e => this.props.handleChatModelChange(e.target.value)}
              placeholder={this.props.chatModel}
              style={{ width: '100%' }}
            >
              <MenuItem disabled value="">
                <em>Select a model</em>
              </MenuItem>
              {this.props.chatModels &&
                this.props.chatModels.map((el, index) => {
                  return (
                    <MenuItem value={el.id} key={index}>
                      {el.id + ' (' + el.name + ')'}
                    </MenuItem>
                  );
                })}
            </Select>
            <div className="annotation-label annotation-label__abs">
              Select a Model
            </div>
          </div>
          <div
            className={[
              myStyles['settings-container__row'],
              myStyles['settings-container__row--no-border'],
              // 'annotation-query-input-container',
            ].join(' ')}
          >
            <TextareaAutosize
              rows={4}
              minRows={4}
              maxRows={10}
              aria-label="maximum height"
              placeholder="Enter prompt"
              value={this.props.chatPrompt}
              style={{
                width: '100%',
                maxHeight: '247px',
                overflow: 'auto',
                borderColor: 'lightgray',
                borderRadius: '5px',
              }}
              onChange={e => this.props.handleChatPromptChange(e.target.value)}
              defaultValue=""
            />
          </div>
          <div className="pb-4" />
        </div>
      </form>
      <Divider />
      {this.renderErrorModal()}
      {this.renderChatModelSettingsModal()}
    </Drawer>
  );

  /* componentDidMount() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  componentWillUnmount() {
    this.props.deleteChatSession();
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload = event => {
    event.preventDefault();
    this.props.deleteChatSession();
  }; */

  handleDrawerOpen = () => {
    console.log(this.state.open);
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  setModelSettingsOpen = () => {
    this.setState({ isModelSettingsOpen: true });
  };

  setModelSettingsClose = () => {
    this.setState({ isModelSettingsOpen: false });
  };

  handleCreatePerspective = perspective => {
    this.props.handleCreatePerspective(perspective);
    this.setModelSettingsClose();
  };

  handleSelectPerspectiveBtn = () => {
    this.setModelSettingsOpen();
  };

  chatEmbed = () => {
    this.props.clearChatMessageHistory();
    const apiKey = window.localStorage.getItem('apiKey');
    // console.log("this.props.openAIApiKey : " + this.props.openAIApiKey);
    // console.log("this.props.kValue : " + this.props.kValue);

    if (!(this.props.openAIApiKey && this.props.openAIApiKey.length)) {
      this.handleDrawerOpen();
      this.setSnackbarOpen(true, 'Open AI API key is required');
      return;
    }
    /* if (!(this.props.kValue && this.props.kValue.length)) {
      this.handleDrawerOpen();
      this.setSnackbarOpen(true, 'K value is required');
      return;
    } */

    this.setState({ isEmbeddingDatabase: true });
    // this.props.deleteChatSession();
    this.props
      .chatEmbed(
        apiKey,
        this.props.databaseValue,
        this.props.openAIApiKey,
        this.props.kValue || 3,
        +(this.props.limit || 0)
      )
      .then(async data => {
        console.log('data :', data);
        const status = data.status;
        const res = await data.json();
        console.log('res :', res);
        if (status == 200) {
          this.props.getEmbeddedDatabases();
          this.setState({
            isDatabaseEmbedded: true,
            embeddedDatabase: res.database,
            messageDBId: res.messageDBId,
          });
        } else {
          this.setState({
            errorModalOpen: true,
            errorModalMessage: res.detail || 'Oops something went wrong',
          });
        }
      })
      .catch(err => {
        this.setState({
          errorModalOpen: true,
          errorModalMessage: err.detail || 'Oops something went wrong',
        });
      })
      .finally(() => {
        this.setState({ isEmbeddingDatabase: false });
      });
  };

  getChatMessages = () => {
    this.props.clearChatMessageHistory();
    const apiKey = window.localStorage.getItem('apiKey');
    this.props
      .getChatMessages(apiKey, this.props.databaseValue)
      .then(async data => {
        console.log('data :', data);
        const status = data.status;
        const res = await data.json();
        console.log('res :', res);
        if (status == 200) {
          // this.props.getEmbeddedDatabases();
          // this.setState({
          //   isDatabaseEmbedded: true,
          //   embeddedDatabase: res.database,
          //   messageDBId: res.messageDBId
          // });
        } else {
          // this.setState({
          //   errorModalOpen: true,
          //   errorModalMessage: res.detail || 'Oops something went wrong',
          // });
        }
      })
      .catch(err => {
        this.setState({
          errorModalOpen: true,
          errorModalMessage: err.detail || 'Oops something went wrong',
        });
      })
      .finally(() => {
        this.setState({ isEmbeddingDatabase: false });
      });
  };

  askChatQuestion = () => {
    const apiKey = window.localStorage.getItem('apiKey');

    if (!(this.props.openAIApiKey && this.props.openAIApiKey.length)) {
      this.handleDrawerOpen();
      this.setSnackbarOpen(true, 'Open AI API key is required');
      return;
    }

    const chatString = (this.props.chatString || '') + '';
    const kValue = this.props.kValue;
    if (!chatString) {
      return;
    }
    let messageObj = {
      from: 'User',
      message: chatString,
    };
    this.props.onSendChat(messageObj);
    this.setState({ isAnswerLoading: true });
    this.props
      .askChatQuestion(
        chatString,
        kValue,
        this.props.databaseValue,
        apiKey,
        this.props.openAIApiKey,
        this.props.selectedModel,
        this.props.chatPrompt,
        'gpt-4-turbo',
        0.75
      )
      .then(async data => {
        console.log('data :', data);
        const status = data.status;
        const res = await data.json();
        console.log('res :', res);
        if (status == 200 && res.answer) {
          let messageObj = {
            from: 'Bot',
            message: res.answer,
          };
          this.props.onSendChat(messageObj);
        } else {
          this.setState({
            errorModalOpen: true,
            errorModalMessage: res.detail || 'Oops something went wrong',
          });
        }
      })
      .catch(err => {
        this.setState({
          errorModalOpen: true,
          errorModalMessage: err.detail || 'Oops something went wrong',
        });
      })
      .finally(() => {
        this.setState({ isAnswerLoading: false });
      });
  };
}

export default reduxForm({
  form: 'queryBuilderForm',
  enableReinitialize: true,
  // validate
})(ChatScreen);
