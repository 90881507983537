export default {
  LOGIN_SUCCESS: 'Succesfully Logged In',
  PASSWORD_MISMATCH: "Password and Confirm Password doesn't match!",
  DATABASE1_TAG_01: `The main metadata associated with a BerryDB document (also referred to as a BerryDB record or a BerryDB row) is maintained
  in this BObject collection. There are other collections that maintain associated metadata such as Category, Channel. Users can
  add a new collection of metadata for a BerryDB document although it is rarely necessary.`,
  DATABASE1_TAG_02: `The metadata in a collection is in the form of key-value pairs. A key is made up of a user specified string or a tag and the value
  is a user specified JSON document. The DB automatically assigns a special ID tag that uniquely identifies each record.`,
  DATABASE1_TAG_03: 'The DB also uses a special URI tag to link the metadata to the underlying audio/video/image/text content.',
  DATABASE1_TAG_04: 'Users can extend the schema of a Berry object here by adding application specific tags.',
  DATABASE1_TAG_11: `Categories are used to organize the Berry objects. Users can specify a list of categories here and assign Berry objects to it. A
  Berry object can have more than one Category.`,
  DATABASE1_TAG_12: "There is a special Category called 'Unassigned' which is the default category assigned to any object.",
  DATABASE1_TAG_21: `A Channel collection is used to organize Berry objects. Users can specify a list of Channels here. Think of a Channel as a
  department in a company. A Berry object can only be assigned to one Channel or department.`,
  DATABASE1_TAG_22: "There is a special Channel called 'Unassigned' which is the default Channel assigned to any object.",
  OBJECT_TAG_DESC: 'Object tags - describes the object with links to underlying content',
  ORGANIZATION_TAG_DESC: 'Organization tags - organizes the object',
  METRIC_TAG_DESC: 'Metric tags - metrics for the object',
  POPULATE_DESC: 'Bulk populate the database from Excel OR cloud storage. The format of the Excel file is available in the \'Download Template\' link.',
  NOTEPAD_LINE1: 'Install BerryDB notebook as a docker container and run it locally with the following command:',
  NOTEPAD_LINE2: 'docker run -p 8081:8080 --rm --name bdb bdb1/notebook:latest',
  NOTEPAD_LINE3: 'Once the container is running, connect to the notebook at ',
  NOTEPAD_LINE4: 'Load pre-built example notebooks or create new ones',
  NOTEPAD_HEADING: 'BerryDB notebook examples that shows how to use BerryDB API',
  SETTINGS_LINE1: 'Storage: GCS',
  SETTINGS_LINE2: 'Top level directory: /storage/browser/berrydb',
  SETTINGS_LINE3: 'Organization directory:',
  SETTINGS_LINE4: 'Database Memory configuration: Each bucket',
  SETTINGS_LINE5: 'Similar image hamming distance: 10',
  SETTINGS_HEADING: 'Settings for your account',
  ENTER_USERNAME: 'Enter Username',
  ENTER_PASSWORD: 'Enter Password',
  INVALID_DATA: 'Invalid Id or password',
  SIGNIN_WITH_GOOGLE: 'Sign In with Google',
  SIGNIN_WITH_EMAIL: 'Sign In with your Email and OTP',
  SIGNIN_WITH_BERRYDB: 'Sign In with BerryDB Account',
  CODE_NOTIFY: 'Code is sent to mail',
  SET_PASSWORD_SUCCESS: 'User profile updated',
  EMAIL_ERROR: 'Email ID is required',
};
