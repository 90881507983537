/* eslint-disable */

import superagent from 'superagent';
import { APIConfig } from '../constants';
import IsJsonString from '../helpers/TypeCheckers';
// import { userInfo } from '@utility';

const methods = ['get', 'post', 'put', 'patch', 'del'];
let HOSTNAME = APIConfig.hostname;
const PORTS = APIConfig.ports;
const ENDPOINTS = APIConfig.endpoints;
const BERRY_GPT_HOSTNAME = APIConfig.chatHostname;

function formatUrl(path) {
  //  let HOSTNAME = 'https://staging.vidberrys.com:';
  let apiPathArray = [];
  if (path.indexOf('?') !== -1) {
    apiPathArray = path.split('?');
    path = apiPathArray[0];
    apiPathArray.shift();
  }
  let mappedEndpoint = ENDPOINTS[path];
  if (path.indexOf('/') !== -1) {
    mappedEndpoint = '';
    const splitPathArray = path.split('/');
    mappedEndpoint += ENDPOINTS[splitPathArray[0]] + '/';
    splitPathArray.shift();
    mappedEndpoint += splitPathArray.join('/');
  }

  if (mappedEndpoint.startsWith('before/')) {
    mappedEndpoint = mappedEndpoint.split('before/')[1];
  }

  // debugger
  if (mappedEndpoint.startsWith('profile')) {
    // HOSTNAME = HOSTNAME + PORTS['profile'];
  } else if (mappedEndpoint.startsWith('berrydb')) {
    // HOSTNAME = HOSTNAME + PORTS['berrydb'];
  } else if (mappedEndpoint.startsWith('watchparty')) {
    // HOSTNAME = HOSTNAME + PORTS['watchparty'];
  } else if (mappedEndpoint.startsWith('label-studio')) {
    mappedEndpoint = mappedEndpoint.replace('label-studio', '');
    HOSTNAME = HOSTNAME + ':' + PORTS['label-studio'];
  } else if (mappedEndpoint.startsWith('berryGpt')) {
    mappedEndpoint = mappedEndpoint.replace('berryGpt', '');
    HOSTNAME = BERRY_GPT_HOSTNAME;
  }

  // else if (mappedEndpoint.startsWith('logger')) {
  //   console.log('object333', mappedEndpoint);
  //   HOSTNAME = HOSTNAME + PORTS['logger'];
  // } else if (mappedEndpoint.startsWith('streamOverlay')) {
  //   console.log('object444', mappedEndpoint);
  //   HOSTNAME = HOSTNAME + PORTS['ffmpeg'];
  // }

  let adjustedPath =
    mappedEndpoint[0] !== '/'
      ? HOSTNAME + '/' + mappedEndpoint
      : HOSTNAME + mappedEndpoint;
  if (apiPathArray) adjustedPath = adjustedPath + '?' + apiPathArray;

  return adjustedPath;

  // const adjustedPath = mappedEndpoint[0] !== '/' ? HOSTNAME + '/' + mappedEndpoint : HOSTNAME + mappedEndpoint;
  // return adjustedPath;
}

export default class ApiClient {
  constructor(req) {
    methods.forEach(method => {
      this[method] = (
        path,
        { params, data, headers, files, fields, setCookie } = {}
      ) =>
        new Promise((resolve, reject) => {
          let request = superagent[method](formatUrl(path));
          /* if (setCookie) {
          request.set('Access-Control-Allow-Origin', '*');
          // .set('Access-Control-Allow-Credentials', true)
        } */
          // console.log("request is ", request);
          if (path.indexOf('fakeapi') !== -1) {
            let fakePath = path;
            let splitPathArray = fakePath.split('/');
            splitPathArray.shift();
            let constructedURL = splitPathArray.join('/');
            request = superagent[method](
              `http://localhost:3004/${constructedURL}`
            ).withCredentials();
          }
          if (
            path.indexOf('createDatabase') == -1 &&
            path.indexOf('uploadFileData') == -1 &&
            path.indexOf('updateThumbnail') == -1
          ) {
            request.set({ 'Content-Type': 'application/json;charset=UTF-8' });
          }

          // request.set({ 'Content-Type': 'application/json;charset=UTF-8' });

          if (params) {
            request.query(params);
          }

          if (headers) {
            request.set(headers);
            // request.set({'Cookie': document.cookie});
          }

          // if (userInfo.isUserLogin()) {
          //   request.set('Authorization', `Bearer ${userInfo.getToken()}`);
          // }

          if (files) {
            files.forEach(file => request.attach(file.key, file.value));
          }

          if (fields) {
            fields.forEach(item => request.field(item.key, item.value));
          }

          const requrl = request.url.split('?');
          if (requrl[0].indexOf('download') != -1) {
            request.responseType('arraybuffer');
          }

          if (data) {
            // request.set(headers);
            request.send(data);
          }

          // request.end((err, { body } = {}) => (err ? reject(body || err) : resolve(body)));
          request.end(async (err, response = {}) => {
            if (err) {
              if (response && response.text != '') {
                // debugger
                if (
                  request.url.indexOf('/streamOverlay') > -1 ||
                  request.url.indexOf('/profile/user/set/password') > -1
                ) {
                  if (
                    IsJsonString(
                      decodeURIComponent(response.text.replace(/\+/g, '%20'))
                    )
                  ) {
                    reject(
                      JSON.parse(
                        decodeURIComponent(response.text.replace(/\+/g, '%20'))
                      )
                    );
                  } else {
                    reject(
                      decodeURIComponent(response.text.replace(/\+/g, '%20'))
                    );
                  }
                } else {
                  reject(err);
                }
              } else {
                reject(err);
              }
            } else if (request.url.indexOf('download') != -1) {
              resolve(response);
            } else {
              if (setCookie) {
                const cookie = response.headers['set-cookie'];
                response['cookie'] = cookie;
              }
              if (
                request.url.indexOf('/profile/user/social') > -1 ||
                request.url.indexOf('/profile/user/login') > -1
              ) {
                let isOtp = true;
                let base64password = '';
                if (request.url.indexOf('profile/user/login') > -1) {
                  isOtp = request.url.split('?isOtp=')[1];
                } else if (request.url.indexOf('/profile/user/social') > -1) {
                  isOtp = false;
                }
                if (!isOtp || isOtp === false || isOtp === 'false') {
                  base64password = request._data.otp
                    ? Buffer.from(request._data.otp).toString('base64')
                    : '';
                }
                // let access_token = await fetchBasicAuthToken(request._data.email, base64password);
                if (IsJsonString(response.text)) {
                  let toBeModified = {};
                  toBeModified.userData = JSON.parse(response.text);
                  // toBeModified.access_token =  access_token;
                  toBeModified.status = 200;
                  resolve(toBeModified);
                } else {
                  console.log('login', response.text, access_token);
                }
              } else {
                if (IsJsonString(response.text)) {
                  resolve(JSON.parse(response.text));
                } else {
                  resolve(response.text);
                }
              }
            }
          });
        });
    });
  }
  empty() {}
}
