/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import SampleJson from '../../samples/simple.json';
// import { Path, EditMode, EditType } from '../../types';
import { getTypeByPath } from '../../helpers/utils';
import { isArray, isDate } from '../../utils/is';
/**
 * State of dataSlice
 * @param data the data.
 * @param editPath the path of editable. `null` means no editable data.
 */

const getParentPathAndName = path => {
  let parentPath = [];
  let name = '';
  if (path.length === 1) {
    name = path[0];
  } else if (path.length > 1) {
    parentPath = _.initial(path);
    name = _.last(path);
  } else {
  }
  return [parentPath, name];
};

const schemaDataTypeFormat = (state, mainObject) => {
  state.invalidSchemaKey = null;
  return getJsonPathsWithTypes(state, mainObject || {});
};

const getDataType = value => {
  if (isArray(value)) {
    return 'array';
  }
  if (isDate(value)) {
    return 'date';
  }
  return typeof value;
};

const getJsonPathsWithTypes = (state, obj, parentPath = '') => {
  const pathsWithTypes = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // if (Object.prototype.hasOwnProperty.call(obj, key)) {
      console.log('--- key: ', key);
      const regex = /^[A-Za-z][A-Za-z0-9]*$/;
      if (!(regex.test(key) && key !== '' && key !== 'fts')) {
        console.log('Key found with invalid characters: ', key);
        state.invalidSchemaKey = key;
      }
      const value = obj[key];
      let currentPath = parentPath ? `${parentPath}.${key}` : key;
      const dataType = getDataType(value);

      if (dataType === 'object') {
        pathsWithTypes[currentPath] = dataType;
        const nestedPaths = getJsonPathsWithTypes(state, value, currentPath);
        Object.assign(pathsWithTypes, nestedPaths);
      } else if (dataType === 'array' && value.length) {
        if (value.length !== 1) {
          state.invalidSchemaKey = key;
        }
        pathsWithTypes[currentPath] = dataType;
        const arrayPath = `${currentPath}[0]`;
        const arrayDataType = getDataType(value[0]);
        pathsWithTypes[arrayPath] = arrayDataType;
        if (arrayDataType === 'object') {
          const nestedPaths = getJsonPathsWithTypes(state, value[0], arrayPath);
          Object.assign(pathsWithTypes, nestedPaths);
        }
      } else {
        pathsWithTypes[currentPath] = dataType;
      }
    }
  }

  return pathsWithTypes;
};

const initialState = {
  data: null,
  editMode: null,
  filter: [],
  ftsfilter: [],
  newSchemaDataTypes: null,
  newDataTypeKey: {},
  filterUpdate: [],
  invalidSchemaKey: null,
};

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setSaveAsPlainJson: (state, action) => {
      state.saveAsPlainJson = action.payload;
    },
    setSaveDocumentHandler: (state, action) => {
      state.saveDocumentHandler = action.payload;
    },
    setAlterSchemaDataTypes: (state, action) => {
      if (action.payload.hasOwnProperty('newSchemaDataTypes')) {
        // console.log("newSchemaDataTypes")
        // console.log(action.payload.newSchemaDataTypes)

        const initialSchemaDataType = action?.payload?.newSchemaDataTypes
          ? Object.entries(action?.payload?.newSchemaDataTypes)
          : [];
        const schemaDataType = initialSchemaDataType?.filter(function([
          key,
          value,
        ]) {
          return (
            value === 'image' ||
            value === 'text' ||
            value === 'audio' ||
            value === 'video' ||
            value === 'metric'
          );
        });
        state.newDataTypeKey = Object.fromEntries(schemaDataType);
      }
      if (action.payload.hasOwnProperty('key')) {
        const key = action.payload.key.replaceAll(',', '.');
        const value = action.payload.type;
        console.log('***');
        console.log('value', value);
        console.log('***');

        state.newDataTypeKey = { ...state.newDataTypeKey, ...{ [key]: value } };
      }
    },

    setUpdateFilters: (state, action) => {
      const deleteFilter = action.payload[0];
      console.log('deleteFilter -', deleteFilter);
      if (deleteFilter) {
        const findex =
          state.filter &&
          state.filter.length &&
          state.filter.indexOf(deleteFilter);
        if (findex >= 0) {
          state.filter = [
            ...state.filter.slice(0, findex),
            ...state.filter.slice(findex + 1),
          ];
        }
        const ftsindex =
          state.ftsfilter &&
          state.ftsfilter.length &&
          state.ftsfilter.indexOf(deleteFilter);
        if (ftsindex >= 0) {
          state.ftsfilter = [
            ...state.ftsfilter.slice(0, ftsindex),
            ...state.ftsfilter.slice(ftsindex + 1),
          ];
        }
      }

      if (!(state.filter && state.filterUpdate && state.filterUpdate.length)) {
        return;
      }

      const findex = state.filter.indexOf(state.filterUpdate[0]);
      if (findex >= 0) {
        state.filter = Object.assign([], state.filter, {
          [findex]: state.filterUpdate[1],
        });
      }
      const ftsindex = state.ftsfilter.indexOf(state.filterUpdate[0]);
      if (ftsindex >= 0) {
        state.ftsfilter = Object.assign([], state.ftsfilter, {
          [ftsindex]: state.filterUpdate[1],
        });
      }
    },

    setNewSchemaDataTypes: (state, action) => {
      var mainObject = action.payload.data;
      if (mainObject) {
        if (action.payload.hasOwnProperty('newSchemaDataTypes')) {
          const newSchema = schemaDataTypeFormat(state, mainObject);
          state.newSchemaDataTypes = { ...newSchema };
          // console.log(state.newSchemaDataTypes)
        }
        if (Object.keys(state.newDataTypeKey).length > 0) {
          Object.keys(state.newDataTypeKey).forEach(key => {
            if (Object.keys(mainObject).includes(key)) {
              state.newSchemaDataTypes = {
                ...state.newSchemaDataTypes,
                ...{ [key]: state.newDataTypeKey[key] },
              };
            }
          });
        }

        state.filter &&
          state.filter.forEach(key => {
            const filterType = getTypeByPath(mainObject, key);
            const removeFromFilter = filterType
              ? !(
                  filterType == 'string' ||
                  filterType == 'number' ||
                  filterType == 'boolean'
                )
              : false;
            if (!filterType || removeFromFilter) {
              const index = state.filter.indexOf(key);
              state.filter = [
                ...state.filter.slice(0, index),
                ...state.filter.slice(index + 1),
              ];
            }
          });

        state.ftsfilter &&
          state.ftsfilter.forEach(key => {
            const ftsType = getTypeByPath(mainObject, key);
            const removeFromFTS = ftsType ? ftsType !== 'string' : false;
            if (!ftsType || removeFromFTS) {
              const index = state.ftsfilter.indexOf(key);
              state.ftsfilter = [
                ...state.ftsfilter.slice(0, index),
                ...state.ftsfilter.slice(index + 1),
              ];
            }
          });

        if (state.displayTextField) {
          const displayTextType = getTypeByPath(
            mainObject,
            state.displayTextField
          );
          const removeDisplayTextType = displayTextType
            ? displayTextType !== 'string'
            : false;
          if (!displayTextType || removeDisplayTextType) {
            state.displayTextField = null;
          }
        }

        if (state.displayImageField) {
          const displayImageType = getTypeByPath(
            mainObject,
            state.displayTextField
          );
          const removeDisplayImageType = displayImageType
            ? displayImageType !== 'string'
            : false;
          if (!displayImageType || removeDisplayImageType) {
            state.displayImageField = null;
          }
        }

        // console.log('keystate.newDataTypeKey')
        // console.log(state.newDataTypeKey)

        console.log('Final NewSchemaDataTypes :');
        console.log(state.newSchemaDataTypes);

        console.log('filter :');
        console.log(state.filter);

        console.log('ftsfilter :');
        console.log(state.ftsfilter);

        console.log(`JSON schema key ${state.invalidSchemaKey} is not valid`);
      }
    },

    setDisplayTextField: (state, action) => {
      state.displayTextField = action.payload.displayTextField
        ? action.payload.displayTextField
        : null;
    },

    setDisplayImageField: (state, action) => {
      state.displayImageField = action.payload.displayImageField
        ? action.payload.displayImageField
        : null;
    },

    setInitialFilter: (state, action) => {
      // debugger
      state.filter = action.payload.filterValues || [];
      state.ftsfilter = action.payload.ftsFilterValues || [];
    },

    setFilter: (state, action) => {
      if (!state.filter) {
        return;
      }
      const receivedKey = action.payload.path.key.replaceAll(',', '.');
      if (
        action.payload.path.filtertoggle === 'On' &&
        !state.filter.includes(receivedKey)
      )
        state.filter = state.filter.concat(receivedKey);
      if (action.payload.path.filtertoggle === 'Off') {
        const index = state.filter.indexOf(receivedKey);
        if (index >= 0) {
          state.filter = [
            ...state.filter.slice(0, index),
            ...state.filter.slice(index + 1),
          ];
        }
      }
      console.log('filter : ', state.filter);
    },
    setFTSFilter: (state, action) => {
      if (!state.ftsfilter) {
        return;
      }
      const receivedKey = action.payload.path.key.replaceAll(',', '.');
      if (
        action.payload.path.ftstoggle === 'On' &&
        !state.ftsfilter.includes(receivedKey)
      )
        state.ftsfilter = state.ftsfilter.concat(receivedKey);
      if (action.payload.path.ftstoggle === 'Off') {
        const index = state.ftsfilter.indexOf(receivedKey);
        if (index >= 0) {
          state.ftsfilter = [
            ...state.ftsfilter.slice(0, index),
            ...state.ftsfilter.slice(index + 1),
          ];
        }
      }
      console.log('ftsfilter : ', state.ftsfilter);
    },
    pasteSample: state => {
      state.data = SampleJson;
    },
    deletePath: (state, action) => {
      const path = action.payload;
      const [parentPath, name] = getParentPathAndName(path);
      const data = state.data;
      if (parentPath.length === 0) {
        // the traget is root object
        if (isArray(data)) {
          // Array
          state.data = data.filter((_, index) => `${index}` !== name);
        } else {
          // Object
          _.unset(state.data, path);
        }
      } else {
        // the traget is not root object
        const targetData = _.get(data, parentPath);
        if (isArray(targetData)) {
          // Array
          _.set(
            state.data,
            parentPath,
            targetData.filter((_, index) => index !== parseInt(name))
          );
        } else {
          // Object
          _.unset(state.data, path);
        }
      }
    },
    updateDataOfPath: (state, action) => {
      const { data, path } = action.payload;
      _.set(state.data, path, data);
    },
    updateKeyOfPath: (state, action) => {
      const { key: newKey, path } = action.payload;
      console.log('11111', action.payload, newKey, path);
      state.filterUpdate = [path[0], newKey];
      // get
      const [parentPath, name] = getParentPathAndName(path);
      console.log('222', [parentPath, name]);
      const targetData =
        parentPath.length === 0 ? state.data : _.get(state.data, parentPath);
      console.log('333', targetData);
      const newObject = {};
      _.toPairs(targetData).forEach(([key, value]) => {
        if (key === name) {
          newObject[newKey] = value;
          console.log('444', newObject[newKey]);
        } else {
          newObject[key] = value;
          console.log('555', newObject[key]);
        }
      });
      // set
      if (parentPath.length > 0) {
        console.log('666', state.data, parentPath, newObject);
        _.set(state.data, parentPath, newObject);
      } else {
        state.data = newObject;
        // setData(newObject);
        console.log('777', newObject);
        console.log('888', state.data);
      }
    },
    setEditMode: (state, action) => {
      state.editMode = action.payload;
    },
    insertDataAfterPath: (state, action) => {
      const [parentPath, name] = getParentPathAndName(action.payload);
      // get
      const newData =
        parentPath.length === 0 ? state.data : _.get(state.data, parentPath);
      // insert
      if (isArray(newData)) {
        const newName = name === '' ? 0 : parseInt(name) + 1;
        newData.splice(newName, 0, null);
      }
      // set
      _.set(state.data, parentPath, newData);
    },
    duplicatePath: (state, action) => {
      const [parentPath, name] = getParentPathAndName(action.payload);
      // get
      const newData =
        parentPath.length === 0 ? state.data : _.get(state.data, parentPath);
      // insert
      if (isArray(newData)) {
        // array
        if (name === '') {
          newData.splice(0, 0, newData[0]);
        } else {
          const newName = parseInt(name) + 1;
          newData.splice(newName, 0, newData[name]);
        }
        // set
        _.set(state.data, parentPath, newData);
      } else {
        // object
        const newObject = {};
        const newKey = '';
        const keys = Object.keys(newData);
        // if insert before the first element
        if (name === '') {
          newObject[newKey] = keys.length > 0 ? newData[keys[0]] : null;
        }
        keys.forEach(key => {
          newObject[key] = newData[key];
          if (key === name) {
            newObject[newKey] = newData[key];
          }
        });
        // set
        if (parentPath.length === 0) {
          state.data = newObject;
        } else {
          _.set(state.data, parentPath, newObject);
        }
        // set edit mode
        state.editMode = {
          path: [...parentPath, newKey],
          type: 0,
        };
      }
    },
  },
});
