/* eslint-disable */
export const BACKUP_RESTORE = 'BACKUP_RESTORE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BACKUP_RESTORE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: false,
      };
    }
    default:
      return state;
  }
};

export const getBackupList = userSurrogateId => {
  return {
    types: [BACKUP_RESTORE, BACKUP_RESTORE, BACKUP_RESTORE],
    promise: client =>
      client.get(`backupRestore?userSurrogateId=${userSurrogateId}`),
  };
};

export const createBackup = data => {
  return {
    types: [BACKUP_RESTORE, BACKUP_RESTORE, BACKUP_RESTORE],
    promise: client =>
      client.post(`backupRestore`, {
        data,
      }),
  };
};

export const restoreBackup = backupId => {
  return {
    types: [BACKUP_RESTORE, BACKUP_RESTORE, BACKUP_RESTORE],
    promise: client => client.post(`backupRestore/${backupId}/restore`),
  };
};
