/* eslint-disable */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import HeaderComponent from './component';
import {
  globalDbSearch,
  setDbName,
  setSearchKey,
  setDbId,
  setSchemaId,
} from '../../reducers/search';
import { getDatabaseList } from '../../reducers/database';
import { CLIENT_RENEG_LIMIT } from 'tls';
import { Context } from '../AppProvider/AppProvider';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchData: [],
      databaseList: [],
      name: '',
      dbId: '',
      schemaId: '',
      isDropDownModelOpened: false,
      searchKey: '',
      isCalled: true,
      userName: '',
      userEmail: '',
      orgName: '',
      isFetchingGlobalSearchResults: false,
      // executeSearch: true
    };
  }

  componentDidMount() {
    const userId = window.localStorage.getItem('userSurrogateId');
    const pathname = window.location.pathname;
    if (pathname !== '/') {
      this.getDatabaseList(userId);
    }
  }

  getDatabaseList = userId => {
    if (userId !== null) {
      this.props.getDatabaseList(userId, 0, 0);
    }
    // .then((results) => {
    //   this.setState({ databaseList: results.responseList });
    // });
  };

  componentDidUpdate(prevProps, nextProps) {
    if (window.localStorage.getItem('userSurrogateId') && this.state.isCalled) {
      this.getDatabaseList(window.localStorage.getItem('userSurrogateId'));
      this.setState({
        userName: window.localStorage.getItem('username'),
        userEmail: window.localStorage.getItem('userEmail'),
        orgName: window.localStorage.getItem('orgName'),
        isCalled: false,
      });
    }
    if (
      this.state.searchKey &&
      window.location.pathname !== '/search' &&
      this.props.searchTerm &&
      window.localStorage.getItem('filterPage')
    ) {
      document.getElementById('search').value = '';
      window.localStorage.removeItem('filterPage');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.databaseList !== this.props.databaseList) {
      this.setState({ databaseList: nextProps.databaseList });
    }
  }

  setDbName = dbName => {
    let dbId = '';
    this.state.databaseList &&
      this.state.databaseList.map(results => {
        if (results.name === dbName) {
          dbId = results.id;
          return this.setState({
            dbId: results.id,
            schemaId: results.schemaId,
          });
        }
      });
    this.setState({ name: dbName });
    window.localStorage.setItem('globalDBName', dbName);
    window.localStorage.setItem('globalDBId', dbId);
  };

  globalSearch = value => {
    // If there is no search term, do not make API call
    // if (!inputRef.current.value.trim()) {
    //   setAnimals([])
    //   return
    // }
    let promise = null;
    this.setState(
      { searchKey: value, isFetchingGlobalSearchResults: true },
      () => {
        if (this.state.searchKey !== '') {
          promise = this.props
            .globalDbSearch(
              window.localStorage.getItem('orgName'),
              this.state.dbId,
              this.state.searchKey
            )
            .then(async response => {
              {
                const data = await response.items;
                this.setState({ searchData: data });
              }
            })
            .catch(err => {
              console.error(err);
            })
            .finally(() => {
              this.setState({ isFetchingGlobalSearchResults: false });
            });
          this.setState({ isDropDownModelOpened: true });
        } else {
          this.setState({
            searchData: [],
            isDropDownModelOpened: false,
            isFetchingGlobalSearchResults: false,
          });
        }
      }
    );
    return () => {
      if (promise) {
        promise.abort();
      }
    };
  };

  onEnterkeyPress = e => {
    if (e.key === 'Enter' && this.state.searchKey !== '') {
      this.props.setDbName(this.state.name);
      this.props.setSearchKey(this.state.searchKey);
      this.props.setDbId(this.state.dbId);
      this.props.setSchemaId(this.state.schemaId);
      this.handleClose();
      this.props.history.push({
        pathname: `/search`,
        state: { keySearch: this.state.searchKey },
      });
    }
  };

  redirectTo = (name, id) => {
    this.props.history.push(`/detail/${name}/${id}`);
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ isDropDownModelOpened: false });
  };

  render() {
    return (
      <HeaderComponent
        logoAltText={this.props.logoAltText}
        logo={this.props.logo}
        toggleDrawer={this.props.toggleDrawer}
        globalSearch={this.globalSearch}
        searchData={this.state.searchData}
        availableDatabaseList={this.state.databaseList}
        setDbName={this.setDbName}
        redirectTo={this.redirectTo}
        databaseName={this.state.name}
        dbId={this.state.dbId}
        handleClose={this.handleClose}
        isDropDownModelOpened={this.state.isDropDownModelOpened}
        searchKey={this.state.searchKey}
        loading={this.props.loading}
        currentUrl={this.props.currentUrl}
        onEnterkeyPress={this.onEnterkeyPress}
        userName={this.state.userName}
        userEmail={this.state.userEmail}
        orgName={this.state.orgName}
        isFetchingGlobalSearchResults={this.state.isFetchingGlobalSearchResults}
      />
    );
  }
}

const mapToProps = state => {
  return {
    loading: state.search.loading,
    databaseList: state.database.databaseList.responseList,
    searchTerm: state.search.searchKey,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      globalDbSearch,
      getDatabaseList,
      setSearchKey,
      setDbName,
      setDbId,
      setSchemaId,
    },
    dispatch
  );

export default withRouter(connect(mapToProps, mapDispatchToProps)(Header));
