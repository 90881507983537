/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Collapse from '@material-ui/core/Collapse';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Person from '@material-ui/icons/Person';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import styles from './Header.module.scss';
import cn from 'classnames';
import Select from '@material-ui/core/Select';
import Box from '@mui/material/Box';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import EN from '../../localization/EN';
import { Field, reduxForm } from 'redux-form';
import { useTheme } from '@mui/material/styles';
// import { Browser } from 'leaflet';
import { labelStudioLogout } from '../../reducers/otp';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getValueByPath } from '../../helpers/utils';
import { debounce } from '@mui/material';
import { useAppState } from '../AppProvider/AppProvider';
import { Backdrop } from '@material-ui/core';
import { usePostHog } from 'posthog-js/react';
import logMessages from '../../constants/logMessages';

const useStyles = makeStyles(theme => ({
  appBar: {
    boxShadow: '0 1px 8px rgba(0,0,0,.3)',
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 100,
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
    },
  },
  toolBar: {
    paddingLeft: theme.spacing(1) / 2,
    paddingRight: theme.spacing(1) / 2,
  },
  branding: {
    display: 'flex',
    // overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    margin: 'auto 0',
    lineHeight: '50px',
    padding: '0 35px 0 0',
  },
  logo: {
    margin: 'auto',
    marginTop: '-15px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80px',
    },
  },
  searchWrapper: {
    flex: '1 1 0%',
    display: 'flex',
    boxSizing: ' border-box',
    position: 'relative',
  },
  selectBox: {
    flex: '0.5',
    background: 'white',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1) * 2,
    display: 'flex',
    alignItems: 'center',
    maxWidth: '265px',
  },
  searchForm: {
    flex: '1.5',
    background: 'white',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1) * 2,
    display: 'block',
    maxWidth: '800px',
  },
  searchInput: {
    fontSize: '1rem',
    padding: theme.spacing(1) * 1.9,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1) * 1.2,
    },
    cursor: 'text',
    textIndent: '30px',
    border: 'none',
    background: 'transparent',
    width: '100%',
    outline: '0',
    color: 'black',
  },
  searchIcon: {
    position: 'absolute',
    top: '50%',
    left: '0',
    marginTop: '-24px',
    color: 'rgba(0,0,0,.87)',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      top: 20,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const HeaderComponent = props => {
  const classes = useStyles();
  const posthog = usePostHog();
  const [state, dispatch] = useAppState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchExpanded, setSearchExpanded] = useState(false);
  const handleSettingdToggle = event => setAnchorEl(event.currentTarget);
  const theme = useTheme();
  const myRef = useRef();
  const databaseList =
    props.availableDatabaseList &&
    props.availableDatabaseList.length &&
    props.availableDatabaseList.map(Database => {
      return Database.name;
    });
  const [primaryValue, setValue] = useState('');

  useEffect(() => {
    setValue(state.globalDatabaseName);
    setDbName({ target: { value: state.globalDatabaseName } });
  }, [state.globalDatabaseName]);

  useEffect(() => {
    const prevSelectedDb = window.localStorage.getItem('globalDBName');
    if (databaseList && databaseList.length) {
      const selectedDB = databaseList.includes(prevSelectedDb)
        ? prevSelectedDb
        : databaseList[0];
      setValue(selectedDB);
      setDbName({ target: { value: selectedDB } });
    }
  }, [props.availableDatabaseList]);

  const globalSearchDebounce = debounce(key => {
    key = key?.trim()?.toLowerCase() || '';
    props.globalSearch(key);
  }, 350);

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);

  //   // returned function will be called on component unmount
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside)
  //   }
  // }, [])

  // const handleClickOutside = event => {
  //   if (myRef.current && !myRef.current.contains(event.target)) {
  //     props.handleClose();
  //   }
  // };

  const setDbName = ({ target: { value } }) => {
    setValue(value);
    props.setDbName(value);
    dispatch({ type: 'dbName', payload: value });
    dispatch({
      type: 'dbId',
      payload: (props.availableDatabaseList || []).find(d => d.name === value)
        ?.id,
    });
  };

  const renderSelectField = ({
    input,
    label,
    meta: { touched, error },
    children,
    ...custom
  }) => {
    return (
      <Select
        disableUnderline
        id={label}
        name={label}
        displayEmpty
        value={primaryValue}
        onChange={handleChange}
        {...input}
        renderValue={selected => {
          if (selected?.length === 0) {
            // setDbName(primaryValue);
            return <Typography>{primaryValue}</Typography>;
          }
          // setDbName(selected);
          return <Typography>{selected}</Typography>;
        }}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'Without label', value: primaryValue }}
        children={children}
        {...custom}
      ></Select>
    );
  };

  const handleChange = event => {
    console.log('in handlechange', event);
    const {
      target: { value },
    } = event;
    setValue(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    props.history.push('/');
    posthog?.capture(logMessages.CLICKED_ON_SIGN_OUT);
    posthog?.reset();
    window.localStorage.clear();
  };

  const handleSearchExpandToggle = () => setSearchExpanded(!searchExpanded);

  const handleDrawerToggle = () => {
    props.toggleDrawer();
    if (searchExpanded) handleSearchExpandToggle();
  };

  const handleNotificationToggle = () => {
    props.toogleNotifications();
    if (searchExpanded) handleSearchExpandToggle();
  };

  // useEffect(() => {
  //   props.currentUrl(window.location.pathname);
  // }, [window.location.pathname])

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>

        <div className={classes.branding}>
          <img
            src={props.logo}
            alt={props.logoAltText}
            height="45"
            className={classes.logo}
          />
        </div>

        <Hidden xsDown>
          <div className={classes.searchWrapper}>
            <Box className={classes.selectBox}>
              <FormControl className={styles.formControlStyle}>
                <Field
                  name="databaseName"
                  component={renderSelectField}
                  label="databaseName"
                  onChange={setDbName}
                >
                  <MenuItem disabled value="">
                    <em>{EN.SELECT_DATABASE}</em>
                  </MenuItem>
                  {databaseList.length > 0 &&
                    databaseList.map(item => (
                      <MenuItem
                        key={item}
                        value={item}
                        style={getStyles(item, name, theme)}
                      >
                        {item}
                      </MenuItem>
                    ))}
                </Field>
              </FormControl>
            </Box>
            <form className={classes.searchForm}>
              <IconButton aria-label="Search" className={classes.searchIcon}>
                <SearchIcon />
              </IconButton>
              <input
                id="search"
                className={classes.searchInput}
                type="text"
                placeholder="Search"
                onChange={e => {
                  console.log(e.target.value);
                  globalSearchDebounce(e.target.value);
                }}
                onKeyPress={e => props.onEnterkeyPress(e)}
                // autoFocus
              />
              {/* <Box className={styles.searchBox}>
                <FormControl className={styles.formControlStyle}>
                  <Field
                    name="databaseName"
                    component={renderSelectField}
                    label="databaseName"
                  >
                    <MenuItem disabled value="">
                      <em>{EN.SELECT_DATABASE}</em>
                    </MenuItem>
                    {databaseList.length > 0 &&
                      databaseList.map(item => (
                        <MenuItem
                          key={item}
                          value={item}
                          style={getStyles(item, name, theme)}
                        >
                          {item}
                        </MenuItem>
                      ))}
                  </Field>
                </FormControl>
              </Box> */}
            </form>
            {props.isFetchingGlobalSearchResults ? (
              <Backdrop
                invisible
                classes={{ root: cn(styles.listOverlay) }}
                open={props.isDropDownModelOpened}
                onClick={props.handleClose}
              >
                <div className={cn(styles.list)}>
                  <div className={cn(styles.data)}>Fetching Results...</div>
                </div>
              </Backdrop>
            ) : (
              props.loading === false &&
              props.isDropDownModelOpened &&
              props.searchKey && (
                <Backdrop
                  invisible
                  classes={{ root: cn(styles.listOverlay) }}
                  open={props.isDropDownModelOpened}
                  onClick={props.handleClose}
                >
                  <div className={cn(styles.list)}>
                    {props.searchData && props.searchData.length ? (
                      props.searchData.map((item, index) => {
                        const parts = (item.matchedField
                          ? getValueByPath(item, item.matchedField)
                          : item.title
                        ).split(new RegExp(`(${props.searchKey})`, 'gi'));
                        return (
                          <div
                            ref={myRef}
                            className={cn(styles.data, 'cursor')}
                            key={index}
                            onClick={() =>
                              props.redirectTo(props.dbId, item.objectId)
                            }
                          >
                            {parts.map((part, index) =>
                              part.toLowerCase() ===
                              props.searchKey.toLowerCase() ? (
                                <span
                                  key={index}
                                  style={{
                                    backgroundColor: 'yellow',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {part}
                                </span>
                              ) : (
                                <span key={index}>{part}</span>
                              )
                            )}
                            <span className={styles['sub-text']}>
                              {item.matchedField
                                ? ` in ${item.matchedField}`
                                : ' in title'}
                            </span>
                          </div>
                        );
                      })
                    ) : (
                      <div className={cn(styles.data)}>No Results</div>
                    )}
                  </div>
                </Backdrop>
              )
            )}
          </div>
        </Hidden>

        <Hidden smUp>
          <span className="flexSpacer" />
        </Hidden>

        <Hidden smUp>
          <IconButton
            color="inherit"
            onClick={handleSearchExpandToggle}
            aria-expanded={searchExpanded}
            aria-label="Show searchbar"
          >
            <SearchIcon />
          </IconButton>
        </Hidden>

        {/* <Hidden xsDown>
          <IconButton color="inherit" onClick={toggleFullscreen}>
            <FullscreenIcon />
          </IconButton>
        </Hidden> */}

        {/* <IconButton color="inherit" onClick={handleNotificationToggle}>
          <Badge badgeContent={5} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton> */}

        <IconButton
          aria-label="User Settings"
          aria-owns={anchorEl ? 'user-menu' : null}
          aria-haspopup="true"
          color="inherit"
          onClick={handleSettingdToggle}
        >
          <Person />
        </IconButton>

        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {/* <MenuItem onClick={() =>handleCloseMenu}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </MenuItem>
          <MenuItem onClick={() =>handleCloseMenu}>
            <ListItemIcon>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </MenuItem>
          <MenuItem onClick={() =>handleCloseMenu}>
            <ListItemIcon>
              <NotificationsOffIcon />
            </ListItemIcon>
            <ListItemText primary="Disable notifications" />
          </MenuItem> */}
          {(props.userName || props.userEmail || props.orgName) && (
            <MenuItem
              id="userName"
              className={styles['menuItem__user']}
              disableRipple
            >
              <ListItemText>
                {props.userName && (
                  <div className={styles['menuItem__user--name']}>
                    {props.userName}
                  </div>
                )}
                {props.userName !== props.userEmail && (
                  <div className={styles['menuItem__user--email']}>
                    {props.userEmail}
                  </div>
                )}
                {props.orgName && (
                  <div className={styles['menuItem__user--email']}>
                    {props.orgName}
                  </div>
                )}
              </ListItemText>
            </MenuItem>
          )}
          {/* {props.userEmail && <MenuItem id="userEmail" className={[styles.userEmail, "mt-8"]} style={{ cursor: 'default' }}>
            <ListItemText primary={props.userEmail} />
          </MenuItem>}
          {props.orgName && <MenuItem id="orgName" className="mt-8">
            <ListItemText className={styles.userName} primary={props.orgName} />
          </MenuItem>} */}
          <MenuItem id="menu" className="mt-8" onClick={() => signOut()}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Sign out" />
          </MenuItem>
        </Menu>
      </Toolbar>
      <Hidden smUp>
        <Collapse in={searchExpanded} timeout="auto" unmountOnExit>
          <Toolbar className={classes.toolBar}>
            <div className={classes.searchWrapper}>
              <form className={classNames(classes.searchForm, 'mr-0')}>
                <IconButton aria-label="Search" className={classes.searchIcon}>
                  <SearchIcon />
                </IconButton>
                <input
                  className={classes.searchInput}
                  type="text"
                  placeholder="Search"
                  autoFocus="true"
                />
              </form>
            </div>
          </Toolbar>
        </Collapse>
      </Hidden>
    </AppBar>
  );
};

// Header.prototypes = {
//   logo: PropTypes.string,
//   logoAltText: PropTypes.string
// };

const mapStateToProps = state => {
  return {
    otp: state.otp,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      mapStateToProps,
      labelStudioLogout,
    },
    dispatch
  );

// const ModuleForm = withRouter(connect(
//   mapDispatchToProps
// )(HeaderComponent));

export default withRouter(
  reduxForm({
    form: 'HeaderForm', // a unique identifier for this form
  })(HeaderComponent)
);
