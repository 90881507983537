/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable */
import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { reduxForm, Field } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@mui/material/Checkbox';
import { reset } from 'redux-form';

const useStyles = makeStyles(() => ({
  checkbox: {
    width: '24px',
    height: '24px',
  },
}));

const AddFilter = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(props.showAddFilterDialog);
  }, [props.showAddFilterDialog]);

  // React.useEffect(() => {
  //   props.resetForm(props.reset);
  // }, [props.databaseValue])

  const handleClose = () => {
    setOpen(false);
    props.setOpen();
    props.reset();
  };

  const renderCheckbox = ({
    input: { value, onChange, ...input },
    meta: { touched, error },
    isApplicationTag,
    ...rest
  }) => (
    <div>
      <Checkbox
        {...input}
        {...rest}
        defaultChecked={value}
        // onChange={(e, data) => onChange(data.checked)}
        type="checkbox"
        // tabIndex={-1}
        // disableRipple
        className={classes.checkbox}
        color="success"
      />
      {touched && error && <span>{error}</span>}
    </div>
  );

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Add Filter</DialogTitle>

      <DialogContent>
        <List
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
          }}
        >
          <Grid container alignItems={'center'}>
            {props.objectTag &&
              props.objectTag.map(value => (
                <Grid item xs={6}>
                  <ListItem key={value.id} role={undefined} button>
                    <Field name={value.name} component={renderCheckbox} />
                    <ListItemText
                      primary={value.name}
                      style={{
                        marginLeft: '4px',
                        overflowWrap: 'break-word',
                      }}
                    />
                  </ListItem>
                </Grid>
              ))}
          </Grid>
        </List>
        {props.dataApplication.length !== 0 && (
          <>
            <Divider className="my-4" />
            <List
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: 0,
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <h6>Application Tags</h6>
                </Grid>
                {props.dataApplication.map(value => (
                  <>
                    <Grid item xs={4}>
                      <ListItem key={value.id} role={undefined} button>
                        <Field
                          name={value.tagName}
                          component={renderCheckbox}
                        />
                        <ListItemText primary={value.tagName} />
                      </ListItem>
                    </Grid>
                  </>
                ))}
              </Grid>
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={props.handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default reduxForm({
  form: 'addFilterForm',
  enableReinitialize: true,
  // validate
})(AddFilter);
