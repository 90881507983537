/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import v4 from 'uuid/v4';
import cn from 'classnames';
import styles from './notification.module.css';
import close from '../../../assets/icons/close.svg';

const NotificationComponent = (props) => {
  const { notifMessage } = props;
  const [latestMessage, setLatestMessage] = useState('');
  useEffect(() => {
    if (notifMessage) {
      setLatestMessage(notifMessage);
    }
  }, [notifMessage]);

  const itemsList = () => {
    const classes = latestMessage.type === 'danger' ? styles.failureToast : styles.successToast;
    return (
      <CSSTransition key={v4()} in appear timeout={300} classNames="fade">
        <div key={latestMessage.id} className={classes}>
          <span />
          <span className="align-self-center lh_25p">
            {latestMessage.message}
          </span>
          {latestMessage.autoClose && (
            <button className={cn('btn-vidberrys btn-gray', styles.closeButton)} onClick={() => props.closeNotification(latestMessage.id)}><img width="10px" src={close} alt="" /></button>
          )}
        </div>
      </CSSTransition>
    );
  };
  return (
    <div className={styles.notificationContainer}>
      {notifMessage && notifMessage !== '' && itemsList()}
    </div>
  );
};

NotificationComponent.defaultProps = {
  autoClose: true,
};

export default NotificationComponent;
