/* eslint-disable */

import React, { useEffect, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
//import './HomeComponent.scss';
import PlayCircleFilled from '@mui/icons-material/PlayCircleFilled';
import { IconButton} from '@mui/material';


const PlayAudioButtonComponent = ({message, synth, voice}) => {

  // useEffect(() => {
    
  // }, [speechSynthesis.speaking])

  const speakText = () => {
    //const synth = window.speechSynthesis;
    //setTimeout(() => {
    //  console.log(window.speechSynthesis.getVoices());
    //   const voices = synth.getVoices();
    // console.log(voices)
    // const britishVoice = voices.find(voice => voice.name === "Google UK English Male"
    // );
    const utterance = new SpeechSynthesisUtterance(message);
    utterance.voice = voice;
    // if (britishVoice) {
    // }

    // console.log(utterance.voice)
    // console.log(utterance.voice)
    synth.speak(utterance);
    let r = setInterval(() => {
      console.log(speechSynthesis.speaking);
      if (!speechSynthesis.speaking) {
        clearInterval(r);
      } else {
        speechSynthesis.pause();
        speechSynthesis.resume();
      }
    }, 14000);
    //}, 100);
  };

  const iconStyle = {
    color: "#3f51b5"
  };

  return (
    <IconButton style={iconStyle} aria-label="play audio" size="small" onClick={speakText}>
    <PlayCircleFilled />
    </IconButton>        
  );
};

export default PlayAudioButtonComponent;
