/* eslint-disable */
import { Bar, Bubble } from 'react-chartjs-2';
import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@mui/material/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@mui/material/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import EmailIcon from '@material-ui/icons/Email';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/More';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import Paper from '@material-ui/core/Paper';
import PhoneIcon from '@material-ui/icons/Phone';
import SettingsIcon from '@material-ui/icons/Settings';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import red from '@material-ui/core/colors/red';
import OutlinedInput from '@mui/material/OutlinedInput';
import { reduxForm } from 'redux-form';
import { mockDashboard, mockFeed } from '../../utils/mock';
import SelectComponent from '../../components/common/Select';
import { getDatabaseList } from '../../reducers/database';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { APIConfig } from '@constants';

import {
  NewsCard,
  PostCard,
  StatCard,
  WeatherCard,
  Wrapper,
} from '../../components';
import { options } from '../../utils/mock/chart';

let id = 0;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function createData(name, user, noOrRecords, action) {
  id += 1;
  return {
    id,
    name,
    user,
    noOrRecords,
    action,
  };
}
const data = [
  createData('My youtube channel', 'Gaby', 1815, 'Manage'),
  createData('My Vidberrys channel', 'Arjuna', 650, 'Manage'),
  createData('Images', 'Spiderman', 1136, 'Manage'),
  createData('Production audio', 'Superman', 7812, 'Manage'),
];

function createDataForNotification(date, desc, action) {
  id += 1;
  return {
    id,
    date,
    desc,
    action,
  };
}

const dataForNotification = [
  createDataForNotification(
    'March 10, 2024',
    'Created database RetailerDB',
    'Info'
  ),
  createDataForNotification(
    'February 27, 2024',
    `Auto indexed tag, 'product_type' in 'RetailerDB' bucket`,
    'More info'
  ),
  createDataForNotification(
    'January 12, 2023',
    `Populated 678 objects into RetailerDB`,
    'Info'
  ),
  createDataForNotification(
    'December 20, 2023',
    'Broken link to data',
    'Repair'
  ),
  createDataForNotification(
    'November 25, 2023',
    `Labeled image classification`,
    'Annotation'
  ),
  createDataForNotification(
    'November 11, 2023',
    `Need to resize bucket, 'RetailerDB'`,
    'Resize'
  ),
];

const Usage = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [options, setOptions] = useState([]);
  const theme = useTheme();
  // const [personName, setPersonName] = useState([]);
  const [state] = useState({ age: '', name: 'hai' });

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const userId = window.localStorage.getItem('userSurrogateId');
    const userEmail = window.localStorage.getItem('userEmail');
    props.getDatabaseList(userId, 0, 0).then(results => {
      const databaseListDropdown =
        results.responseList &&
        results.responseList.map(Database => {
          return Database.name;
        });
      setOptions(databaseListDropdown);
    });
  }, []);

  const chartMenu = (
    <Menu
      id="chart-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <MoreIcon />
        </ListItemIcon>
        <ListItemText primary="View more" />
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <NotificationsOffIcon />
        </ListItemIcon>
        <ListItemText primary="Disable notifications" />
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Remove panel" />
      </MenuItem>
    </Menu>
  );

  const renderDropdown = () => {
    return (
      <Box sx={{ minWidth: 120, display: 'inline-block', float: 'right' }}>
        <FormControl className="w-200">
          <SelectComponent list={options} placeHolder="By Database" />
        </FormControl>
      </Box>
    );
  };

  return (
    <div className="usage-page-overlay">
      <div className="usage-overlay-message">Coming Soon...</div>
      <div className="mt-31">
        <Wrapper>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard
                type="fill"
                title="Databases"
                value={12}
                icon={<LocalOfferIcon />}
                color="#009688"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard
                type="fill"
                title="Objects"
                value={5862}
                icon={<PhoneIcon />}
                color="#9c27b0"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard
                type="fill"
                title="Queries"
                value={286}
                icon={<NotificationsIcon />}
                color="rgb(224 117 66)"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard
                type="fill"
                title="Monthly compute"
                value={48}
                icon={<EmailIcon />}
                color="#3f51b5"
              />
            </Grid>
            {chartMenu}
            {mockDashboard.map((chart, index) => (
              <Grid item xs={12} sm={12} md={4} keys={index}>
                <Card>
                  <CardHeader
                    id="chartText"
                    subheader={chart.title}
                    action={
                      <IconButton
                        id={`${index}-menu-button`}
                        onClick={handleClick}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    {chart.type === 'bar' && (
                      <Bar
                        data={chart.data}
                        height={chart.height}
                        options={chart.options}
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
            <Grid item xs={12} sm={12} md={12} className="m-10">
              <label className="mt-10">Latest notifications</label>
              {renderDropdown()}
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Paper className="table-responsive">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Date</b>
                      </TableCell>
                      <TableCell>
                        <b>Description</b>
                      </TableCell>
                      <TableCell>
                        <b>Actions</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataForNotification.map(n => (
                      <TableRow key={n.id}>
                        <TableCell component="th" scope="row">
                          {n.date}
                        </TableCell>
                        <TableCell>{n.desc}</TableCell>
                        <TableCell>
                          <a href="#" className="clrBlack">
                            {n.action}
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </Wrapper>
      </div>
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDatabaseList,
    },
    dispatch
  );

const ModuleForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Usage)
);

export default reduxForm({
  form: 'moduleForm', // a unique name for this form
})(ModuleForm);
