/* eslint-disable */
import React, { useEffect, useState } from 'react';
import CardContent from '@mui/material/CardContent';
import styles from './index.module.scss';
import api from '../../constants/api.js';
import CopyToClipboardButton from '../CopyToClipboardButton/index.jsx';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { usePostHog } from 'posthog-js/react';
import logMessages from '../../constants/logMessages';

const MlModels = () => {
  const posthog = usePostHog();
  const [expandedCardModelIds, setExpandedCardModelIds] = useState({});

  useEffect(() => {
    const url = `${api.mlBackendHostName}/${api.endpoints.mlModels}`;
    // const url = `${api.hostname}/${api.endpoints.mlModels}`;
    try {
      // return fetch(url);
    } catch (err) {
      console.error('err', err);
      throw err;
    }
  }, []);

  const modelsList = [
    {
      name: 'Named Entity Recognition (NER)',
      description:
        'Custom NER models that extracts labels like Person, GPE etc. from text',
      url: 'http://gpt.berrydb.io/ner',
      modelName: 'Custom NER model',
      host: 'Built-In',
      isBuiltIn: true,
      usageData: {
        title: 'USAGE',
        content:
          '# Install BerryDB using pip\n' +
          'pip install BerryDB\n\n' +
          '# Import BerryDB\n' +
          'from berrydb.BerryDB import BerryDB\n\n' +
          "document_ids = ['**DOCUMENT_ID**', '**DOCUMENT_ID**']\n\n" +
          '# Connect to a BerryDB Database\n' +
          "db_conn = BerryDB.connect('**API_KEY**', '**DATABASE_NAME**')\n\n" +
          '# Retrieve Predictions\n' +
          "db_conn.ner('**JSON_PATH**', document_ids)" +
          '\n' +
          '# Annotate documents\n' +
          "db_conn.ner('**JSON_PATH**', document_ids, True)" +
          '',
      },
    },
    {
      name: 'Medical NER',
      description:
        'Medical documents NER model by fine tuning BERT. The model identifies tags Medical Condition, Problem etc. from text',
      url: 'http://gpt.berrydb.io/ner/medical-hf',
      modelName: 'bert-medical-ner-proj',
      host: 'Hugging Face',
      usageData: {
        title: 'USAGE',
        content:
          '# Install BerryDB using pip\n' +
          'pip install BerryDB\n\n' +
          '# Import BerryDB\n' +
          'from berrydb.BerryDB import BerryDB\n\n' +
          "document_ids = ['**DOCUMENT_ID**', '**DOCUMENT_ID**']\n\n" +
          '# Connect to a BerryDB Database\n' +
          "db_conn = BerryDB.connect('**API_KEY**', '**DATABASE_NAME**')\n\n" +
          '# Retrieve Predictions\n' +
          "db_conn.medical_ner('**JSON_PATH**', document_ids)" +
          '\n' +
          '# Annotate documents\n' +
          "db_conn.medical_ner('**JSON_PATH**', document_ids, True)" +
          '',
      },
    },
    {
      name: 'Text Classification MDeberta',
      description:
        'Model designed to classify text in to a particular category given a list of categories using zero-shot classification',
      url: 'http://gpt.berrydb.io/text-classification/mdeberta-hf',
      modelName: 'deberta-v3-large-zeroshot-v1.1-all-33',
      host: 'Hugging Face',
      /* usageData: {
        title: 'USAGE',
        content:
          '# Install BerryDB using pip\n' +
          'pip install BerryDB\n\n' +
          '# Import BerryDB\n' +
          'from berrydb.BerryDB import BerryDB\n\n' +
          "document_ids = ['**DOCUMENT_ID**', '**DOCUMENT_ID**']\n\n" +
          '# Connect to a BerryDB Database\n' +
          "db_conn = BerryDB.connect('**API_KEY**', '**DATABASE_NAME**')\n\n" +
          "labels = ['**LABEL_1**', '**LABEL_2**']\n" +
          '# Retrieve Predictions\n' +
          "db_conn.text_classification('**JSON_PATH**', document_ids, labels)" +
          '\n' +
          '# Annotate documents\n' +
          "db_conn.text_classification('**JSON_PATH**', document_ids, labels, True)" +
          '',
      }, */
    },
    {
      name: 'Text Classification Facebook',
      url: 'http://gpt.berrydb.io/text-classification/facebook-hf',
      description:
        'bart-large model trained with MultiNLI dataset for Zero Shot Text Classification',
      modelName: 'bart-large-mnli',
      host: 'Hugging Face',
      usageData: {
        title: 'USAGE',
        content:
          '# Install BerryDB using pip\n' +
          'pip install BerryDB\n\n' +
          '# Import BerryDB\n' +
          'from berrydb.BerryDB import BerryDB\n\n' +
          "document_ids = ['**DOCUMENT_ID**', '**DOCUMENT_ID**']\n\n" +
          '# Connect to a BerryDB Database\n' +
          "db_conn = BerryDB.connect('**API_KEY**', '**DATABASE_NAME**')\n\n" +
          "labels = ['**LABEL_1**', '**LABEL_2**']\n" +
          '# Retrieve Predictions\n' +
          "db_conn.text_classification('**JSON_PATH**', document_ids, labels)" +
          '\n' +
          '# Annotate documents\n' +
          "db_conn.text_classification('**JSON_PATH**', document_ids, labels, True)" +
          '',
      },
    },
    {
      name: 'Text Summarization',
      url: 'http://gpt.berrydb.io/text-summarize/falcon-hf',
      description: 'Fine-Tuned T5 Small for Text Summarization',
      modelName: 't5-small falcon',
      host: 'Hugging Face',
      usageData: {
        title: 'USAGE',
        content:
          '# Install BerryDB using pip\n' +
          'pip install BerryDB\n\n' +
          '# Import BerryDB\n' +
          'from berrydb.BerryDB import BerryDB\n\n' +
          "document_ids = ['**DOCUMENT_ID**', '**DOCUMENT_ID**']\n\n" +
          '# Connect to a BerryDB Database\n' +
          "db_conn = BerryDB.connect('**API_KEY**', '**DATABASE_NAME**')\n\n" +
          '# Retrieve Predictions\n' +
          "db_conn.text_summarization('**JSON_PATH**', document_ids)" +
          '\n' +
          '# Annotate documents\n' +
          "db_conn.text_summarization('**JSON_PATH**', document_ids, True)" +
          '',
      },
    },
    {
      name: 'Image Classification',
      url: 'http://gpt.berrydb.io/image-classification/clip-hf',
      description: 'Open AI CLIP for image classification',
      modelName: 'clip-vit-large-patch14',
      host: 'Hugging Face',
      usageData: {
        title: 'USAGE',
        content:
          '# Install BerryDB using pip\n' +
          'pip install BerryDB\n\n' +
          '# Import BerryDB\n' +
          'from berrydb.BerryDB import BerryDB\n\n' +
          "document_ids = ['**DOCUMENT_ID**', '**DOCUMENT_ID**']\n\n" +
          '# Connect to a BerryDB Database\n' +
          "db_conn = BerryDB.connect('**API_KEY**', '**DATABASE_NAME**')\n\n" +
          '# Retrieve Predictions\n' +
          "db_conn.image_classification('**JSON_PATH**', document_ids)" +
          '\n' +
          '# Annotate documents\n' +
          "db_conn.image_classification('**JSON_PATH**', document_ids, True)" +
          '',
      },
    },
    {
      name: 'Image Captioning',
      url: 'http://gpt.berrydb.io/image-caption',
      description: 'Model for image captioning',
      modelName: 'moondream1',
      host: 'Built-In',
      isBuiltIn: true,
      usageData: {
        title: 'USAGE',
        content:
          '# Install BerryDB using pip\n' +
          'pip install BerryDB\n\n' +
          '# Import BerryDB\n' +
          'from berrydb.BerryDB import BerryDB\n\n' +
          "document_ids = ['**DOCUMENT_ID**', '**DOCUMENT_ID**']\n\n" +
          '# Connect to a BerryDB Database\n' +
          "db_conn = BerryDB.connect('**API_KEY**', '**DATABASE_NAME**')\n\n" +
          '# Retrieve Predictions\n' +
          "db_conn.image_captioning('**JSON_PATH**', document_ids)" +
          '\n' +
          '# Annotate documents\n' +
          "db_conn.image_captioning('**JSON_PATH**', document_ids, True)" +
          '',
      },
    },
    {
      name: 'Pneumonia detection',
      url: 'http://gpt.berrydb.io/image-classification/pneumonia',
      description: 'Custom model to identify Pneumonia in X-ray/MRI/CT scans',
      modelName: 'Custom built model',
      host: 'Built-In',
      isBuiltIn: true,
      usageData: {
        title: 'USAGE',
        content:
          '# Install BerryDB using pip\n' +
          'pip install BerryDB\n\n' +
          '# Import BerryDB\n' +
          'from berrydb.BerryDB import BerryDB\n\n' +
          "document_ids = ['**DOCUMENT_ID**', '**DOCUMENT_ID**']\n\n" +
          '# Connect to a BerryDB Database\n' +
          "db_conn = BerryDB.connect('**API_KEY**', '**DATABASE_NAME**')\n\n" +
          '# Retrieve Predictions\n' +
          "db_conn.pneumonia_detection('**JSON_PATH**', document_ids)" +
          '\n' +
          '# Annotate documents\n' +
          "db_conn.pneumonia_detection('**JSON_PATH**', document_ids, True)" +
          '',
      },
    },
    {
      name: 'Alzheimers detection',
      url: 'http://gpt.berrydb.io/image-classification/alzheimer',
      description:
        'Custom model for Alzheimers detection in brain MRI/CT scans',
      modelName: 'Custom build model',
      host: 'Built-In',
      isBuiltIn: true,
      usageData: {
        title: 'USAGE',
        content:
          '# Install BerryDB using pip\n' +
          'pip install BerryDB\n\n' +
          '# Import BerryDB\n' +
          'from berrydb.BerryDB import BerryDB\n\n' +
          "document_ids = ['**DOCUMENT_ID**', '**DOCUMENT_ID**']\n\n" +
          '# Connect to a BerryDB Database\n' +
          "db_conn = BerryDB.connect('**API_KEY**', '**DATABASE_NAME**')\n\n" +
          '# Retrieve Predictions\n' +
          "db_conn.alzheimer_detection('**JSON_PATH**', document_ids)" +
          '\n' +
          '# Annotate documents\n' +
          "db_conn.alzheimer_detection('**JSON_PATH**', document_ids, True)" +
          '',
      },
    },
    {
      name: 'Object detection for clothing',
      url: 'http://gpt.berrydb.io/object-detection/fashion-hf',
      description:
        'Detects pieces of clothing and fashion accessories in an image',
      modelName: 'yolos-fashionpedia',
      host: 'Hugging Face',
      usageData: {
        title: 'USAGE',
        content:
          '# Install BerryDB using pip\n' +
          'pip install BerryDB\n\n' +
          '# Import BerryDB\n' +
          'from berrydb.BerryDB import BerryDB\n\n' +
          "document_ids = ['**DOCUMENT_ID**', '**DOCUMENT_ID**']\n\n" +
          '# Connect to a BerryDB Database\n' +
          "db_conn = BerryDB.connect('**API_KEY**', '**DATABASE_NAME**')\n\n" +
          '# Retrieve Predictions\n' +
          "db_conn.fashion('**JSON_PATH**', document_ids)" +
          '\n' +
          '# Annotate documents\n' +
          "db_conn.fashion('**JSON_PATH**', document_ids, True)" +
          '',
      },
    },
    {
      name: 'Audio Transcription',
      url: 'http://gpt.berrydb.io/audio/transcription/whisper-hf',
      description: 'Model to extract transcriptions from audio',
      modelName: 'Open AI Whisper',
      host: 'Hugging Face',
      usageData: {
        title: 'USAGE',
        content:
          '# Install BerryDB using pip\n' +
          'pip install BerryDB\n\n' +
          '# Import BerryDB\n' +
          'from berrydb.BerryDB import BerryDB\n\n' +
          "document_ids = ['**DOCUMENT_ID**', '**DOCUMENT_ID**']\n\n" +
          '# Connect to a BerryDB Database\n' +
          "db_conn = BerryDB.connect('**API_KEY**', '**DATABASE_NAME**')\n\n" +
          '# Retrieve Predictions\n' +
          "db_conn.audio_transcription('**JSON_PATH**', document_ids)" +
          '\n' +
          '# Annotate documents\n' +
          "db_conn.audio_transcription('**JSON_PATH**', document_ids, True)" +
          '',
      },
    },
  ];

  return (
    <div className={styles['models-page']}>
      <h4 className={styles['page-title']} style={{ fontSize: '21px' }}>
        ML Models
      </h4>
      <div className={styles['models-page__card-list']}>
        {modelsList.map((model, index) => (
          <div
            className={[
              styles['card-item'],
              model.usageData ? styles['card-item__clickable'] : '',
              expandedCardModelIds[index] ? styles['card-item__expanded'] : '',
            ].join(' ')}
            onClick={() => {
              if (!model.usageData) {
                return;
              }
              const newExpandedCardModelIds = { ...expandedCardModelIds };
              newExpandedCardModelIds[index] = !newExpandedCardModelIds[index];
              setExpandedCardModelIds(newExpandedCardModelIds);
            }}
          >
            <div className={styles['card-item__title']}>{model.name}</div>
            <div className={styles['card-item__sub-title']}>
              {model.description}
            </div>
            <div
              className={styles['card-item__url-container']}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <div className={styles['text-wrapper']}>
                <div className={styles['text-wrapper__text']}>{model.url}</div>
              </div>
              <div
                onClick={e => {
                  posthog?.capture(
                    logMessages.COPIED_ML_MODEL_URL_TO_CLIPBOARD,
                    {
                      URL: model.url,
                    }
                  );
                }}
              >
                <CopyToClipboardButton text={model.url} />
              </div>
            </div>
            <div
              className={[
                styles['card-item__attributes'],
                styles['card-item__attributes--align-end'],
              ].join(' ')}
            >
              <span className={styles['attribute']}>{model.modelName}</span>
              <span className={styles['attribute__delimiter']}>|</span>
              <span
                className={[
                  styles['attribute'],
                  // model.isBuiltIn ? styles['attribute--green'] : '',
                ].join(' ')}
              >
                {model.isBuiltIn ? '' : 'Hosted on: '}
                {model.host}
              </span>
            </div>
            {model.usageData && (
              <div
                className={[
                  styles['model-usage'],
                  expandedCardModelIds[index]
                    ? styles['model-usage__expanded']
                    : '',
                ].join(' ')}
              >
                <div
                  className={[
                    styles['model-usage__header'],
                    expandedCardModelIds[index]
                      ? styles['model-usage__header--visible']
                      : '',
                  ].join(' ')}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <div className={styles['title']}>{model.usageData.title}</div>
                  <div
                    className={styles['copy-btn']}
                    onClick={e => {
                      posthog?.capture(
                        logMessages.COPIED_ML_MODEL_CONTENT_TO_CLIPBOARD,
                        { content: model.usageData.content }
                      );
                    }}
                  >
                    <CopyToClipboardButton text={model.usageData.content} />
                  </div>
                </div>
                {expandedCardModelIds[index] && (
                  <SyntaxHighlighter
                    language="python"
                    style={docco}
                    className={[styles['model-usage__textarea']].join(' ')}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  >
                    {expandedCardModelIds[index] ? model.usageData.content : ''}
                  </SyntaxHighlighter>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MlModels;
