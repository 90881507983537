export const GET_DETAILS_REQUESTED = 'tags/GET_DETAILS_REQUESTED';
export const GET_DETAILS_SUCCESS = 'tags/GET_DETAILS_SUCCESS';
export const GET_DETAILS_FAILURE = 'tags/GET_DETAILS_FAILURE';

export const GET_DETAILS_BY_QUERY_REQUESTED = 'tags/GET_DETAILS_BY_QUERY_REQUESTED';
export const GET_DETAILS_BY_QUERY_SUCCESS = 'tags/GET_DETAILS_BY_QUERY_SUCCESS';
export const GET_DETAILS_BY_QUERY_FAILURE = 'tags/GET_DETAILS_BY_QUERY_FAILURE';

export const GET_ANNOTATIONS_REQUESTED = 'tags/GET_ANNOTATIONS_REQUESTED';
export const GET_ANNOTATIONS_SUCCESS = 'tags/GET_ANNOTATIONS_SUCCESS';
export const GET_ANNOTATIONS_FAILURE = 'tags/GET_ANNOTATIONS_FAILURE';

export const DELETE_DETAILS_REQUESTED = 'tags/DELETE_DETAILS_REQUESTED';
export const DELETE_DETAILS_SUCCESS = 'tags/DELETE_DETAILS_SUCCESS';
export const DELETE_DETAILS_FAILURE = 'tags/DELETE_DETAILS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DETAILS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_DETAILS_BY_QUERY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_DETAILS_BY_QUERY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_DETAILS_BY_QUERY_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_ANNOTATIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_ANNOTATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_ANNOTATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case DELETE_DETAILS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DELETE_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DELETE_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    default:
      return state;
  }
};

export const getItemDetails = (payload) => {
  return {
    types: [GET_DETAILS_REQUESTED, GET_DETAILS_SUCCESS, GET_DETAILS_FAILURE],
    promise: (client) => client.post('getItemDetails', {
      data: payload
    })
  };
};

export const getItemDetailsByQuery = (payload) => {
  return {
    types: [GET_DETAILS_BY_QUERY_REQUESTED, GET_DETAILS_BY_QUERY_SUCCESS, GET_DETAILS_BY_QUERY_FAILURE],
    promise: (client) => client.post('getItemDetailsByQuery', {
      data: payload
    })
  };
};

export const getAnnotations = (database) => {
  return {
    types: [GET_ANNOTATIONS_REQUESTED, GET_ANNOTATIONS_SUCCESS, GET_ANNOTATIONS_FAILURE],
    promise: (client) => client.get(`getAnnotations?databaseId=${database}`)
  };
};

export const deleteItemFromFilterList = (payload) => {
  return {
    types: [DELETE_DETAILS_REQUESTED, DELETE_DETAILS_SUCCESS, DELETE_DETAILS_FAILURE],
    promise: (client) => client.del('deleteItemFromFilterList', {
      data: payload
    })
  };
};
