import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { StylesProvider, jssPreset, ThemeProvider } from '@material-ui/styles';

import { create } from 'jss';
import { createMuiTheme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import red from '@material-ui/core/colors/red';
import rtl from 'jss-rtl';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export const Context = createContext();
const { Provider } = Context;

const reducer = (state, action) => {
  switch (action.type) {
    case 'direction':
      return {
        ...state,
        direction: state.direction === 'ltr' ? 'rtl' : 'ltr',
      };

    case 'type':
      return { ...state, type: state.type === 'light' ? 'dark' : 'light' };

    case 'dbName':
      return { ...state, globalDatabaseName: action.payload };

    case 'dbId':
      return { ...state, globalDatabaseId: action.payload };

    default:
      return state;
  }
};

const AppProvider = ({ children }) => {
  const prefersDarkMode = useMediaQuery('@media (prefers-color-scheme: dark)');
  // const [selectedDbName, setSelectedDbName] = useState(
  //   window.localStorage.getItem('globalDBName') || ''
  // );
  // const [selectedDbId, setSelectedDbId] = useState(
  //   window.localStorage.getItem('globalDBId') || ''
  // );
  const [state, dispatch] = useReducer(reducer, {
    type: prefersDarkMode ? 'dark' : 'light',
    direction: 'ltr',
    globalDatabaseName: window.localStorage.getItem('globalDBName'),
    globalDatabaseId: window.localStorage.getItem('globalDBId'),
  });

  const theme = createMuiTheme({
    direction: state.direction,
    palette: {
      type: state.type,
      primary: indigo,
      secondary: red,
      error: red,
    },
    typography: {
      headline: {
        fontSize: '1rem',
      },
      subtitle1: {
        fontSize: '0.8125rem',
      },
      button: {
        fontWeight: 400,
        textTransform: 'initial',
      },
      body1: {
        fontSize: '0.875rem',
      },
    },
    shape: {
      borderRadius: 4,
    },
  });

  useEffect(() => {
    document.body.dir = state.direction;
  }, [state.direction]);

  // useEffect(() => {
  //   setSelectedDbId(window.localStorage.getItem('globalDBId') || '');
  //   setSelectedDbName(window.localStorage.getItem('globalDBName') || '');
  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <Provider value={[state, dispatch]}>{children}</Provider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default AppProvider;
export const useAppState = () => useContext(Context);
