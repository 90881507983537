/* eslint-disable */
const TITLE = '';

export default {
  LOGIN: `${TITLE} Login`,
  SIGNUP: `${TITLE} Sign up`,
  RESET_PASSWORD: `${TITLE} Reset password`,
  BASE_URL: process.env.PUBLIC_URL,
};

export const GOOGLE_KEY = 'AIzaSyBgMi_tKS5WRdH_RrCmdApIdCQCEZRIOyE';
export const GOOGLE_OAUTH_CLIENT_ID =
  '585787995241-429f9o7t0o94p57248beh571940uvhdb.apps.googleusercontent.com';
export const POSTHOG_HOST = 'https://us.i.posthog.com';
export const POSTHOG_KEY = 'phc_ai9E2ORKJb9PE2bmPVmEZj50QtLcktp2sX5yggMjrPu';
