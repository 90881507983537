/* eslint-disable */
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ButtonGroup from '@mui/material/ButtonGroup';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListItem from '@mui/material/ListItem';
import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { ProductCardCompact, Wrapper } from '..';
import { ellipsis, getValueByPath } from '../../helpers/utils';
import EN from '../../localization/EN';
import { drawerWidth } from '../../styleVariables';
import TextField from '../common/formElements/TextField';
import { useAppState } from '../AppProvider/AppProvider';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    // overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 100,
  },
  toolBar: {
    paddingLeft: theme.spacing(1) / 2,
    paddingRight: theme.spacing(1) / 2,
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    maxWidth: drawerWidth,
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'transparent',
      // overflow: 'visible'
      height: 'calc(100vh - 70px)',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 56px)',
    },
    [theme.breakpoints.up('sm')]: {
      //top: '0',
      height: 'calc(100vh - 130px)',
    },
    border: 0,
    overflowY: 'auto',
    marginTop: '40px',
    position: 'sticky',
    top: '70px',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    minWidth: 0,
    marginTop: '40px',
  },
  modal: {
    [theme.breakpoints.down('sm')]: {
      top: '56px',
    },
    [theme.breakpoints.up('sm')]: {
      top: '64px',
    },
    zIndex: '1000',
  },
  backdrop: {
    [theme.breakpoints.down('sm')]: {
      top: '56px',
    },
    [theme.breakpoints.up('sm')]: {
      top: '64px',
    },
  },
  checkbox: {
    width: '24px',
    height: '24px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '90%',
  },
  label: {
    margin: '0px !important',
  },
  listItem: {
    padding: '10px !important',
  },
  stickyButtonGroup: {
    // position: 'sticky',
    bottom: '0px',
    position: 'fixed',
    width: '200px',
    zIndex: '999',
  },
  formStyle: {
    width: '100%',
    // height: 'inherit'
  },
}));

const QueryComponent = props => {
  const classes = useStyles();
  const [targetKey, setTargetKey] = useState('');
  const [itemToShow, setItemToShow] = useState(5);
  // const [channelItemToShow, setchannelItemToShow] = useState(5);
  const [opened, setOpened] = useState(false);
  const [products, setProducts] = useState('');
  const [metadata, setMetadata] = useState('');
  const [isEmptyProduct, setIsEmptyProduct] = useState(false);
  const [state, dispatch] = useAppState();
  // const [collapseOpen, setIsCollapseOpen] = useState(false);
  var timeoutHandle;
  useEffect(() => {
    window.clearTimeout(timeoutHandle);
    if (!props.loading && props.products.length === 0) {
      timeoutHandle = window.setTimeout(function() {
        setIsEmptyProduct(true);
      }, 500);
    } else {
      setIsEmptyProduct(false);
    }
    setProducts(props.products);
    setMetadata(props.metadata ? props.metadata.fieldsByDataType : {});
  }, [props.products]);

  useEffect(() => {
    if (props.loading) {
      setIsEmptyProduct(false);
    }
  }, [props.loading]);

  useEffect(() => {
    props.handleChange(
      'name',
      props.reset
    )({ target: { value: state.globalDatabaseName } });
  }, [state.globalDatabaseName]);

  const handleDrawerToggle = () => setOpened(!opened);

  const setCollapse = (key, value) => {
    setTargetKey(itemToShow === 5 ? key : '');
    setItemToShow(itemToShow === 5 ? value : 5);
  };
  const conditionalCheckbox = (index, val, key) => {
    if (targetKey == key) {
      return (
        index < itemToShow && (
          <>
            <ListItem
              key={val}
              role={undefined}
              button
              className={classes.listItem}
            >
              <Field
                name={val?.replaceAll('.', '')}
                component={renderCheckbox}
                label={val}
              />
            </ListItem>
          </>
        )
      );
    } else {
      return (
        index < 5 && (
          <>
            <ListItem
              key={val}
              role={undefined}
              button
              className={classes.listItem}
            >
              <Field
                name={val?.replaceAll('.', '')}
                component={renderCheckbox}
                label={val}
              />
            </ListItem>
          </>
        )
      );
    }
  };
  const renderCheckbox = ({
    input: { value, onChange, ...input },
    meta: { touched, error },
    label,
    ...rest
  }) => (
    <div>
      <FormControlLabel
        className={classes.label}
        control={
          <Checkbox
            {...input}
            {...rest}
            defaultChecked={value}
            // onChange={(e, data) => onChange(data.checked)}
            type="checkbox"
            // tabIndex={-1}
            disableRipple
            className={classes.checkbox}
            // style ={{
            //   color: "rgb(3 195 102)",
            // }}
            color="success"
          />
        }
        label={<Typography className="ml-1">{ellipsis(label, 12)}</Typography>}
      />
      {touched && error && <span>{error}</span>}
    </div>
  );

  const menu = (
    <>
      <form onSubmit={props.handleSubmit} className={classes.formStyle}>
        {props.allFilterDataList &&
          props.allFilterDataList.length > 0 &&
          Object.entries(props.allFilterDataList[0]).map(([key, value]) => {
            return (
              <>
                <List
                  subheader={
                    <ListSubheader disableSticky>
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </ListSubheader>
                  }
                >
                  {value &&
                    value.map((val, index) => (
                      <>{conditionalCheckbox(index, val, key)}</>
                    ))}
                  {value.length > 5 && (
                    <div className="d-flex justify-content-end">
                      <Button
                        className="mr-1"
                        onClick={() => setCollapse(key, value.length)}
                        edge="end"
                        aria-label="comments"
                        size="small"
                      >
                        {key === targetKey ? 'less' : 'more'}{' '}
                        {key === targetKey ? <ExpandLess /> : <ExpandMore />}
                      </Button>
                    </div>
                  )}
                </List>
                <Divider />
              </>
            );
          })}

        {props.addedFilter.length !== 0 &&
          props.addedFilter.map(value => (
            <>
              <Divider />
              <List
                subheader={<ListSubheader disableSticky>{value}</ListSubheader>}
              >
                <ListItem
                  key={value}
                  role={undefined}
                  button
                  className={classes.listItem}
                >
                  <TextField id={value} name={value} variant="standard" />
                </ListItem>
              </List>
            </>
          ))}

        <Grid container direction="row" className={classes.stickyButtonGroup}>
          <ButtonGroup
            fullWidth
            className="ml-1"
            variant="contained"
            aria-label="outlined primary button group"
          >
            <Button type="submit" variant="contained" color="primary">
              Apply
            </Button>
            <Button
              className="bg-white"
              onClick={() => props.setOpen()}
              variant="outlined"
              color="primary"
            >
              Add Filter
            </Button>
          </ButtonGroup>
          <Grid container direction="row">
            <Button
              variant="body2"
              className="p-1 w-100 clrBlack"
              onClick={() => props.resetForm(props.reset, 'reset')}
            >
              <Typography style={{ textDecoration: 'underline' }}>
                Reset
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );

  return (
    <Wrapper padding={false}>
      <Hidden mdUp>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => handleDrawerToggle()}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>
              Products
            </Typography>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Grid className={classes.root}>
        <Hidden smDown>
          <Drawer
            variant="permanent"
            ModalProps={{
              keepMounted: false,
              className: classes.modal,
              BackdropProps: {
                className: classes.backdrop,
              },
              onBackdropClick: handleDrawerToggle,
            }}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {menu} {/* Menu variable called here */}
          </Drawer>
        </Hidden>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            open={opened}
            ModalProps={{
              keepMounted: false,
              className: classes.modal,
              BackdropProps: {
                className: classes.backdrop,
              },
              onBackdropClick: handleDrawerToggle,
            }}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {menu}
          </Drawer>
        </Hidden>
        <main className={classes.content}>
          <Grid container spacing={2} className="pb-5">
            {props.loading && !props.paginationLoading && (
              <CircularProgress
                style={{ position: 'absolute', top: '40%', right: '45%' }}
              />
            )}
            {/* {products.length !== 0 && (
              <Grid container direction="row-reverse" className="px-2">
                <Button
                  onClick={() => props.showAlertForDelete('all')}
                  type="button"
                  variant="outlined"
                  color="secondary"
                >
                  Delete All
                </Button>
              </Grid>
            )} */}
            {products &&
              products.map((product, index) => {
                const name =
                  metadata &&
                  ((metadata.displayTextField &&
                    metadata.displayTextField.length &&
                    getValueByPath(product, metadata.displayTextField[0])) ||
                    (metadata.fts &&
                      metadata.fts.length &&
                      getValueByPath(product, metadata.fts[0])) ||
                    '');
                const imageType =
                  metadata &&
                  metadata.image &&
                  metadata.image.length &&
                  metadata.image[0];
                const displayImageField =
                  (metadata &&
                    metadata.displayImageField &&
                    metadata.displayImageField.length &&
                    getValueByPath(product, metadata.displayImageField[0])) ||
                  null;
                return (
                  <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
                    <ProductCardCompact
                      id={product.id}
                      dbId={product.databaseId}
                      name={name}
                      type={product.type}
                      description={product.description}
                      discounted={product.discounted}
                      discount={product.discount}
                      thumbnail={product.itemPic}
                      displayImageField={displayImageField}
                      uploadedTime={
                        product && product._meta && product._meta.uploadedTime
                      }
                      databaseName={props.databaseValue}
                      onDeleteItem={props.showAlertForDelete}
                      dHash={
                        product[imageType] &&
                        product[imageType][0] &&
                        product[imageType][0].fingerprint
                      }
                      threedots={!!(imageType === 'image')}
                      onClickSimilar={props.onClickSimilar}
                    />
                  </Grid>
                );
              })}
            {props.paginationLoading && products.length > 0 && (
              <Grid container>
                <Grid item xs={12}>
                  <h5 className="text-center">Loading...</h5>
                </Grid>
              </Grid>
            )}
          </Grid>
          {!props.loading && products.length === 0 && isEmptyProduct && (
            <Grid className="dflex h-75" container>
              <Grid className="align-self-center" item xs={12}>
                <h2 className="text-center text-muted">No Data available</h2>
              </Grid>
            </Grid>
          )}
          {props.error && (
            <Grid className="dflex h-75" container>
              <Grid className="align-self-center" item xs={12}>
                <h2 className="text-center text-muted">
                  Error in Request ! Try Again.
                </h2>
              </Grid>
            </Grid>
          )}
        </main>
      </Grid>
    </Wrapper>
  );
};

export default reduxForm({
  form: 'queryForm',
  enableReinitialize: true,
  // initialValues: {
  //   iop: "initial currentPassword",
  // },
  // validate
})(QueryComponent);
