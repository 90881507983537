/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import cn from 'classnames';
import FormValidationMessages from '@components/common/FormValidationMessages';
// import styles from './TextField.module.css';
import TextField from '@mui/material/TextField';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { th } from 'date-fns/esm/locale';

const theme = createMuiTheme({
  palette: {
    text: {
      disabled: '#383838'
    }
  },
});
class renderField extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const {
      input,
      errorLabel,
      label,
      type,
      readOnly,
      maxLength,
      meta: { touched, error, warning },
      customClass,
      autocomplete,
      placeholder,
      labelIconClass,
      normalize,
      customLabelCss,
      hasNoStartingSpace,
      errorLabelCustomClass,
      initialValue,
      customErrorLabel,
      onKeyPress,
      adonLeft,
      adonRight,
      adonLeftClass,
      adonRightClass,
      adonLeftContent,
      adonRightContent,
      onBlur,
      onKeyDown,
      onKeyUp,
      min,
      max,
      required,
      requiredText,
      disabled,
      onPaste,
      variant,
      InputProps,
      id,
      onChange
    } = this.props;
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          // {...input}
          label={label}
          id={id}
          // className={isLast && !disable ? 'align-self-end' : 'align-self-center'}
          // InputProps={{
          //   disableUnderline: (disable || !isLast),
          // }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          // inputProps={{
          //   className: classes.input
          // }}
          InputLabelProps={{ shrink: true }}
          variant={variant ? variant : 'outlined'}
          fullWidth
          // size="small"
          disabled={disabled}
          value={input.value ? input.value : initialValue}
          InputProps={InputProps}
          onChange={onChange}
          // className={this.props.classes.disabledInput}
        />
        {touched && error && <span>{error}</span>}
        </ThemeProvider>
      </Fragment>
    );
  }
}

function mapStateToProps({ localization }) {
  return {
    language: localization.language
  };
}

const ConnectedRenderField = connect(mapStateToProps)(renderField);

const Textfield = (props) => {
  const {
    name,
    type,
    errorLabel,
    label,
    readOnly,
    maxLength,
    validate,
    customClass,
    autocomplete,
    onChange,
    labelIconClass,
    placeholder,
    normalize,
    customLabelCss,
    onBlur,
    hasNoStartingSpace,
    errorLabelCustomClass,
    initialValue,
    customErrorLabel,
    showLabel,
    onKeyPress,
    adonLeft,
    adonRight,
    adonLeftClass,
    adonRightClass,
    adonLeftContent,
    adonRightContent,
    onKeyDown,
    onKeyUp,
    min,
    max,
    required,
    requiredText,
    disabled,
    onPaste,
    variant,
    id,
    InputProps
  } = props;
  return (
    <Field
      name={name}
      type={type}
      readOnly={readOnly}
      maxLength={maxLength}
      component={ConnectedRenderField}
      label={label}
      errorLabel={errorLabel}
      validate={validate}
      customClass={customClass}
      autocomplete={autocomplete}
      onChange={onChange}
      placeholder={placeholder}
      labelIconClass={labelIconClass}
      normalize={normalize}
      customLabelCss={customLabelCss}
      onBlur={onBlur}
      hasNoStartingSpace={hasNoStartingSpace}
      errorLabelCustomClass={errorLabelCustomClass}
      initialValue={initialValue}
      customErrorLabel={customErrorLabel}
      showLabel={showLabel}
      onKeyPress={onKeyPress}
      adonLeft={adonLeft}
      adonRight={adonRight}
      adonLeftClass={adonLeftClass}
      adonRightClass={adonRightClass}
      adonLeftContent={adonLeftContent}
      adonRightContent={adonRightContent}
      onKeyDown={onKeyDown}
      required={required}
      onKeyUp={onKeyUp}
      min={min}
      max={max}
      requiredText={requiredText}
      disabled={disabled}
      onPaste={onPaste}
      variant={variant}
      InputProps={InputProps}
      id={id}
    />
  );
};

export default Textfield;