import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CommonTab from '@components/common/CommonTabs';
import { CUSTOM_MESSAGES } from '@constants';
import TableForm from '@components/common/TableForm';

const Tags = (props) => {
  const getFirstTabContent = () => {
    return (
      <>
        <Typography variant="subtitle">
          <p>{CUSTOM_MESSAGES.DATABASE1_TAG_01}</p>
          <p>{CUSTOM_MESSAGES.DATABASE1_TAG_02}</p>
          <p>{CUSTOM_MESSAGES.DATABASE1_TAG_03}</p>
        </Typography>
        {/* <Typography variant="subtitle"><p className="pt-4">{CUSTOM_MESSAGES.OBJECT_TAG_DESC}</p></Typography> */}
        <TableForm apiCallhappened={props.apiCallhappened} formItem={props.dataObject} loading={props.loadingGenericTag} />
        <br />
        {/* <Typography variant="subtitle"><p className="pt-4">{CUSTOM_MESSAGES.ORGANIZATION_TAG_DESC}</p></Typography> */}
        <TableForm apiCallhappened={props.apiCallhappened} formItem={props.dataOrganization} loading={props.loadingGenericTag} />
        <br />
        {/* <Typography variant="subtitle"><p className="pt-4">{CUSTOM_MESSAGES.METRIC_TAG_DESC}</p></Typography> */}
        <TableForm apiCallhappened={props.apiCallhappened} formItem={props.dataMetric} loading={props.loadingGenericTag} />
        <br />
        {/* <Typography variant="subtitle"><p className="pt-4">{CUSTOM_MESSAGES.DATABASE1_TAG_04}</p></Typography> */}
        <TableForm apiCallhappened={props.apiCallhappened} formItem={props.dataVideo} loading={props.loadingGenericTag} />
        <br />
        {/* <Typography variant="subtitle"><p className="pt-4">{CUSTOM_MESSAGES.DATABASE1_TAG_04}</p></Typography> */}
        <TableForm apiCallhappened={props.apiCallhappened} formItem={props.dataAudio} loading={props.loadingGenericTag} />
        <br />
        {/* <Typography variant="subtitle"><p className="pt-4">{CUSTOM_MESSAGES.DATABASE1_TAG_04}</p></Typography> */}
        <TableForm apiCallhappened={props.apiCallhappened} formItem={props.dataImage} loading={props.loadingGenericTag} />
        <br />
        <Typography variant="subtitle"><p className="pt-4">{CUSTOM_MESSAGES.DATABASE1_TAG_04}</p></Typography>
        <TableForm apiCallhappened={props.apiCallhappened} formName={props.formName} formItem={props.dataApplication} handleSubmit={props.handleSubmit} loading={props.loadingSpecificTag} onClickFinish={props.onClickFinish} />
      </>
    );
  };

  const getSecondTabContent = () => {
    return (
      <>
        <Typography variant="subtitle">
          <p>{CUSTOM_MESSAGES.DATABASE1_TAG_11}</p>
          <p>{CUSTOM_MESSAGES.DATABASE1_TAG_12}</p>
        </Typography>
        <TableForm apiCallhappened={props.apiCallhappened} formName={props.formName} formItem={props.dataCategory} handleSubmit={props.handleSubmit} loading={props.loadingCategory} onClickFinish={props.onClickFinish} />
      </>
    );
  };

  const getThirdTabContent = () => {
    return (
      <>
        <Typography variant="subtitle">
          <p>{CUSTOM_MESSAGES.DATABASE1_TAG_21}</p>
          <p>{CUSTOM_MESSAGES.DATABASE1_TAG_22}</p>
        </Typography>
        <TableForm apiCallhappened={props.apiCallhappened} formName={props.formName} formItem={props.dataChannel} handleSubmit={props.handleSubmit} loading={props.loadingChannel} onClickFinish={props.onClickFinish} />
      </>
    );
  };

  const getTabContent = () => {
    return (
      <>
        {props.activeTabId === 0 && (
          getFirstTabContent()
        )}
        {props.activeTabId === 1 && (
          getSecondTabContent()
        )}
        {props.activeTabId === 2 && (
          getThirdTabContent()
        )}
      </>
    );
  };

  return (
    <Grid container direction="row" className="px-3 py-4 mt-4">
      <Grid item xs={12} sm={12} md={12} lg={12} className="pb-3">
        <Typography variant="h4">{props.databaseName}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CommonTab
          tablist={props.tabList}
          getTabIndex={(index) => props.setActiveTab(index)}
          tabContent={getTabContent()}
          activeTab={props.activeTabId}
        />
      </Grid>
    </Grid>
  );
};

export default Tags;
