/* eslint-disable */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { VALUES, APIConfig } from '@constants';
import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import PopulateData from './component';
import database, { getDatabaseList } from '../../reducers/database';
import { uploadFileData } from '../../reducers/uploadFile';
import { getUserDetails } from '../../reducers/otp';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const ENDPOINTS = APIConfig.endpoints;
const PORTS = APIConfig.ports;
const HOSTNAME = APIConfig.hostname;

export class PopulateDatabase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isUploadInProgress: false,
      fileName: '',
      open: false,
      successMsg: '',
      failureMsg: '',
      openModal: false,
      socketConnected: false,
      progress: { count: 0, label: 'Uploading the file' },
      invalidFileUpload: '',
      databaseId: 0,
      showPDFOptions: false,
      fileList: [],
      fileIds: [],
      availableDatabaseList: [],
    };
  }

  componentDidMount() {
    const userSurrogateId = window.localStorage.getItem('userSurrogateId');
    const userEmail = window.localStorage.getItem('userEmail');
    const pathname = window.location.pathname;
    if (pathname === '/populate-pdf') {
      this.setState({ showPDFOptions: true });
    }
    this.props.getUserDetails(userEmail);
    this.props
      .getDatabaseList(userSurrogateId, 0, 0)
      .then(res => {
        let availableDatabaseList = res.responseList || [];
        let defaultDbId = 0;
        if (availableDatabaseList.length) {
          defaultDbId = res.responseList[0].id || 0;
        }
        this.setState({
          availableDatabaseList: availableDatabaseList,
          databaseId: defaultDbId,
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  handleCloseLoader = () => {
    this.setState({ isUploadInProgress: false });
  };

  handleToggle = () => {
    this.setState({ isUploadInProgress: false });
  };

  handleClickOpen = () => {
    this.setState({ openModal: true });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  };

  /* currentFile = e => {
    var validExts = new Array('.xlsx', '.xls', '.csv', '.pdf');
    var fileExt = e.target.value;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
    if (validExts.indexOf(fileExt) < 0) {
      this.setState({
        invalidFileUpload:
          'Invalid file selected, valid files are of ' +
          validExts.toString() +
          ' types.',
      });
      // return false;
    } else {
      // return true;
      this.setState({ invalidFileUpload: '', fileName: e.target.files[0] });
    }
  }; */

  /* handleFileRemove = e => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ fileName: '' });
  }; */

  successOfUpload = data => {
    // this.handleClick();
    // this.handleCloseModal();
    // this.setState({ fileName: '', successMsg: data.message, isUploadInProgress: false });
    this.setState({
      // fileName: '',
      isUploadInProgress: false,
      progress: { count: 0, label: 'File Uploading' },
    });
  };

  failureOfUpload = data => {
    this.setState({
      // fileName: '',
      failureMsg: data.errorMessage,
      isUploadInProgress: false,
      progress: { count: 0, label: 'File Uploading' },
    });
  };

  uploadFileData = (values, apiKey, uploadType, urls, jsonPath = null) => {
    return new Promise((resolve, reject) => {
      try {
        /* const name = values.name
          ? values.name
          : this.props.availableDatabases.responseList[0].name;
        const templateType = values.templateType
          ? values.templateType
          : VALUES.TEMPLATE_TYPES[0]; */
        this.handleClickOpen();
        const formData = new FormData();
        if (this.state.fileList && this.state.fileList.length) {
          this.state.fileList.forEach(file => {
            formData.append('files', file);
          });
        }
        formData.append('databaseId', this.state.databaseId);
        formData.append('apiKey', apiKey);
        if (uploadType) {
          formData.append('uploadType', uploadType);
        }
        if (urls && urls.length) {
          formData.append('urls', urls);
        }
        if (jsonPath) {
          formData.append('extractJsonPath', jsonPath);
        }

        console.log('formData: ', formData);

        this.props
          .uploadFileData(formData)
          .then(data => {
            this.setState(
              {
                fileIds: data.fileIds,
              },
              () => {
                return resolve(data.fileIds || []);
              }
            );
          })
          .catch(data => {
            this.failureOfUpload(data);
            return resolve('failure');
          });
      } catch (error) {
        this.failureOfUpload({});
        return resolve('failure');
      }
    });
  };

  handleSubmit = (values, uploadType, urls, jsonPath = null) => {
    // alert(values);
    this.setState({ isUploadInProgress: true });
    const apiKey = localStorage.getItem('apiKey');
    var socket = new SockJS(`${HOSTNAME}/berrydb/socket`);
    var stompClient = Stomp.over(socket);
    stompClient.connect(
      {},
      frame => {
        this.setState(
          {
            socketConnected: true,
            progress: { ...this.state.progress, count: 10 },
          },
          () => {
            this.uploadFileData(values, apiKey, uploadType, urls, jsonPath)
              .then(fileIds => {
                const obj = {
                  fileIds: fileIds,
                  userEmail: this.props.userDetails.email,
                  databaseId: this.state.databaseId.toString(),
                  apiKey: apiKey,
                };
                if (uploadType) {
                  obj['uploadType'] = uploadType;
                }
                if (urls && urls.length) {
                  obj['urls'] = urls;
                }
                if (jsonPath) {
                  obj['extractJsonPath'] = jsonPath;
                }
                const myJson = JSON.stringify(obj);
                stompClient.send(ENDPOINTS.storeFileData, {}, myJson);
                this.clearAllFiles();
              })
              .catch(data => {
                this.failureOfUpload(data);
              });
          }
        );
        console.log('Connected: ' + frame);
        stompClient.subscribe(ENDPOINTS.subscribeStomp, greeting => {
          if (greeting.body.indexOf('statusCode') === -1) {
            let completedMessage = 'Processing done and upload completed!';
            const showDialogStartsWith = 'SHOW_DIALOG:';
            const endConnection = 'END_CONNECTION:';
            if (
              // this.state.fileId !==
              greeting.body &&
              this.state.progress.count < 100
            ) {
              if (greeting.body && greeting.body.startsWith(endConnection)) {
                console.log('greeting.body.startsWith(endConnection): ', true);
                const msg = greeting.body.split(endConnection)[1];
                console.log('greeting.body.split(endConnection)[1]: ', msg);
                this.setState({
                  progress: {
                    count: 100,
                    label: msg,
                  },
                });
                setTimeout(() => {
                  stompClient.disconnect();
                }, 2000);
              }
              this.setState(prevState => ({
                progress: {
                  count:
                    greeting.body === completedMessage ||
                    greeting.body === endConnection
                      ? 100
                      : prevState.progress.count + 10,
                  label: greeting.body,
                },
              }));
            } else {
              if (this.state.isUploadInProgress) {
                setTimeout(() => {
                  this.successOfUpload();
                  stompClient.disconnect();
                }, 2000);
              }
            }
            if (
              greeting.body &&
              greeting.body.startsWith(showDialogStartsWith)
            ) {
              console.log(
                'greeting.body.startsWith(showDialogStartsWith): ',
                true
              );
              const msg = greeting.body.split(showDialogStartsWith)[1];
              console.log(
                'greeting.body.split(showDialogStartsWith)[1]: ',
                msg
              );
              this.setState({
                progress: { count: 100 },
                successMsg: msg,
                open: true,
              });
            }
          } else {
            const errMsg = JSON.parse(greeting.body);
            this.handleClick();
            this.failureOfUpload({ errorMessage: errMsg.body.errorMessage });
            stompClient.disconnect();
          }
        });
      },
      err => {
        if (err) {
          this.setState({
            isUploadInProgress: false,
          });
        }
      }
    );
  };

  handleDatabaseId = value => {
    this.setState({ databaseId: value });
  };

  handleFileUploads = event => {
    if (!(event.target && event.target.files && event.target.files.length)) {
      this.setState({ failureMsg: 'Select a file', open: true });
      return;
    }
    event.preventDefault();
    const files = [...event.target.files];
    if (!files.length) {
      this.setState({ failureMsg: 'Select a file', open: true });
      return;
    }
    const origFileList = [...(this.state.fileList || [])];
    if (origFileList.length + files.length > 5) {
      this.setState({
        failureMsg: 'A max of 5 files can be uploaded in total',
        open: true,
      });
      return;
    }
    this.setState({ fileList: [...origFileList, ...files] });
    console.log('fileList: ', origFileList);
  };

  clearAllFiles = () => {
    this.setState({ fileList: null, fileIds: null });
  };

  removeFileByIndex = index => {
    if (!(index > -1)) {
      return;
    }
    const origFileList = [...(this.state.fileList || [])];
    if (!(origFileList.length && origFileList.length > index)) {
      return;
    }
    origFileList.splice(index, 1);
    this.setState({ fileList: [...origFileList] });
  };

  render() {
    return (
      <div>
        <PopulateData
          availableDatabaseList={this.state.availableDatabaseList}
          isLoading={this.state.isLoading}
          handleSubmit={this.handleSubmit}
          fileName={this.state.fileName}
          handleClose={this.handleClose}
          open={this.state.open}
          successMsg={this.state.successMsg}
          isUploadInProgress={this.state.isUploadInProgress}
          openModal={this.state.openModal}
          handleCloseModal={this.handleCloseModal}
          handleCloseLoader={this.handleCloseLoader}
          failureMsg={this.state.failureMsg}
          progress={this.state.progress}
          invalidFileUpload={this.state.invalidFileUpload}
          ports={PORTS.berrydb}
          hostname={HOSTNAME}
          handleDatabaseId={this.handleDatabaseId}
          showPDFOptions={this.state.showPDFOptions}
          fileList={this.state.fileList}
          fileIds={this.state.fileIds}
          handleFileUploads={this.handleFileUploads}
          removeFileByIndex={this.removeFileByIndex}
          clearAllFiles={this.clearAllFiles}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userDetails: state.otp.userDetailsOnLoad.userDetails,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUserDetails,
      getDatabaseList,
      uploadFileData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PopulateDatabase);
