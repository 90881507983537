/* eslint-disable */
import React, { useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@mui/material/styles';
 
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
 PaperProps: {
   style: {
     maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
     width: 250,
   },
 },
};
 
function getStyles(name, personName, theme) {
 return {
   fontWeight:
       personName.indexOf(name) === -1
         ? theme.typography.fontWeightRegular
         : theme.typography.fontWeightMedium,
 };
}
 
const SelectComponent = (props) => {
 const [primaryValue, setValue] = useState([]);
 const theme = useTheme();
 
 const handleChange = (event) => {
   const {
     target: { value },
   } = event;
   setValue(
     // On autofill we get a the stringified value.
     typeof value === 'string' ? value.split(',') : value,
   );
 };
 
 return (
   <Select
     id={props.name}
     name={props.name}
     displayEmpty
     value={primaryValue}
     onChange={handleChange}
     variant="outlined"
     renderValue={(selected) => {
       if (selected.length === 0) {
         return <Typography>{props.placeHolder}</Typography>;
       }
 
       return selected;
     }}
     MenuProps={MenuProps}
     inputProps={{ 'aria-label': 'Without label' }}
   >
     <MenuItem disabled value="">
       <em>{props.placeHolder}</em>
     </MenuItem>
     {props.list.map((item) => (
       <MenuItem
         key={item}
         value={item}
         style={getStyles(item, primaryValue, theme)}
       >
         {item}
       </MenuItem>
     ))}
   </Select>
 );
};
 
export default SelectComponent;
