export const GET_SEARCH_DATA_REQUESTED = 'berrydb/GET_SEARCH_DATA_REQUESTED';
export const GET_SEARCH_DATA_SUCCESS = 'berrydb/GET_SEARCH_DATA_SUCCESS';
export const GET_SEARCH_DATA_FAILURE = 'berrydb/GET_SEARCH_DATA_FAILURE';
export const SET_DATABASE_NAME = 'berrydb/SET_DATABASE_NAME';
export const SET_SEARCH_KEY = 'berrydb/SET_SEARCH_KEY';
export const SET_DATABSE_ID = 'berrydb/SET_DATABSE_ID';
export const SET_SCHEMA_ID = 'berrydb/SET_SCHEMA_ID';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  dbName: '',
  searchKey: '',
  sentDbId: '',
  sentSchemaId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SEARCH_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_SEARCH_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_SEARCH_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case SET_DATABASE_NAME: {
      return {
        ...state,
        dbName: action.payload
      };
    }
    case SET_SEARCH_KEY: {
      return {
        ...state,
        searchKey: action.payload
      };
    }
    case SET_DATABSE_ID: {
      return {
        ...state,
        sentDbId: action.payload
      };
    }
    case SET_SCHEMA_ID: {
      return {
        ...state,
        sentSchemaId: action.payload
      };
    }
    default:
      return state;
  }
};

export const globalDbSearch = (orgName, databaseId, searchKey) => {
  return {
    types: [GET_SEARCH_DATA_REQUESTED, GET_SEARCH_DATA_SUCCESS, GET_SEARCH_DATA_FAILURE],
    promise: (client) => client.get(`globalDbSearch?orgName=${orgName}&databaseId=${databaseId}&search=${searchKey}`)
  };
};

export const setDbName = (payload) => {
  return {
    type: SET_DATABASE_NAME,
    payload
  };
};

export const setSearchKey = (payload) => {
  return {
    type: SET_SEARCH_KEY,
    payload
  };
};

export const setDbId = (payload) => {
  return {
    type: SET_DATABSE_ID,
    payload
  };
};

export const setSchemaId = (payload) => {
  return {
    type: SET_SCHEMA_ID,
    payload
  };
};
