/*eslint-disable*/
export const GET_SCHEMA_DETAILS_REQUESTED =
  'berrydb/GET_SCHEMA_DETAILS_REQUESTED';
export const GET_SCHEMA_DETAILS_SUCCESS = 'berrydb/GET_SCHEMA_DETAILS_SUCCESS';
export const GET_SCHEMA_DETAILS_FAILURE = 'berrydb/GET_SCHEMA_DETAILS_FAILURE';

export const CREATE_SCHEMA_METADATA_REQUESTED =
  'profile/CREATE_SCHEMA_METADATA_REQUESTED';
export const CREATE_SCHEMA_METADATA_SUCCESS =
  'profile/CREATE_SCHEMA_METADATA_SUCCESS';
export const CREATE_SCHEMA_METADATA_FAILURE =
  'profile/CREATE_SCHEMA_METADATA_FAILURE';

export const SAVE_SCHEMA_DETAILS_REQUESTED =
  'berrydb/SAVE_SCHEMA_DETAILS_REQUESTED';
export const SAVE_SCHEMA_DETAILS_SUCCESS =
  'berrydb/SAVE_SCHEMA_DETAILS_SUCCESS';
export const SAVE_SCHEMA_DETAILS_FAILURE =
  'berrydb/SAVE_SCHEMA_DETAILS_FAILURE';

const initialState = {
  schemaDetails: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHEMA_DETAILS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SCHEMA_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        schemaDetails: action.result.responseList,
      };
    }
    case GET_SCHEMA_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case CREATE_SCHEMA_METADATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CREATE_SCHEMA_METADATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case CREATE_SCHEMA_METADATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }

    case SAVE_SCHEMA_DETAILS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_SCHEMA_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_SCHEMA_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }

    default:
      return state;
  }
};

export const getSchemaDetails = schemaId => {
  return {
    types: [
      GET_SCHEMA_DETAILS_REQUESTED,
      GET_SCHEMA_DETAILS_SUCCESS,
      GET_SCHEMA_DETAILS_FAILURE,
    ],
    promise: client => client.get(`getSchemaDetails?schemaId=${schemaId}`, {}),
  };
};

export const createSchemaMetadata = formData => {
  return {
    types: [
      CREATE_SCHEMA_METADATA_REQUESTED,
      CREATE_SCHEMA_METADATA_SUCCESS,
      CREATE_SCHEMA_METADATA_FAILURE,
    ],
    promise: client =>
      client.post('createSchemaMetadata', {
        data: formData,
      }),
  };
};

export const updateSchemaMetadata = (schemaId, formData) => {
  return {
    types: [
      CREATE_SCHEMA_METADATA_REQUESTED,
      CREATE_SCHEMA_METADATA_SUCCESS,
      CREATE_SCHEMA_METADATA_FAILURE,
    ],
    promise: client =>
      client.put(`createSchemaMetadata/${schemaId}`, {
        data: formData,
      }),
  };
};

export const saveSchemaDetails = formData => {
  return {
    types: [
      SAVE_SCHEMA_DETAILS_REQUESTED,
      SAVE_SCHEMA_DETAILS_SUCCESS,
      SAVE_SCHEMA_DETAILS_FAILURE,
    ],
    promise: client =>
      client.post('saveSchemaDetails', {
        data: formData,
      }),
  };
};

export const fetchSchemaMetadataById = (schemaId, userId) => {
  return {
    types: [
      SAVE_SCHEMA_DETAILS_REQUESTED,
      SAVE_SCHEMA_DETAILS_SUCCESS,
      SAVE_SCHEMA_DETAILS_FAILURE,
    ],
    promise: client =>
      client.get(`fetchSchemaMetadataById/${schemaId}?userId=${userId}`),
  };
};
