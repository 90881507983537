import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { dataSlice } from '../features/data/dataSlice';
import { textareaSlice } from '../features/textarea/textareaSlice';
import chat from './chat';
import database from './database';
import { updateDocuments } from './documents';
import localization from './localization';
import oauth from './oauth';
import otp from './otp';
import password from './password';
import query from './query';
import { register } from './register';
import schema from './schema';
import search from './search';
import sqlEditor from './sqlEditor';
import tags from './tags';
import ui from './ui';
import upload from './uploadFile';

const appReducer = combineReducers({
  router: routerReducer,
  otp,
  oauth,
  password,
  localization,
  register,
  ui,
  database,
  tags,
  upload,
  query,
  search,
  sqlEditor,
  schema,
  chat,
  updateDocuments,
  data: dataSlice.reducer,
  textarea: textareaSlice.reducer,
  form: formReducer
});

// Setup root reducer
const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
