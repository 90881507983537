/* eslint-disable */
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const NotificationMessage = props => {
  const [state, setState] = React.useState({
    vertical: 'bottom',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  const Alert = React.forwardRef((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={props.open}
      autoHideDuration={props.showDuration || 10000}
      onClose={props.handleClose}
    >
      <Alert
        onClose={props.handleClose}
        severity={props.msgType}
        sx={{ width: '100%' }}
      >
        {props.msg}
      </Alert>
    </Snackbar>
  );
};

export default NotificationMessage;
