/* eslint-disable */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ObjectType from '../object/ObjectType';
import { textareaSlice } from '../features/textarea/textareaSlice';
import { dataSlice } from '../features/data/dataSlice';

const VisualizedData = props => {
  const data = useSelector(state => state.data.data);
  const text = useSelector(state => state.textarea.localText);
  console.log('props.json:', props.json);

  const schemaDataTypes = useSelector(state => state.schema.newSchemaDataTypes);

  const ftsfilter = useSelector(state => {
    return (state.data && state.data.ftsfilter) || [];
  });
  const filter = useSelector(state => (state.data && state.data.filter) || []);
  /* const newSchemaDataTypes = useSelector(
    (state) => state.data && state.data.schemaDataTypes
  ); */

  // const ftsfilter = props.schemaDetails && props.schemaDetails.indexFields;
  // const filter = props.schemaDetails && props.schemaDetails.filterFields;
  const newSchemaDataTypes =
    props.schemaDetails && props.schemaDetails.schemaDataTypes;

  const { setLocalText } = textareaSlice.actions;
  const dispatch = useDispatch();
  const {
    setInitialFilter,
    setNewSchemaDataTypes,
    setAlterSchemaDataTypes,
    setEditMode,
  } = dataSlice.actions;

  useEffect(() => {
    dispatch(setLocalText(JSON.stringify(data, null, 4)));
    dispatch(setAlterSchemaDataTypes({ newSchemaDataTypes, data }));
    dispatch(setEditMode(null));
  }, []);

  useEffect(() => {
    dispatch(setNewSchemaDataTypes({ newSchemaDataTypes, data }));
    // console.log('data :');
    // console.log(data);
  }, [data, newSchemaDataTypes]);

  /* useEffect(() => {
    dispatch(
      setInitialFilter({ filterValues: filter, ftsFilterValues: ftsfilter })
    );
  }, [newSchemaDataTypes]); */

  useEffect(() => {
    dispatch(setLocalText(JSON.stringify(data, null, 4)));
    // console.log(data);
  }, [data]);

  return (
    <div className="visualized-data">
      {text && text.length > 0 ? (
        <ObjectType
          data={data}
          path={[]}
          schemaDataType={schemaDataTypes}
          hideFilters={props.hideFilters}
          schemaInputTypeList={props.schemaInputTypeList}
          getTypeRawJson={props.getTypeRawJson}
          fetchAllEmbeddedSchemaTypes={props.fetchAllEmbeddedSchemaTypes}
          pipelinesExtractTypes={props.pipelinesExtractTypes}
          getPipelinesExtractTypes={props.getPipelinesExtractTypes}
          pipelineFields={props.pipelineFields}
          handleAttachPipeline={props.handleAttachPipeline}
          handleRemovePipeline={props.handleRemovePipeline}
        />
      ) : (
        <div className="placeholder p-2">No data</div>
      )}
    </div>
  );
};

export default VisualizedData;
