// Pages
/* eslint-disable */
// Icons
import CloudUpload from '@material-ui/icons/CloudUpload';
import Dashboard from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import Storage from '@material-ui/icons/Storage';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ChatIcon from '@mui/icons-material/Chat';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
// import NoteOutlined from '@material-ui/icons/NoteOutlined';
import LocalOffer from '@material-ui/icons/LocalOffer';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { lazy } from 'react';
import { Usage } from './pages';

import { Home, Widgets } from './pages';

export default {
  items: [
    {
      path: '/Dashboard',
      name: 'Home',
      type: 'link',
      icon: Dashboard,
      component: Home,
    },
    {
      path: '/Schemas',
      name: 'Schemas',
      type: 'link',
      icon: AccountTreeIcon,
      component: lazy(() => import('./components/Schemas/Schemas')),
    },
    {
      path: '/Database',
      name: 'Database',
      type: 'link',
      icon: Storage,
      component: lazy(() => import('./components/Database/Database')),
    },
    {
      path: '/populate',
      name: 'Populate',
      type: 'link',
      icon: CloudUpload,
      component: Widgets,
    },
    {
      path: '/search',
      name: 'Filter',
      type: 'link',
      icon: SearchIcon,
      component: lazy(() => import('./components/Filter')),
    },
    /*
    {
      path: '/query',
      name: 'Query',
      type: 'link',
      icon: ManageSearchIcon,
      component: lazy(() => import('@components/SqlEditor')),
    },
    {
      path: '/annotation-filter',
      name: 'Annotation Filters',
      type: 'link',
      icon: FilterListIcon,
      component: lazy(() => import('@components/AnnotationFilter')),
    }
  */
    {
      path: '/annotation',
      name: 'Annotation',
      type: 'link',
      icon: LocalOffer,
      component: lazy(() => import('./components/Filter')),
    },
    {
      path: '/annotation-filter',
      name: 'Query',
      type: 'link',
      icon: ManageSearchIcon,
      component: lazy(() => import('@components/AnnotationFilter')),
    },
    {
      path: '/chat',
      name: 'BerryGPT',
      type: 'link',
      icon: ChatIcon,
      component: lazy(() => import('@components/ChatWindow')),
    },
    {
      path: '/notepad',
      name: 'API Examples',
      type: 'link',
      icon: NoteAltIcon,
      component: lazy(() => import('@components/Notepad')),
    },
    {
      path: '/ml-models',
      name: 'ML Models',
      type: 'link',
      icon: DeviceHubOutlinedIcon,
      component: lazy(() => import('@components/MlModels')),
    },
    // {
    //   path: '/Usage',
    //   name: 'Usage',
    //   type: 'link',
    //   icon: AnalyticsIcon,
    //   component: Usage,
    // },
    {
      path: '/settings',
      name: 'Settings',
      type: 'link',
      icon: SettingsIcon,
      component: lazy(() => import('@components/Settings')),
    },
  ],
};
