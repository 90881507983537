/* eslint-disable */
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ShareIcon from '@material-ui/icons/Share';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { formatPrice } from '../../helpers';
import dummyImage from '@assets/images/default-image.png';
import cn from 'classnames';
import { countUploadTime, ellipsis } from '../../helpers/utils';
import { withRouter } from 'react-router';
import Box from '@mui/material/Box';
import { padding } from '@mui/system';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonGroup from '@mui/material/ButtonGroup';
import moment from 'moment-timezone';

const useStyles = makeStyles(theme => ({
  mediaGrid: {
    textAlign: 'center',
    overflow: 'hidden',
  },
  mediaGridCompact: {
    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
  },
  figure: {
    position: 'relative',
    // cursor: 'pointer',
    // background: 'rgba(0,0,0,.38)',
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    textAlign: 'center',
    '&:hover $captionAnchor': {
      transform: 'translate3d(0,0,0)',
      opacity: 1,
    },
    '&:hover $caption::before': {
      transform: 'translate3d(0,0,0)',
    },
  },
  figureCompact: {
    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
  },
  figureImg: {
    position: 'relative',
    display: 'block',
    maxWidth: '100%',
    height: '114px',
    // borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    transition: 'opacity 300ms, transform 300ms',
    opacity: 0.85,
    objectFit: 'contain',
    // width: '100%',
    padding: '5px',
    // [theme.breakpoints.up(1600)]: {
    //   height: '140px'
    // },
    [theme.breakpoints.up(1900)]: {
      height: '180px',
    },
  },
  figureImgCompact: {
    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
  },
  // badge: {
  //   color: theme.palette.primary.contrastText,
  //   textAlign: 'center',
  //   position: 'absolute',
  //   borderTop: `30px solid ${theme.palette.error.main}`,
  //   borderBottom: '30px solid transparent',
  //   borderLeft: '30px solid transparent',
  //   borderRight: `30px solid ${theme.palette.error.main}`,
  //   top: 0,
  //   right: 0,
  //   zIndex: 100
  // },
  moreMenu: {
    // color: theme.palette.primary.contrastText,
    position: 'absolute',
    // width: '25px',
    // height: '25px',
    // borderRadius: '50%',
    // backgroundColor: '#3f51b5',
    top: 0,
    right: 0,
    zIndex: 100,
    margin: '3px',
    display: 'flex',
    justifyContent: 'center',
    // cursor: 'pointer'
  },
  badge: {
    color: theme.palette.primary.contrastText,
    position: 'absolute',
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    backgroundColor: '#3f51b5',
    bottom: 0,
    right: 0,
    zIndex: 100,
    margin: '3px',
    display: 'flex',
    justifyContent: 'center',
  },
  badgeText: {
    position: 'absolute',
    // transform: 'rotate(-45deg)',
    top: '3px',
    // right: '9px',
    // left: '-25px',
    whiteSpace: 'nowrap',
    color: '#fff',
    fontSize: '13px',
    fontWeight: '500',
  },
  caption: {
    color: theme.palette.primary.contrastText,
    textTransform: 'uppercase',
    backfaceVisibility: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    '&::before': {
      pointerEvents: 'none',
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderStyle: 'solid',
      borderColor: 'rgba(0,0,0,.38)',
      content: "''",
      transition: 'transform 300ms',
      right: 0,
      bottom: 0,
      borderWidth: '0 45px 0 0',
      transform: 'translate3d(45px,0,0)',
    },
  },
  captionAnchor: {
    position: 'relative',
    transition: 'opacity 300ms, transform 300ms',
    display: 'block',
    color: theme.palette.primary.contrastText,
    transform: 'translate3d(90px,0,0)',
    margin: `${theme.spacing(1)}px 0`,
    '&:first-child': {
      transitionDelay: '0.025s',
    },
    '&:nth-child(2)': {
      transitionDelay: '0.05s',
    },
    '&:nth-child(3)': {
      transitionDelay: '0.075s',
    },
    '&:nth-child(4)': {
      transitionDelay: ' 0.1s',
    },
  },
  paragraph: {
    margin: 0,
    float: 'right',
    clear: 'both',
    textAlign: 'center',
    textTransform: 'none',
    fontSize: '1rem',
    width: '45px',
    position: 'relative',
  },
  price: {
    display: 'flex',
    alignItems: 'baseline',
  },
  mainPrice: {
    color: '#3f51b5',
    fontSize: '18px',
  },
  itemCard: {
    boxShadow: theme.shadows[4],
    height: '100%',
  },
  cardContent: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    // cursor: 'pointer',
    // background: 'rgba(0,0,0,.38)',
    // borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    // textAlign: 'center',
  },
  twoLineTextWrap: {
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    // whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: 'calc(23px * 2)',
    overflowWrap: 'break-word',
  },
}));

const ProductCardCompact = props => {
  const {
    id,
    dbId,
    threedots,
    name,
    price,
    description,
    sale,
    discounted,
    discount,
    rating,
    thumbnail,
    displayImageField,
    databaseName,
    uploadedTime,
    url,
    dHash,
    onClick,
    type,
  } = props;

  const classes = useStyles();
  const stars = [1, 2, 3, 4, 5];
  const [ellipsisChar, setEllipsisChar] = useState('');

  useEffect(() => {
    if (window.innerWidth > 1600) {
      setEllipsisChar(20);
    } else if (window.innerWidth > 1900) {
      setEllipsisChar(40);
    } else {
      setEllipsisChar(20);
    }
  }, []);

  const redirectTo = (name, id) => {
    props.history.push(`/detail/${name}/${id}`);
  };

  const itemPic = displayImageField || thumbnail;
  return (
    <Card className={classes.itemCard}>
      <Grid
        container
        className={dbId && 'cursor'}
        onClick={() => {
          redirectTo(dbId, id);
          props.onClick;
        }}
      >
        <Grid item xs={5}>
          <div className={classes.mediaGrid}>
            <div className={classes.figure}>
              <img
                alt={name}
                // src={`${process.env.PUBLIC_URL}/unsplash/${id}.jpg`}
                src={itemPic ? itemPic : type === 'image' ? url : dummyImage}
                className={classes.figureImg}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={7} className="d-flex">
          <div className={classes.cardContent}>
            {/* {(
            <div className={classes.moreMenu}>
            <IconButton size="small">
            <MoreVertIcon color="action" fontSize="small" />
            </IconButton>
            </div>
        )} */}
            {
              <PopupState variant="popover" popupId="demo-popup-popover">
                {popupState => (
                  <div>
                    {threedots ? (
                      <div>
                        {/* <Button variant="contained" {...bindTrigger(popupState)}>
                Open Popover
              </Button> */}

                        <div
                          className={classes.moreMenu}
                          {...bindTrigger(popupState)}
                        >
                          <IconButton size="small">
                            <MoreVertIcon color="action" fontSize="small" />
                          </IconButton>
                        </div>
                        <Popover
                          {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <ButtonGroup
                            orientation="vertical"
                            aria-label="vertical outlined button group"
                          >
                            <Button
                              onClick={() => {
                                props.onClickSimilar(dHash);
                                popupState.close();
                              }}
                            >
                              Similar
                            </Button>
                            {/* <Button onClick={() => { props.onDeleteItem(id); popupState.close(); }} startIcon={<DeleteIcon />} color="secondary">
                            Delete
                          </Button> */}
                          </ButtonGroup>
                        </Popover>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                )}
              </PopupState>
            }
            {type && typeof type === 'string' && (
              <div className={classes.badge}>
                <Typography className={classes.badgeText}>
                  {type.charAt(0).toUpperCase()}
                </Typography>
              </div>
            )}
            <CardContent className="px-2 py-1 d-flex" style={{ width: '100%' }}>
              <Grid
                container
                spacing={0}
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item style={{ width: '100%' }}>
                  {rating && (
                    <Typography variant="caption">
                      {stars.map(el => {
                        return (
                          <span
                            className={cn(
                              el <= rating ? 'text-default' : 'text-muted'
                            )}
                          >
                            &#9733;
                          </span>
                        );
                      })}
                    </Typography>
                  )}
                  <Typography className={classes.twoLineTextWrap}>
                    {name}
                  </Typography>
                  {/* <Typography className="text-muted" variant="caption">{countUploadTime(uploadedTime)}</Typography> */}
                  {price && (
                    <Typography className={classNames(classes.mainPrice)}>
                      {' '}
                      {formatPrice(price)}
                    </Typography>
                  )}

                  <Typography variant="caption">
                    {ellipsis(props.description, ellipsisChar)}
                  </Typography>
                  <Typography className="grayFont" style={{ fontSize: 11 }}>
                    {moment
                      .utc(uploadedTime)
                      .tz(moment.tz.guess(true))
                      .format('ll')}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

ProductCardCompact.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  price: PropTypes.number,
  description: PropTypes.string,
  sale: PropTypes.bool,
  discounted: PropTypes.bool,
  discount: PropTypes.number,
};

export default withRouter(ProductCardCompact);
