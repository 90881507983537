import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [disable, setDisable] = React.useState(false);

  React.useEffect(() => {
    setOpen(props.showAlertForDelete);
    setDisable(false);
  }, [props.showAlertForDelete]);

  const handleClose = () => {
    props.setOpen();
  };

  const closeModel = () => {
    setOpen(false);
    setDisable(true);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          Delete Database
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`It will delete all the contents related to ${props.dbToDelete}. Are you sure ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} autoFocus>Cancel</Button>
          <Button disabled={disable} variant="contained" onClick={() => { props.onDeleteItem(); closeModel(); }}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
