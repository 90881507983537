/* eslint-disable */
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MonacoEditor from 'react-monaco-editor';
import ControlsArea from './ControlsArea';
import { RootState } from './index';
import { dataSlice } from './features/data/dataSlice';
import { textareaSlice } from './features/textarea/textareaSlice';
import {
  createSchemaMetadata,
  updateSchemaMetadata,
  fetchSchemaMetadataById,
} from './reducers/schema';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';

const tabSize = 4;
const localStorageKey = 'json-text';

const TextArea = props => {
  const dispatch = useDispatch();
  // check here
  const data = useSelector(
    state =>
      state.schema.schemaDetails[0] && state.schema.schemaDetails[0].rawSchema
  );
  const newData = useSelector(state => state.data.data);
  // const dataTypes = useSelector(state => state.schema.schemaDetails[0] && state.schema.schemaDetails[0].schemaDataTypes);
  const dataTypes = useSelector(state => state.data.newSchemaDataTypes);
  const displayTextFieldPath = useSelector(
    state => state.data.displayTextField
  );
  const displayImageFieldPath = useSelector(
    state => state.data.displayImageField
  );
  const saveDocumentHandler = useSelector(
    state => state.data.saveDocumentHandler
  );

  // const ftsfilter = useSelector((state) => state.schema.schemaDetails[0] && state.schema.schemaDetails[0].indexFields);
  // const filter = useSelector((state) => state.schema.schemaDetails[0] && state.schema.schemaDetails[0].filterFields);

  const ftsfilter = useSelector(state => state.data.ftsfilter);
  const filter = useSelector(state => state.data.filter);

  const text = useMemo(() => {
    if (!window.location.pathname.split('/')[2])
      return JSON.stringify({}, null, tabSize);
    return data === null ? '' : JSON.stringify(data, null, tabSize);
  }, [data]);

  const [timeoutId, setTimeoutId] = useState(setTimeout(() => {}, 0));

  const { setData, setInitialFilter } = dataSlice.actions;
  const [openModal, setModal] = useState(false);
  const [notifyOpen, setNotifyOpen] = useState(false);
  const [notifyText, handleNotifyText] = useState();

  const localText = useSelector(state => state.textarea.localText);
  const { setLocalText } = textareaSlice.actions;

  // if (window.location.pathname.split('/')[1] === "schemaDetails" && typeof window.location.pathname.split('/')[2]==="undefined"){
  //   dispatch(setData(null));
  //   dispatch(setLocalText(JSON.stringify({}, null, tabSize)));
  // }
  // return JSON.stringify({}, null, tabSize);

  // // initialize
  // useEffect(() => {
  //   // load from local storage
  //   // const jsonText = localStorage.getItem(localStorageKey);
  //   if (false) {
  //     const data = JSON.parse(jsonText);
  //     dispatch(setData(data));
  //     dispatch(setInitialFilter({"filterValues":filter,"ftsFilterValues":ftsfilter}))
  //   }
  // }, [text, dispatch, setData,filter,ftsfilter]);

  // state to local
  useEffect(() => {
    if (data) {
      dispatch(setLocalText(text));
    } else {
      // loadEmptySchema;
    }
  }, [data]);

  useEffect(() => {
    if (!window.location.pathname.split('/')[2]) {
      dispatch(setInitialFilter({ filterValues: [], ftsFilterValues: [] }));
    }
  }, []);

  useEffect(() => {
    if (newData > 0) dispatch(setLocalText(JSON.stringify(newData, null, 4)));
  }, [newData]);

  // local to state
  useEffect(() => {
    clearTimeout(timeoutId);
    const id = setTimeout(() => {
      try {
        const data = localText.length > 0 ? JSON.parse(localText) : null;
        dispatch(setData(data));
        // Save to local storage
        localStorage.setItem(localStorageKey, localText);
      } catch {}
    }, 1000);
    setTimeoutId(id);
  }, [localText]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadEmptySchema = useCallback(() => {
    dispatch(setData(null));
    dispatch(setLocalText(''));
  }, [text, data, dispatch, setData, setLocalText]);

  // update local text
  const onChange = useCallback(
    newValue => {
      dispatch(setLocalText(newValue));
    },
    [dispatch, localText]
  );

  // for updating layout of the editor
  const isDragging = useSelector(state => state.textarea.isDragging);
  const editor = useRef(null);
  useEffect(() => {
    if (!isDragging) {
      editor.current?.editor?.layout();
    }
  }, [isDragging, editor]);

  const handleModalOpen = () => {
    setModal(true);
  };

  const handleSubmit = (data, isEditMode = false) => {
    const userSurrogateId = window.localStorage.getItem('userSurrogateId');
    const userEmail = window.localStorage.getItem('userEmail');

    console.log('handleSubmit data: ', data);

    if (data.schemaName && data.schemaDescription) {
      const schemaJsonData = {
        schemaName: data.schemaName,
        userId: userSurrogateId,
        userEmail: userEmail,
        rawSchema: newData,
        schemaDataTypes: dataTypes,
        filterFields:
          filter && filter.length
            ? filter.map(ft => ft.replace(/.0/g, '[0]'))
            : [],
        indexFields:
          ftsfilter && ftsfilter.length
            ? ftsfilter.map(fts => fts.replace(/.0/g, '[0]'))
            : [],
        embeddedType: data.embeddedType,
      };
      if (displayTextFieldPath) {
        schemaJsonData['displayTextField'] = displayTextFieldPath;
      }
      if (displayImageFieldPath) {
        schemaJsonData['displayImageField'] = displayImageFieldPath;
      }
      if (props.pipelineFields) {
        schemaJsonData['pipelineFields'] = props.pipelineFields;
        const apiKey = window.localStorage.getItem('apiKey');
        schemaJsonData['apiKey'] = apiKey;
      }
      const jsonData = {
        userId: userSurrogateId,
        name: data.schemaName,
        description: data.schemaDescription,
        embeddedType: data.embeddedType,
        details: schemaJsonData,
      };
      if (isEditMode) {
        props.updateSchemaMetadata(data.schemaId, jsonData).then(response => {
          props.handleSuccessNotification(response.message);
          setTimeout(() => {
            props.history.push('/Schemas');
          }, 500);
        });
      } else {
        props.createSchemaMetadata(jsonData).then(response => {
          props.handleSuccessNotification(response.message);
          setTimeout(() => {
            props.history.push('/Schemas');
          }, 500);
        });
      }
      handleClose();
    } else {
      handleNotifyOpen();
      if (
        typeof data.schemaName === 'undefined' &&
        typeof data.schemaDescription === 'undefined'
      )
        handleNotifyText('Schema Name and Schema Description');
      else if (typeof data.schemaName === 'undefined')
        handleNotifyText('Schema Name');
      else if (typeof data.schemaDescription === 'undefined')
        handleNotifyText('Schema Description');
    }
  };

  const fetchSchemaMetadataById = async schemaId => {
    const userId = localStorage.getItem('userSurrogateId');
    const res = await props.fetchSchemaMetadataById(schemaId, userId);
    return res;
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleNotifyClose = () => {
    setNotifyOpen(false);
  };

  const handleNotifyOpen = () => {
    setNotifyOpen(true);
  };

  const renderNotifyBlank = () => {
    return (
      <Dialog
        open={notifyOpen}
        onClose={handleNotifyClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Save schema could not be completed
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please enter <b>{notifyText}</b>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNotifyClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const formatJson = () => {
    dispatch(setLocalText(JSON.stringify(data, null, 4)));
  };

  return (
    <div className="textarea-column d-flex flex-column h-100">
      <ControlsArea
        openModal={openModal}
        handleSubmit={saveDocumentHandler || handleSubmit}
        handleModalOpen={handleModalOpen}
        handleClose={handleClose}
        fetchSchemaMetadataById={fetchSchemaMetadataById}
      />
      {renderNotifyBlank()}
      <div className="monaco-wrapper h-100 flex-grow-1">
        <MonacoEditor
          language="json"
          theme="vs"
          value={typeof localText === 'string' ? localText : ''}
          ref={editor}
          onChange={onChange}
          options={{
            automaticLayout: true,
            formatOnPaste: true,
            formatOnType: true,
            renderLineHighlight: 'none',
            autoClosingOvertype: 'always',
            cursorStyle: 'block',
            quickSuggestions: false,
            scrollBeyondLastLine: false,
            snippetSuggestions: 'none',
            minimap: {
              enabled: false,
            },
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    schemaToSave: state.data.data,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createSchemaMetadata,
      updateSchemaMetadata,
      fetchSchemaMetadataById,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TextArea)
);
