/* eslint-disable */
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import api from '../../constants/api.js';
import { useParams } from 'react-router-dom';
import { getSchemaDetails } from '../../reducers/schema.js';
import { updateDocuments } from '@reducers/documents';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import JsonEditorContainer from '../JsonEditor/JsonEditorContainer.jsx';
import NotificationMessage from '@components/NotificationMessage';
import { dataSlice } from '../../features/data/dataSlice.js';
import { Backdrop } from '@mui/material';
import { getDatabaseList } from '../../reducers/database.js';

const AddDatabaseRecord = props => {
  const { dbId, schemaId } = useParams();
  const dispatch = useDispatch();
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('Added Record Successfully');
  // 'success' | 'error'
  const [toastMessageType, setToastMessageType] = useState('success');
  const [indexCreated, setIndexCreated] = useState(true);

  const databaseList = useSelector(state => {
    return state.database?.databaseList?.responseList;
  });

  const {
    setData,
    setSaveAsPlainJson,
    setSaveDocumentHandler,
  } = dataSlice.actions;

  useEffect(() => {
    if (!schemaId) {
      return;
    }
    const fetchSchemaDetails = async () => {
      const schemaData = await props.getSchemaDetails(schemaId);
      if (schemaData?.responseList?.[0]?.rawSchema) {
        dispatch(setData(schemaData.responseList[0].rawSchema));
      }
    };
    fetchSchemaDetails();
  }, [schemaId]);

  useEffect(() => {
    if (!(databaseList && databaseList.length)) {
      return;
    }
    const dbObj = databaseList.filter(obj => obj.id == dbId)?.[0];
    let eventSource;
    setIndexCreated(dbObj?.indexCreated);
    if (!dbObj?.indexCreated && dbId) {
      eventSource = new EventSource(
        `https://api.berrydb.io/profile/database/${dbId}/status`
      );

      eventSource.onopen = () => {
        console.log('SSE connection established');
      };

      eventSource.addEventListener(dbId, event => {
        console.log('Received message:', event.data);
        if (event.data === 'COMPLETE') {
          setIndexCreated(true);
          const userSurrogateId = localStorage.getItem('userSurrogateId');
          props.getDatabaseList(userSurrogateId, 0, 0);
        }
      });

      eventSource.onerror = error => {
        console.error('SSE error:', error);
        eventSource.close();
      };
    }

    return () => {
      if (eventSource) {
        eventSource.close();
      }
    };
  }, [dbId, databaseList]);

  useEffect(() => {
    dispatch(setSaveAsPlainJson(true));
    dispatch(setSaveDocumentHandler(handleDetailsSave));
    return () => {
      dispatch(setData(null));
      dispatch(setSaveAsPlainJson(false));
      dispatch(setSaveDocumentHandler(null));
    };
  }, [props.activeTabId]);

  const handleDetailsSave = async jsonData => {
    console.log('handleDetailsSave data :', jsonData);
    if (!dbId) {
      return;
    }
    const apiKey = localStorage.getItem('apiKey');
    try {
      await props.updateDocuments([jsonData], apiKey, dbId);
      setToastMessageType('success');
      setToastMessage('Record created successfully!');
      setIsToastOpen(true);
    } catch (err) {
      console.error(err);
      setToastMessageType('error');
      setToastMessage('Error creating record. Please try again later.');
      setIsToastOpen(true);
    }
  };

  return (
    <>
      <div className={styles['add-db-record-wrapper']}>
        <h4 className={styles['page-title']}>Add Record</h4>
        <JsonEditorContainer
          hideFilters={true}
          height={'calc(100vh - 180px)'}
        />
      </div>
      <NotificationMessage
        open={isToastOpen}
        msg={toastMessage}
        msgType={toastMessageType}
        handleClose={() => setIsToastOpen(false)}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={!indexCreated}
        style={{ position: 'absolute' }}
      >
        <div className={styles['index-status-message']}>
          Index is being created, please wait...
        </div>
      </Backdrop>
    </>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSchemaDetails,
      updateDocuments,
      getDatabaseList,
    },
    dispatch
  );

export default withRouter(connect(null, mapDispatchToProps)(AddDatabaseRecord));
