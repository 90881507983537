/* eslint-disable */
import React, { Component } from 'react';
import {
  Query,
  Builder,
  BasicConfig,
  Utils as QbUtils,
} from 'react-awesome-query-builder';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
// For AntDesign widgets only:
import AntdConfig from 'react-awesome-query-builder/lib/config/antd';
import 'antd/dist/antd.css'; // or import "react-awesome-query-builder/css/antd.less";
// For Material-UI widgets only:
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import EN from '../../localization/EN';
import Textfield from '../common/formElements/MuiTextfield';
import { reduxForm, change } from 'redux-form';
import { ProductCardCompact } from '../../components';
import myStyles from './SqlEditor.module.css';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import FilterListIcon from '@mui/icons-material/FilterList';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import cn from 'classnames';

import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css'; //optional, for more compact styles
import { getValueByPath } from '../../helpers/utils';

// Choose your skin (ant/material/vanilla):
const InitialConfig = AntdConfig; // or MaterialConfig or BasicConfig

// You need to provide your own config. See below 'Config format'
// const config = {
//   ...InitialConfig,
//   fields: {
//     qty: {
//         label: 'Qty',
//         type: 'text',
//         // fieldSettings: {
//         //     min: 0,
//         // },
//         // valueSources: ['value'],
//         // preferWidgets: ['number'],
//     }
//   }
// };

// You can load query value from your backend storage (for saving see `Query.onChange()`)
const queryValue = { id: QbUtils.uuid(), type: 'group' };

class DemoQueryBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), props.config),
      config: props.config,
      iv: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.config !== nextProps.config) {
      this.setState({
        tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), nextProps.config),
        config: nextProps.config,
      });
    }
    if (this.props.iv || this.props.databaseValue !== nextProps.databaseValue) {
      this.setState(
        {
          iv: true,
        },
        () => {
          setTimeout(() => {
            this.setState({ iv: false });
          }, 3000);
        }
      );
    }
  }

  render = () => (
    <div className="mt-5">
      <form className="w-100" onSubmit={this.props.handleSubmit}>
        <Grid container className="px-4 pb-3" spacing={2}>
          {/* <Grid item xs={2}>
            <FormControl className="w-100">
              <InputLabel
                shrink={this.props.databaseList ? true : false}
                htmlFor="age-native-simple"
              >
                Database
              </InputLabel>
              <Select
                name="database"
                value={this.props.databaseValue}
                onChange={this.props.handleChange('databaseName')}
                placeholder={this.props.databaseValue}
              >
                <MenuItem disabled value="">
                  <em>{EN.SELECT_DATABASE}</em>
                </MenuItem>
                {this.props.databaseList &&
                  this.props.databaseList.map((el, index) => {
                    return (
                      <MenuItem value={el.name} key={index}>
                        {el.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item xs={10}>
            {this.renderResult(this.state)}
          </Grid>
          <Grid item xs={2} className="d-flex p-3">
            <Button
              className="ml-auto btn-block"
              type="submit"
              variant="contained"
              color="primary"
            >
              Execute
            </Button>
            <Button
              onClick={() => this.props.onReset(true)}
              className="ml-2"
              type="button"
              variant="outlined"
              color="primary"
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
      <Divider />
      <div className={myStyles.displayItemContainer}>
        {this.props.enableQueryBuilder && (
          <>
            <label className={cn(myStyles.builderLabel, 'mb-0 ml-4')}>
              Query Builder
            </label>
            <Query
              {...this.state.config}
              value={this.state.tree}
              onChange={this.onChange}
              renderBuilder={this.renderBuilder}
            />
          </>
        )}
        {!this.props.enableQueryBuilder && (
          <div className={myStyles.disabledQueryBuilder} />
        )}
      </div>
      <Divider />
      {this.renderItem()}
    </div>
  );

  renderItem = () => {
    return (
      <div className="p-3 pb-5">
        <Grid container spacing={2}>
          {this.props.loading && !this.props.paginationLoading && (
            <CircularProgress
              style={{ position: 'absolute', bottom: '40%', right: '45%' }}
            />
          )}
          {this.props.products.length > 0 &&
            this.props.products.map((product, index) => {
              const metadata = this.props.metadata.fieldsByDataType;
              const name =
                metadata &&
                ((metadata.displayTextField &&
                  metadata.displayTextField.length &&
                  getValueByPath(product, metadata.displayTextField[0])) ||
                  (metadata.fts &&
                    metadata.fts.length &&
                    getValueByPath(product, metadata.fts[0])) ||
                  '');
              const dHash =
                metadata &&
                metadata.image &&
                metadata.image.length &&
                product[metadata.image[0]] &&
                product[metadata.image[0]][0] &&
                product[metadata.image[0]][0].fingerprint;
              const displayImageField =
                (metadata &&
                  metadata.displayImageField &&
                    metadata.displayImageField.length &&
                    getValueByPath(product, metadata.displayImageField[0])) ||
                null;
              return (
                <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
                  <ProductCardCompact
                    id={product.id}
                    dbId={product.databaseId}
                    name={name}
                    type={product.type}
                    // price={product.price}
                    description={product.description}
                    // sale={product.sale}
                    discounted={product.discounted}
                    discount={product.discount}
                    // rating={product.rating}
                    thumbnail={product.itemPic}
                    displayImageField={displayImageField}
                    uploadedTime={
                      product && product._meta && product._meta.uploadedTime
                    }
                    databaseName={this.props.databaseValue}
                    onDeleteItem={this.props.showAlertForDelete}
                    // url={product.urls[0]}
                    dHash={dHash}
                    threedots={
                      metadata &&
                      metadata.image &&
                      metadata.image.length &&
                      metadata.image[0] === 'image' &&
                      true
                    }
                    onClickSimilar={this.props.onClickSimilar}
                  />
                </Grid>
              );
            })}
          {this.props.paginationLoading && this.props.products.length > 0 && (
            <Grid container>
              <Grid item xs={12}>
                <h5 className="text-center">Loading...</h5>
              </Grid>
            </Grid>
          )}
          {this.props.products.length === 0 && !this.props.loading && (
            <Grid className="dflex h-100 mt-5" container>
              <Grid className="align-self-center" item xs={12}>
                <h2 className="text-center text-muted">No Data available</h2>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    );
  };

  renderBuilder = props => (
    <div className="query-builder-container" style={{ padding: '10px' }}>
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  );

  renderResult = ({ tree: immutableTree, config }) => {
    return (
      <div className="query-builder-result">
        <Textfield
          id="queryEditor"
          disabled={this.props.enableQueryBuilder}
          onChange={this.props.setQuery(
            QbUtils.sqlFormat(immutableTree, config)
          )}
          initialValue={
            this.state.iv ? '' : QbUtils.sqlFormat(immutableTree, config)
          }
          label="Query Editor"
          name="queryField"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => this.props.onEdit()}
                  aria-label="edit"
                >
                  {this.props.enableQueryBuilder ? (
                    <CreateIcon />
                  ) : (
                    <BorderColorIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* <div>Query string: <pre>{JSON.stringify(QbUtils.queryString(immutableTree, config))}</pre></div>
          <div>MongoDb query: <pre>{JSON.stringify(QbUtils.mongodbFormat(immutableTree, config))}</pre></div> */}
        {/* <div>SQL where: <pre>{JSON.stringify(QbUtils.sqlFormat(immutableTree, config))}</pre></div> */}
        {/* <div>JsonLogic: <pre>{JSON.stringify(QbUtils.jsonLogicFormat(immutableTree, config))}</pre></div> */}
      </div>
    );
  };

  onChange = (immutableTree, config) => {
    console.log('config', config);
    console.log('immutableTree', immutableTree);
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    this.setState({ tree: immutableTree, config: config });

    const jsonTree = QbUtils.getTree(immutableTree);
    // console.log(jsonTree);
    // `jsonTree` can be saved to backend, and later loaded to `queryValue`
  };
}

export default reduxForm({
  form: 'queryBuilderForm',
  enableReinitialize: true,
  // validate
})(DemoQueryBuilder);
