/* eslint-disable */
import NotificationMessage from '@components/NotificationMessage';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import TextArea from '../../TextArea';
import VisualizedData from '../../VisualizedData/VisualizedData';
import { dataSlice } from '../../features/data/dataSlice';
import { textareaSlice } from '../../features/textarea/textareaSlice';
import { getSchemaDetails } from '../../reducers/schema';

const minFrameWidth = 100;
const rectifyWidth = 204;

const JsonEditor = props => {
  const isDragging = useSelector(state => state.textarea.isDragging);
  const width = useSelector(state => state.textarea.width);

  const { setWidth, setDragging } = textareaSlice.actions;
  const [openModal, setModal] = useState(false);
  const [msg, setMsg] = useState('');
  const dispatch = useDispatch();

  const { setData } = dataSlice.actions;
  const { setLocalText } = textareaSlice.actions;
  // const { json } = props;
  // console.log('json: ', json);

  // write here
  const onMouseDown = useCallback(() => {
    dispatch(setDragging(true));
  }, [setDragging, dispatch]);
  const onMouseUpOrLeave = useCallback(() => {
    dispatch(setDragging(false));
  }, [setDragging, dispatch]);
  const onMouseMove = useCallback(
    event => {
      if (isDragging) {
        const newWidth = event.clientX - rectifyWidth;
        if (newWidth > minFrameWidth) {
          dispatch(setWidth(newWidth));
        }
      }
    },
    [isDragging, setWidth, dispatch]
  );

  const leadingStyle = useMemo(() => {
    return {
      width,
    };
  }, [width]);

  useEffect(() => {
    if (window.innerWidth < 576) {
      dispatch(setWidth(window.innerWidth));
    }
  }, [dispatch, setWidth]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setModal(false);
  };

  const handleOpen = msg => {
    setModal(true);
    setMsg(msg);
  };

  return (
    <>
      {/* <Header /> */}
      <main
        className="d-flex flex-sm-row flex-column flex-grow-1"
        style={{ height: props.height || 'calc(100vh - 220px)' }}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUpOrLeave}
        onMouseLeave={onMouseUpOrLeave}
      >
        <div
          className="leading-side d-flex flex-column p-2"
          style={leadingStyle}
        >
          {<TextArea handleSuccessNotification={handleOpen} />}
        </div>
        <div
          className="adjuster d-flex justify-content-center align-items-center"
          onMouseDown={onMouseDown}
        >
          <i className="fas fa-ellipsis-v" />
        </div>
        <div className="trailing-side d-flex flex-column flex-grow-1 pl-1">
          <div className="flex-grow-1">
            <div className="scroll-wrapper">
              {<VisualizedData hideFilters={props.hideFilters} />}
            </div>
          </div>
        </div>
        <NotificationMessage
          open={openModal}
          msg={msg}
          msgType={msg ? 'success' : 'error'}
          handleClose={handleClose}
        />
      </main>
    </>
  );
};

const mapStateToProps = state => {
  return {
    schemaList: state.schema.schemaDetails,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSchemaDetails,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JsonEditor)
);
