import React from 'react';
import style from './annotation.module.css';
import { APIConfig } from '../../constants';

const Annotation = () => {
  const userSurrogateId = window.localStorage.getItem('userSurrogateId');
  const userEmail = encodeURI(window.localStorage.getItem('userEmail') || '');
  const orgName = encodeURI(window.localStorage.getItem('orgName') || '');
  const HOSTNAME = APIConfig.hostname;
  return (
    <div>
      <iframe
        className={style.annotation_iframe}
        name={userSurrogateId}
        title="annotation"
        id="annotation"
        src={`${HOSTNAME}/annotations/api/user/auth?email=${userEmail}&id=${userSurrogateId}&orgName=${orgName}`}
        width="100%"
        height="500px"
      />
    </div>
  );
};

export default Annotation;
