/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getGenericTags, getApplicationTags, saveApplicationTags, getCategoryTags, saveCategoryTags, getChannelTags, saveChannelTags, generateUploadTemplate
} from '@reducers/tags';
import { VALUES } from '@constants';
import { isDirty, getFormValues } from 'redux-form';
import { withRouter } from 'react-router';
import Tags from './component';

export class DatabaseTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDatabaseCreated: JSON.parse(window.localStorage.getItem('isDatabaseCreated')),
      activeTabIndex: 0,
      activeTabId: 0,
      dataObject: VALUES.data_object,
      dataOrganization: VALUES.data_organization,
      dataMetric: VALUES.data_metric,
      dataVideo: VALUES.data_video,
      dataAudio: VALUES.data_audio,
      dataImage: VALUES.data_image,
      dataApplication: VALUES.data_application,
      dataCategory: VALUES.dataCategory,
      dataChannel: VALUES.dataChannel,
      loadingGenericTag: true,
      loadingSpecificTag: false,
      loadingCategory: false,
      loadingChannel: false,
      savedApplicationTags: false,
      savedCategoryTags: false,
      savedChannelTags: false,
      onFinish: false,
      appFormState: '',
      catFormState: '',
      channelFormState: '',
      apiCallhappened: false
    };
    this.payload = {
      username: 'sankar',
      userId: '12345',
      userEmail: 'sankar.barman@sakhatech.com',
      orgName: this.state.isDatabaseCreated && this.state.isDatabaseCreated[1].orgName,
      databaseName: this.state.isDatabaseCreated && this.state.isDatabaseCreated[0].databaseName,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.state.isDatabaseCreated) {
      this.props.history.push('/Dashboard');
    }
    this.callTabData('get');
  }

  componentWillUnmount() {
    window.localStorage.removeItem('isDatabaseCreated');
  }

  static getDerivedStateFromProps(props, state) {
    if (props.appFormState && props.appFormState !== state.appFormState) {
      return {
        appFormState: props.appFormState
      };
    }
    if (props.catFormState && props.catFormState !== state.catFormState) {
      return {
        catFormState: props.catFormState
      };
    }
    if (props.channelFormState && props.channelFormState !== state.channelFormState) {
      return {
        channelFormState: props.channelFormState
      };
    }
    return null; // No change to state
  }

  getGenericTags = () => {
    this.props.getGenericTags()
      .then((data) => {
        const dataObject = Object.assign(this.state.dataObject, { responseList: data.responseList[0].object });
        const dataOrganization = Object.assign(this.state.dataOrganization, { responseList: data.responseList[0].organization });
        const dataMetric = Object.assign(this.state.dataMetric, { responseList: data.responseList[0].metric });
        const dataVideo = Object.assign(this.state.dataVideo, { responseList: data.responseList[0].video });
        const dataAudio = Object.assign(this.state.dataAudio, { responseList: data.responseList[0].audio });
        const dataImage = Object.assign(this.state.dataImage, { responseList: data.responseList[0].image });
        this.setState((prevState) => ({
          ...prevState, dataObject, dataOrganization, dataMetric, dataVideo, dataAudio, dataImage, loadingGenericTag: !prevState.loadingGenericTag, apiCallhappened: true
        }));
      })
      .catch((err) => console.log('err', err));
  }

  getApplicationTags = () => {
    this.props.getApplicationTags(this.state.isDatabaseCreated && this.state.isDatabaseCreated[1].orgName, this.state.isDatabaseCreated && this.state.isDatabaseCreated[0].databaseName)
      .then((data) => {
        const dataApplication = Object.assign(this.state.dataApplication, { responseList: [...data.responseList, this.state.dataApplication.emptyData] });
        this.setState((prevState) => ({
          ...prevState, dataApplication, apiCallhappened: true
        }));
      })
      .catch((err) => console.log('err', err));
  }

  getCategoryTags = () => {
    this.props.getCategoryTags(this.state.isDatabaseCreated && this.state.isDatabaseCreated[1].orgName, this.state.isDatabaseCreated && this.state.isDatabaseCreated[0].databaseName)
      .then((data) => {
        const dataCategory = Object.assign(this.state.dataCategory, { responseList: [...data.responseList, this.state.dataCategory.emptyData] });
        this.setState((prevState) => ({
          ...prevState, dataCategory, apiCallhappened: true
        }));
      })
      .catch((err) => console.log('err', err));
  }

  getChannelTags = () => {
    this.props.getChannelTags(this.state.isDatabaseCreated && this.state.isDatabaseCreated[1].orgName, this.state.isDatabaseCreated && this.state.isDatabaseCreated[0].databaseName)
      .then((data) => {
        const dataChannel = Object.assign(this.state.dataChannel, { responseList: [...data.responseList, this.state.dataChannel.emptyData] });
        this.setState((prevState) => ({
          ...prevState, dataChannel, apiCallhappened: true
        }));
      })
      .catch((err) => console.log('err', err));
  }

  saveApplicationTags = () => {
    const tagList = this.state.appFormState && this.state.appFormState.responseList.filter((el) => {
      if (!el.tagName) {
        return false;
      }
      return true;
    }).map((obj) => ({ ...obj, tagType: 'application' }));
    this.payload.tagList = tagList;
    if (this.state.dataApplication.responseList !== this.state.appFormState.responseList) {
      this.props.saveApplicationTags(this.payload)
        .then(() => {
          this.onSuccessSaveApplicationTags();
        }).catch((err) => console.log('error', err));
    } else {
      this.onSuccessSaveApplicationTags();
    }
  }

  saveCategoryTags = () => {
    const tagList = this.state.catFormState && this.state.catFormState.responseList.filter((el) => {
      if (!el.categoryName) {
        return false;
      }
      return true;
    }).map((obj) => ({ ...obj, tagType: 'category' }));
    this.payload.tagList = tagList;
    if (this.state.dataCategory.responseList !== this.state.catFormState.responseList) {
      this.props.saveCategoryTags(this.payload)
        .then(() => {
          this.onSuccessSaveCategoryTags();
        }).catch((err) => console.log('error', err));
    } else {
      this.onSuccessSaveCategoryTags();
    }
  }

  saveChannelTags = () => {
    const tagList = this.state.channelFormState && this.state.channelFormState.responseList.filter((el) => {
      if (!el.channelName) {
        return false;
      }
      return true;
    }).map((obj) => ({ ...obj, tagType: 'channel' }));
    if (this.state.dataChannel.responseList !== this.state.channelFormState.responseList) {
      this.payload.tagList = tagList;
      this.props.saveChannelTags(this.payload)
        .then(() => {
          this.onSuccessSaveChannelTags();
        }).catch((err) => console.log('error', err));
    } else {
      this.onSuccessSaveChannelTags();
    }
  }

  onSuccessSaveApplicationTags = () => {
    this.setState({
      savedApplicationTags: true
    }, () => {
      this.getApplicationTags();
      this.checkFinish();
    });
  }

  onSuccessSaveCategoryTags = () => {
    this.setState({
      savedCategoryTags: true
    }, () => {
      this.getCategoryTags();
      this.checkFinish();
    });
  }

  onSuccessSaveChannelTags = () => {
    this.setState({
      savedChannelTags: true
    }, () => {
      this.getChannelTags();
      this.checkFinish();
    });
  }

  setActiveTab = (index) => {
    let id = '';
    switch (index) {
      case 0: id = 0;
        break;
      case 1: id = 1;
        break;
      case 2: id = 2;
        break;
      default: break;
    }
    this.setState((prevState) => ({
      ...prevState,
      prevTab: prevState.activeTabId,
      activeTabIndex: index,
      activeTabId: id,
      apiCallhappened: false
    }), () => {
      this.callTabData('get');
      if (this.state.prevTab === 0) {
        this.saveApplicationTags();
      }
      if (this.state.prevTab === 1) {
        this.saveCategoryTags();
      }
      if (this.state.prevTab === 2) {
        this.saveChannelTags();
      }
    });
  }

  checkFinish = () => {
    // const savedAllTags = this.state.savedApplicationTags && this.state.savedCategoryTags && this.state.savedChannelTags;
    if (this.state.onFinish) {
    //   const payload = {
    //     orgName: this.state.isDatabaseCreated[1].orgName,
    //     databaseName: this.state.isDatabaseCreated[0].databaseName
    //   };
    //   this.props.generateUploadTemplate(payload)
    //     .then(() => {
    //       this.props.history.push('/Database');
    //     })
    //     .catch((err) => console.log('error', err));
      this.props.history.push('/Database');
    }
  }

  callTabData = (type) => {
    if (this.state.activeTabId === 0) {
      if (type === 'get') {
        this.getGenericTags();
        this.getApplicationTags();
      }
      if (type === 'save') {
        this.saveApplicationTags();
      }
    }
    if (this.state.activeTabId === 1) {
      if (type === 'get') {
        this.getCategoryTags();
      }
      if (type === 'save') {
        this.saveCategoryTags();
      }
    }
    if (this.state.activeTabId === 2) {
      if (type === 'get') {
        this.getChannelTags();
      }
      if (type === 'save') {
        this.saveChannelTags();
      }
    }
    if (type === 'save' && !this.state.onFinish) {
      this.setActiveTab(this.state.activeTabIndex === 0 ? 1 : 2);
    }
  }

  handleSubmit = () => {
    this.setActiveTab(this.state.activeTabIndex === 0 ? 1 : 2);
  }

  onClickFinish = () => {
    this.setState({
      onFinish: true
    }, () => {
      this.callTabData('save');
    });
  }

  render() {
    console.log('ivv', this.state.dataApplication.responseList)
    return (
      <Tags
        tabList={VALUES.DATABASE_TAGS}
        setActiveTab={this.setActiveTab}
        activeTabIndex={this.state.activeTabIndex}
        activeTabId={this.state.activeTabId}
        handleSubmit={this.handleSubmit}
        dataObject={this.state.dataObject}
        dataOrganization={this.state.dataOrganization}
        dataMetric={this.state.dataMetric}
        dataVideo={this.state.dataVideo}
        dataAudio={this.state.dataAudio}
        dataImage={this.state.dataImage}
        dataApplication={this.state.dataApplication}
        dataCategory={this.state.dataCategory}
        dataChannel={this.state.dataChannel}
        loadingGenericTag={this.state.loadingGenericTag}
        loadingSpecificTag={this.state.loadingSpecificTag}
        loadingCategory={this.state.loadingCategory}
        loadingChannel={this.state.loadingChannel}
        databaseName={this.state.isDatabaseCreated && this.state.isDatabaseCreated[0].databaseName}
        onClickFinish={this.onClickFinish}
        formName={VALUES.data_application.form}
        apiCallhappened={this.state.apiCallhappened}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isDirty: isDirty('application')(state),
  appFormState: getFormValues('applicationForm')(state),
  catFormState: getFormValues('categoryForm')(state),
  channelFormState: getFormValues('channelForm')(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getGenericTags,
  getApplicationTags,
  getCategoryTags,
  saveApplicationTags,
  saveCategoryTags,
  getChannelTags,
  saveChannelTags,
  generateUploadTemplate,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DatabaseTags));
