/* eslint-disable */

import React, { useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
//import './HomeComponent.scss';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import { IconButton} from '@mui/material';


const RecordComponent = ({inputValue, setInputValue}) => {

  const [fullTranscript, setFullTranscript] = useState('');
  const [messages, setMessages] = useState([]);
  // const [continuous, setContinuous] = useState(false);

  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const onStartListening = () => {
    console.log('start listening for speech recognition')
    SpeechRecognition.startListening({ continuous: true })
  }


  const onStopListening = () => {
    // setFullTranscript(fullTranscript + ' ' + transcript);
    // resetTranscript();
    console.log(transcript)
    setInputValue(transcript)
    SpeechRecognition.abortListening();
    SpeechRecognition.startListening({ continuous: false })
    SpeechRecognition.abortListening();
  };

  const buttonStyle = {
    color: "#3f51b5"
  };

  return (
    <>
    { 
      listening && (<IconButton
      style={buttonStyle}
      variant="contained"
      onClick={onStopListening}
    >
      <StopCircleIcon />
    </IconButton>) } 
    {!listening && <IconButton
      style={buttonStyle}
      variant="contained"
      onClick={onStartListening}
    >
      <KeyboardVoiceIcon />
    </IconButton>
    }
    </>             
  );
};

export default RecordComponent;
