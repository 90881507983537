/* eslint-disable */
import { Grid, Typography } from '@material-ui/core';
import { getDatabaseList } from '@reducers/database';
import {
  deleteItemFromFilterList,
  getAnnotations,
  getItemDetails,
  getItemDetailsByQuery,
} from '@reducers/query';
import { getAllTags, getQueryResult } from '@reducers/sqlEditor';
import { getAddedFilterApiData } from '@reducers/tags';
import React, { Component } from 'react';
import AntdConfig from 'react-awesome-query-builder/lib/config/antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import AlertDialogDelete from '../Filter/deleteAlertDialog';
import SqlComponent from './component.jsx';
import AlertDialogEdit from './editAlert';
import { Context } from '../AppProvider/AppProvider';
const InitialConfig = AntdConfig;

const orgName = window.localStorage.getItem('orgName');

export class SqlEditor extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      databaseList: '',
      databaseName: '',
      dbId: '',
      schemaId: '',
      annotations: [],
      annotationResults: [],
      annotationLabels: [],
      annotationLabelKeyMap: [],
      annotationLabelMap: {},
      annotationLabelKeyMap: {},
      selectedAnnotationLabel: '',
      selectedAnnotationKey: '',
      selectedAnnotationValue: '',
      showAnnotationFilter: false,
      products: [],
      metadata: {},
      pageNo: 1,
      pageSize: 32,
      hasMore: true,
      paginationLoading: false,
      loading: true,
      fieldsItem: [],
      queryValue: '',
      showAlertForDelete: false,
      prevPayload: '',
      config: {
        ...InitialConfig,
        fields: {},
      },
      iv: false,
      enableQueryBuilder: true,
      showAlertForEdit: false,
    };
  }

  componentDidMount() {
    const userId = window.localStorage.getItem('userSurrogateId');

    this.props.getDatabaseList(userId, 0, 0).then(() => {
      this.getItemDetails();
      this.getAllTags();
      this.getAnnotations();
    });
    window.addEventListener('scroll', this.getListOnScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    const [contextState, dispatch] = this.context;
    if (prevState.databaseName !== contextState.globalDatabaseName) {
      this.handleChange('databaseName')({
        target: { value: contextState.globalDatabaseName },
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.getListOnScroll);
  }

  getListOnScroll = () => {
    if (this.state.hasMore) {
      const windowHeight =
        'innerHeight' in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      const windowBottom = windowHeight + window.pageYOffset;
      if (windowBottom >= docHeight && this.props.loading === false) {
        this.setState({ hasMore: false, paginationLoading: true }, () => {
          if (this.state.queryValue === '') {
            this.getItemDetails(
              this.state.prevPayload !== ''
                ? { ...this.state.prevPayload, pageNo: this.state.pageNo }
                : null,
              'onScroll'
            );
          } else {
            this.getQueryResult('onScroll');
          }
        });
      }
    }
  };

  getAllTags = () => {
    if (this.state.databaseName !== '') {
      this.props
        .getAddedFilterApiData(this.state.dbId, this.state.schemaId)
        .then(data => {
          const values = Object.entries(data.responseList[0]);
          var fieldsItem = values.map(item => ({
            label: item[0],
            type: item[1] === 'number' ? 'number' : 'text',
          }));
          const entries = Object.fromEntries(fieldsItem.entries());
          const mappedNames = Object.keys(entries).map(key => [
            entries[key].label,
            entries[key],
          ]);
          const mappedfieldsItem = Object.fromEntries(mappedNames);
          this.setState(prevState => ({
            ...prevState,
            config: {
              ...InitialConfig,
              fields: { ...mappedfieldsItem },
            },
          }));
        });
    }
  };

  getItemDetails = (payload, calledFrom) => {
    const orgName = window.localStorage.getItem('orgName');
    this.setState({ loading: true });
    const callPayload = payload
      ? payload
      : {
          databaseId: this.state.dbId,
          pageNo: this.state.pageNo,
          pageSize: this.state.pageSize,
        };
    this.props
      .getItemDetails(callPayload)
      .then(data => {
        this.setProductData(data, calledFrom);
      })
      .catch(err => {
        console.log('error', err);
        this.setState({ loading: false });
      });
  };

  getItemDetailsByQuery = query => {
    this.setState({ loading: true });
    const callPayload = { query: query };
    this.props
      .getItemDetailsByQuery(callPayload)
      .then(data => {
        console.log(data);
        this.setState(prevState => ({
          ...prevState,
          products: data.items || [],
          metadata: data.metadata || [],
          pageNo: 0,
          hasMore: false,
          loading: false,
          paginationLoading: false,
        }));
        console.log(this.state);
      })
      .catch(err => {
        console.log('error', err);
        this.setState({ loading: false });
      });
  };

  getAnnotations = (payload, calledFrom) => {
    const orgName = window.localStorage.getItem('orgName');
    this.setState({ loading: true });
    this.props
      .getAnnotations(this.state.dbId)
      .then(data => {
        this.setAnnotationsData(data);
      })
      .catch(err => {
        console.log('error', err);
        this.setState({ loading: false });
      });
  };

  setProductData = (data, calledFrom) => {
    if (this.state.products.length > 0 && this.state.pageNo > 0) {
      this.setState(prevState => ({
        ...prevState,
        products: data.items
          ? calledFrom === 'onScroll'
            ? prevState.products.concat(data.items)
            : data.items
          : prevState.products,
        metadata: data.metadata ? data.metadata : prevState.metadata,
        pageNo: this.state.pageNo + 1,
        hasMore: data.items && data.items.length > 0 ? true : false,
        loading: false,
        paginationLoading: false,
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        products: data.items || [],
        metadata: data.metadata || {},
        pageNo: this.state.pageNo + 1,
        hasMore: data.items && data.items.length > 0 ? true : false,
        loading: false,
        paginationLoading: false,
      }));
    }
  };

  setAnnotationsData = data => {
    let annotations = [];
    let results = [];
    let annotationList = [...data.responseList];
    let annotationMap = {};
    let annotationKeyMap = {};
    annotationList.forEach(al => {
      annotations = annotations.concat([...al.annotations]);
    });
    annotations.forEach(a => {
      results = results.concat([...a.result]);
    });
    let labels = new Set();
    results.forEach(r => {
      labels.add(r.type);
      if (!annotationMap[r.type]) {
        annotationMap[r.type] = [];
      }
      let values = r.value[r.type];
      let valuesArray = [...new Set([...annotationMap[r.type], ...values])];
      annotationMap[r.type] = valuesArray;
      valuesArray.forEach(v => {
        if (!annotationKeyMap[v]) {
          annotationKeyMap[v] = [];
        }
        if (
          typeof r.value[r.to_name] === 'string' ||
          typeof r.value[r.to_name] === 'number' ||
          typeof r.value[r.to_name] === 'boolean'
        ) {
          annotationKeyMap[v].push(r.value[r.to_name]);
        }
      });
    });
    console.log(annotationKeyMap);
    this.setState(prevState => ({
      ...prevState,
      annotationLabelKeyMap: annotationKeyMap,
      annotationLabelMap: annotationMap,
      annotationLabels: [...labels],
      annotations: annotations,
      annotationResults: results,
    }));
    console.log(this.state);
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.databaseList.responseList &&
      props.databaseList.responseList !== state.databaseList
    ) {
      return props.databaseList.responseList &&
        props.databaseList.responseList.length
        ? {
            databaseList: props.databaseList.responseList,
            databaseName: props.databaseList.responseList[0].name,
            dbId: props.databaseList.responseList[0].id,
            schemaId: props.databaseList.responseList[0].schemaId,
          }
        : {};
    }
    return null;
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
    this.props.databaseList.responseList &&
      this.props.databaseList.responseList.map(results => {
        if (results.name === event.target.value) {
          this.setState({ dbId: results.id, schemaId: results.schemaId });
        }
      });
    this.doReset();
  };

  handleLabelChange = name => event => {
    this.setState({
      selectedAnnotationLabel: event.target.value,
    });
  };

  handleKeyChange = name => event => {
    this.setState({
      selectedAnnotationKey: event.target.value,
    });
  };

  handleValueChange = name => event => {
    this.setState({
      selectedAnnotationValue: event.target.value,
    });
  };

  onToggleAnnotationfilter = () => {
    let showFilter = !!!this.state.showAnnotationFilter;
    console.log(showFilter);
    console.log(this.state.showAnnotationFilter);
    this.setState({
      showAnnotationFilter: showFilter,
    });
  };

  filterOnAnnotations = () => {
    let query = '';
    if (
      this.state.selectedAnnotationLabel &&
      this.state.selectedAnnotationValue &&
      this.state.selectedAnnotationKey
    ) {
      query = `SELECT * FROM BerryDb AS items WHERE ANY annotation IN items.annotations SATISFIES ANY result IN annotation.result SATISFIES ANY l IN result.\`value\`.${this.state.selectedAnnotationLabel} SATISFIES l = '${this.state.selectedAnnotationKey}' and result.\`value\`.text = '${this.state.selectedAnnotationValue}' END END END AND items.databaseName = '${this.state.databaseName}';`;
    } else if (
      this.state.selectedAnnotationLabel &&
      this.state.selectedAnnotationKey
    ) {
      query = `SELECT * FROM BerryDb AS items WHERE ANY annotation IN items.annotations SATISFIES ANY result IN annotation.result SATISFIES ANY l IN result.\`value\`.${this.state.selectedAnnotationLabel} SATISFIES l = '${this.state.selectedAnnotationKey}' END END END AND items.databaseName = '${this.state.databaseName}';`;
    } else if (this.state.selectedAnnotationLabel) {
      query = `SELECT * FROM BerryDb AS items WHERE ANY annotation IN items.annotations SATISFIES ANY result IN annotation.result SATISFIES result.from_name='${this.state.selectedAnnotationLabel.substring(
        0,
        this.state.selectedAnnotationLabel.length - 1
      )}' END END AND items.databaseName = '${this.state.databaseName}';`;
    }
    this.getItemDetailsByQuery(query);
  };

  handleSubmit = () => {
    let val = '';
    if (document.getElementById('queryEditor')) {
      val = document.getElementById('queryEditor').value;
    }
    if (this.state.queryValue || val !== '') {
      this.setState(
        {
          products: [],
          loading: true,
          pageNo: 1,
        },
        () => {
          this.getQueryResult('', val);
        }
      );
    }
  };

  getQueryResult = (key, val) => {
    const payload = {
      databaseId: this.state.dbId,
      query: this.state.queryValue ? this.state.queryValue : val,
      pageNo: this.state.pageNo,
      pageSize: this.state.pageSize,
    };
    this.props.getItemDetails(payload).then(data => {
      this.setState({
        loading: false,
      });
      this.setProductData(data, key);
    });
  };

  setQuery = data => {
    if (data) {
      this.setState(prevState => ({
        ...prevState,
        queryValue: data,
      }));
    }
    if (data === undefined) {
      this.setState({
        queryValue: '',
      });
    }
  };

  onDeleteItem = id => {
    const orgName = window.localStorage.getItem('orgName');
    const payload = {
      orgName: orgName,
      databaseName: this.state.databaseName,
      objectId: this.state.itemToDelete,
    };
    this.props.deleteItemFromFilterList(payload).then(() => {
      if (payload.objectId !== 'all') {
        this.setState(prevState => ({
          showAlertForDelete: !this.state.showAlertForDelete,
          products: prevState.products.filter(
            product => product.id !== payload.objectId
          ),
        }));
      } else {
        this.setState({
          showAlertForDelete: !this.state.showAlertForDelete,
          products: [],
        });
      }
    });
  };

  showAlertForDelete = id => {
    this.setState({
      itemToDelete: id,
      showAlertForDelete: !this.state.showAlertForDelete,
    });
  };

  onClickSimilar = HashValue => {
    const orgName = window.localStorage.getItem('orgName');
    const payload = {
      // orgName: orgName,
      databaseId: this.state.dbId,
      dHash: HashValue,
      pageNo: 1,
      pageSize: this.state.pageSize,
    };
    this.setState({ products: [], pageNo: 1 }, () => {
      window.scrollTo(0, 0);
      this.getItemDetails(payload);
      this.getAnnotations();
      this.setState({
        prevPayload: payload,
      });
    });
  };

  doReset = key => {
    this.setState(
      {
        hasMore: true,
        pageNo: 1,
        queryValue: '',
        products: [],
        config: {
          ...InitialConfig,
          fields: {},
        },
      },
      () => {
        this.getItemDetails();
        this.getAnnotations();
        this.getAllTags();
      }
    );
    if (key) {
      this.setState(
        {
          iv: true,
        },
        () => {
          setTimeout(() => {
            this.setState({ iv: false });
          }, 3000);
        }
      );
    }
  };

  onEdit = () => {
    this.setState({
      showAlertForEdit: !this.state.showAlertForEdit,
    });
  };

  onConfirmEdit = () => {
    this.setState(
      prevState => ({
        enableQueryBuilder: !this.state.enableQueryBuilder,
        showAlertForEdit: !this.state.showAlertForEdit,
      }),
      () => {
        this.doReset(this.state.enableQueryBuilder);
      }
    );
  };

  render() {
    if (!(this.state.databaseList && this.state.databaseList.length)) {
      return (
        <Grid container direction="row" className="p-3r">
          <Grid item xs={12} className="my-10">
            <Typography variant="subtitle">
              <b>No Database found. Create a Database to Query.</b>
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <div className="overflow-hidden">
        <SqlComponent
          databaseList={this.state.databaseList}
          databaseValue={this.state.databaseName}
          annotations={this.state.annotations}
          annotationResults={this.state.annotationResults}
          annotationLabels={this.state.annotationLabels}
          annotationLabelMap={this.state.annotationLabelMap}
          annotationLabelKeyMap={this.state.annotationLabelKeyMap}
          selectedAnnotationLabel={this.state.selectedAnnotationLabel}
          selectedAnnotationValue={this.state.selectedAnnotationValue}
          selectedAnnotationKey={this.state.selectedAnnotationKey}
          showAnnotationFilter={this.state.showAnnotationFilter}
          onToggleAnnotationfilter={this.onToggleAnnotationfilter}
          filterOnAnnotations={this.filterOnAnnotations}
          products={this.state.products}
          metadata={this.state.metadata}
          handleChange={this.handleChange}
          handleLabelChange={this.handleLabelChange}
          handleValueChange={this.handleValueChange}
          handleKeyChange={this.handleKeyChange}
          onSubmit={this.handleSubmit}
          setQuery={this.setQuery}
          onDeleteItem={this.onDeleteItem}
          paginationLoading={this.state.paginationLoading}
          loading={this.state.loading}
          config={this.state.config}
          queryValue={this.state.queryValue}
          showAlertForDelete={this.showAlertForDelete}
          onClickSimilar={this.onClickSimilar}
          onReset={this.doReset}
          iv={this.state.iv}
          enableQueryBuilder={this.state.enableQueryBuilder}
          onEdit={this.onEdit}
        />
        <AlertDialogDelete
          showAlertForDelete={this.state.showAlertForDelete}
          setOpen={() =>
            this.setState({
              showAlertForDelete: !this.state.showAlertForDelete,
            })
          }
          onDeleteItem={this.onDeleteItem}
        />
        <AlertDialogEdit
          showAlertForEdit={this.state.showAlertForEdit}
          setOpen={() =>
            this.setState({ showAlertForEdit: !this.state.showAlertForEdit })
          }
          onConfirmEdit={this.onConfirmEdit}
          enableQueryBuilder={this.state.enableQueryBuilder}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  databaseList: state.database.databaseList,
  itemOfFields: state.sqlEditor.items,
  loading: state.query.loading,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDatabaseList,
      getItemDetails,
      getItemDetailsByQuery,
      getAnnotations,
      deleteItemFromFilterList,
      getAllTags,
      getAddedFilterApiData,
      getQueryResult,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SqlEditor)
);
