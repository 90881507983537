/* eslint-disable */
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { useForm, Controller } from 'react-hook-form';
import { TextField, TextareaAutosize } from '@material-ui/core';

import styles from './ChatWindow.module.css';

const PerspectiveSettingsModal = props => {
  const [selectedPerspective, setSelectedPerspective] = useState();
  const [
    selectedNewPerspectiveModel,
    setSelectedNewPerspectiveModel,
  ] = useState();

  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {
      perspectiveName: '',
      model: 'OpenAI',
      prompt: '',
    },
  });
  const onSubmit = data => console.log(data);

  const handlePerspectiveSelect = per => {
    setValue('perspectiveName', per.name);
    setValue('model', per.chatModelName);
    setValue('prompt', per.prompt);
  };

  const handleCreatePerspective = () => {
    const userId = window.localStorage.getItem('userSurrogateId');
    const perspectiveName = getValues('perspectiveName');
    const model = getValues('model');
    const prompt = getValues('prompt');
    console.log(
      'perspectiveName: ',
      perspectiveName,
      'model: ',
      model,
      'prompt: ',
      prompt
    );
    if (!(perspectiveName && prompt)) {
      return;
    }
    const payload = {
      name: perspectiveName,
      chatModelName: model,
      databaseId: props.databaseId,
      prompt: prompt,
      userId: userId,
    };
    props.handleCreatePerspective(payload);
  };

  return (
    <Dialog
      open={props.isModelSettingsOpen || false}
      onClose={props.setModelSettingsClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={styles['perspectives-dialog']}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-title">
        <div className={styles['perspectives-dialog__heading']}>
          Assistant Settings
        </div>
      </DialogTitle>
      <DialogContent
      /* style={{
        minHeight: '400px',
        maxHeight: '90vh',
        minWidth: '200px',
        maxWidth: '90vw',
      }} */
      >
        <div className={styles['chat-settings-container']}>
          <div className={styles['chat-settings-content']}>
            <div
              className={[styles['column'], styles['column-left']].join(' ')}
            >
              {/* <div className="item">
              <h4 className="headings">Models</h4>
              {this.props.chatModels && this.props.chatModels.length ? (
                <div className="model-list">
                  {this.props.chatModels.map(per => (
                    <div
                      className={[
                        'item',
                        this.state.selectedModel &&
                        this.state.selectedModel.id == per.id
                          ? 'item-selected'
                          : '',
                      ].join(' ')}
                      onClick={() => this.setState({ selectedModel: per })}
                    >
                      {per.name}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="empty-list">No models found</div>
              )}
            </div> */}
              <div className={styles['item']}>
                <h4
                  className={[styles['heading'], styles['heading--large']].join(
                    ' '
                  )}
                >
                  Assistants
                </h4>
                {props.chatPerspectives && props.chatPerspectives.length ? (
                  <div className={styles['model-list']}>
                    {props.chatPerspectives.map(per => (
                      <div
                        className={[
                          styles['item'],
                          selectedPerspective &&
                          selectedPerspective.id == per.id
                            ? styles['item-selected']
                            : '',
                        ].join(' ')}
                        onClick={() => {
                          setSelectedPerspective(per);
                          handlePerspectiveSelect(per);
                        }}
                      >
                        {per.name}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={styles['empty-list']}>
                    No Assistants found
                  </div>
                )}
                <div
                  className={styles['create-btn']}
                  onClick={() => {
                    setSelectedPerspective(null);
                    reset();
                    /* if (this.state.promptNameField) {
                      this.state.promptNameField.current.value = '';
                    }
                    if (this.state.promptInputField) {
                      this.state.promptInputField.current.value = '';
                    } */
                  }}
                >
                  + Create Assistant
                </div>
              </div>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={[styles['column'], styles['column-right']].join(' ')}
            >
              <h4 className={styles['heading']}>Assistant Name</h4>
              <Controller
                name="perspectiveName"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                  formState,
                }) => (
                  <TextField
                    {...props}
                    value={value}
                    onChange={onChange}
                    variant="standard"
                    size="small"
                    disabled={!!selectedPerspective}
                  />
                )}
              />
              <h4 className={[styles['heading'], 'pt-20'].join(' ')}>Model</h4>
              <Controller
                name="model"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                  formState,
                }) => (
                  <Select
                    {...props}
                    value={value}
                    onChange={onChange}
                    variant="standard"
                    size="small"
                    name="Select model"
                    disabled={!!selectedPerspective}
                    placeholder={props.chatModel}
                    style={{ width: '100%' }}
                    MenuProps={{ MenuListProps: { disablePadding: true } }}
                  >
                    <MenuItem disabled value="">
                      <em>Select a model</em>
                    </MenuItem>
                    {props.chatModels &&
                      props.chatModels.map((el, index) => {
                        return (
                          <MenuItem value={el.id} key={index}>
                            {el.id + ' (' + el.name + ')'}
                          </MenuItem>
                        );
                      })}
                  </Select>
                )}
              />
              <h4 className={[styles['heading'], 'pt-20'].join(' ')}>Prompt</h4>
              <Controller
                name="prompt"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                  formState,
                }) => (
                  <TextareaAutosize
                    {...props}
                    value={value}
                    onChange={onChange}
                    rows={4}
                    minRows={4}
                    maxRows={10}
                    disabled={!!selectedPerspective}
                  ></TextareaAutosize>
                )}
              />
            </form>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        {!!selectedPerspective ? (
          <>
            <Button onClick={props.setModelSettingsClose} autoFocus>
              Close
            </Button>
            <Button
              onClick={() => {
                props.handlePerspectiveSelection(selectedPerspective);
                props.setModelSettingsClose();
              }}
              autoFocus
            >
              Select Assistant
            </Button>
          </>
        ) : (
          <>
            <Button onClick={props.setModelSettingsClose} autoFocus>
              Cancel
            </Button>
            <Button onClick={handleCreatePerspective} autoFocus>
              Create Assistant
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PerspectiveSettingsModal;
