/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Typography, Chip } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import styles from './SchemaPipelineModal.module.css';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';

const SchemaPipelineModal = props => {
  const [extractionsForDataTypes, setExtractionsForDataTypes] = useState(null);
  const [showLabelsForExtraction, setShowLabelsForExtraction] = useState(false);
  const [labelsForExtraction, setLabelsForExtraction] = useState(null);
  const [showPipelineForm, setShowPipelineForm] = useState(false);
  const pipelinesExtractTypes = useMemo(() => props.pipelinesExtractTypes, [
    props.pipelinesExtractTypes,
  ]);
  const pipelinesExtractTypesKeys = Object.keys(pipelinesExtractTypes || {});
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      dataType: '',
      extractionType: '',
      labels: '',
      labelInput: '',
    },
  });

  const handleShowPipelineForm = () => {
    setValue('dataType', '');
    setValue('extractionType', '');
    setLabelsForExtraction(null);
    setExtractionsForDataTypes([]);
    clearErrors('dataType');
    clearErrors('extractionType');
    setShowPipelineForm(true);
  };

  // useEffect(() => {
  //   const type = props.pipelineFields?.dataType || '';
  //   setValue('dataType', type);
  //   const newExtractionType = props.pipelineFields?.extract || '';
  //   setValue('extractionType', newExtractionType);
  //   setLabelsForExtraction(props.pipelineFields?.labels);
  //   setShowLabelsForExtraction(
  //     pipelinesExtractTypes?.[type]?.find(
  //       e => e?.extraction === newExtractionType
  //     )?.isLabelListRequired
  //   );
  //   setExtractionsForDataTypes(pipelinesExtractTypes?.[type] || null);
  // }, []);

  const handleDataTypeChange = e => {
    const type = e?.target?.value || '';
    setValue('dataType', type || '');
    setValue('extractionType', '');
    setLabelsForExtraction(null);
    setExtractionsForDataTypes(pipelinesExtractTypes?.[type] || []);
    clearErrors('dataType');
  };

  const handleExtractionTypeChange = e => {
    const extractionType = e?.target?.value || '';
    const dataType = getValues('dataType');
    setValue('extractionType', extractionType);
    setShowLabelsForExtraction(
      pipelinesExtractTypes?.[dataType]?.find(
        e => e?.extraction === extractionType
      )?.isLabelListRequired
    );
    setLabelsForExtraction(null);
    clearErrors('extractionType');
  };

  const handleAddPipeline = () => {
    const dataType = getValues('dataType');
    const extractionType = getValues('extractionType');
    if (!dataType) {
      setError('dataType', { type: 'custom', message: 'Select a data type' });
      return;
    }
    if (!extractionType) {
      setError('extractionType', {
        type: 'custom',
        message: 'Select an extraction type',
      });
      return;
    }
    if (showLabelsForExtraction && !labelsForExtraction?.length) {
      setError('labelInput', {
        type: 'custom',
        message: 'Add at least one label',
      });
      return;
    }
    clearErrors('dataType');
    clearErrors('extractionType');
    clearErrors('labelInput');
    props.handleAttachPipeline(
      props.path,
      dataType,
      extractionType,
      labelsForExtraction
    );
    setShowPipelineForm(false);
  };

  const handleRemovePipeline = extractionType => {
    props.handleRemovePipeline(props.path, extractionType);
  };

  const getExtractionsMenuDisabledItemText = () => {
    if (!extractionsForDataTypes) {
      return 'Select a Data Type First';
    }
    if (!extractionsForDataTypes.length) {
      return 'No Extraction Types available for this type';
    }
    return 'Select an Extraction Type';
  };

  const handleAddLabel = () => {
    let label = getValues('labelInput');
    label = label?.trim();
    if (!label) {
      setError('labelInput', { type: 'Label', message: 'Enter a valid label' });
      return;
    }
    clearErrors('labelInput');
    let newLabels = labelsForExtraction || [];
    newLabels.push(label);
    newLabels = [...new Set(newLabels)];
    setLabelsForExtraction(newLabels);
    setValue('labelInput', '');
  };

  const handleRemoveLabel = index => {
    const newLabels = [...(labelsForExtraction || [])];
    newLabels.splice(index, 1);
    setLabelsForExtraction(newLabels);
  };

  const renderAddLabels = () => {
    return (
      <div className={styles['labels-container']}>
        <h4 className={styles['labels-container__heading']}>Labels</h4>
        <div className={styles['labels-input-container']}>
          <form
            onSubmit={e => {
              handleAddLabel();
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Controller
              name={'labelInput'}
              control={control}
              render={({
                field: { name, onChange, value },
                formState: { errors },
              }) => (
                <>
                  <TextField
                    name={name}
                    value={value}
                    variant="standard"
                    onChange={onChange}
                  />
                  {!!errors?.labelInput && (
                    <p className={styles['error-msg']}>
                      {errors.labelInput.message}
                    </p>
                  )}
                </>
              )}
            />
          </form>
          <Button
            className={styles['add-label-btn']}
            onClick={handleAddLabel}
            size="small"
          >
            <div className={styles['add-label-btn__content']}>
              <AddCircleOutlineIcon className={styles['icon']} />
              <div>Add</div>
            </div>
          </Button>
        </div>
        <div className={styles['labels-row']}>
          {labelsForExtraction?.map((label, index) => {
            return (
              <Chip
                label={label}
                variant="outlined"
                size="small"
                onDelete={() => handleRemoveLabel(index)}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const listPipelines = () => {
    return (
      <>
        <DialogContent>
          <div className={styles['pipeline-list']}>
            {props.pipelineFields?.map(pipelineField => {
              const extractType = pipelinesExtractTypes[
                pipelineField?.dataType
              ]?.find(e => e.extraction === pipelineField?.extract);
              return (
                <div
                  key={extractType?.extractionName}
                  className={styles['pipeline-list--item']}
                >
                  <div className={styles['pipeline-item--name']}>
                    {extractType?.extractionName + ' | ' + extractType?.model}
                  </div>
                  <div className={styles['pipeline-item--actions']}>
                    <IconButton
                      onClick={() =>
                        handleRemovePipeline(pipelineField?.extract)
                      }
                    >
                      <Delete />
                    </IconButton>
                  </div>
                </div>
              );
            })}
          </div>
          <div></div>
        </DialogContent>
        {/* <DialogActions> */}
        <div className={styles['action-buttons-row']}>
          <Button
            className={styles['action-buttons-row__btn']}
            onClick={props.setModalClose}
            autoFocus
          >
            Close
          </Button>
          <Button
            className={styles['action-buttons-row__btn']}
            onClick={handleShowPipelineForm}
            autoFocus
          >
            Add One More
          </Button>
        </div>
        {/* </DialogActions> */}
      </>
    );
  };

  const editPipeline = () => {
    return (
      <>
        <DialogContent>
          <div className={styles['pipeline-container']}>
            <form className={styles['pipeline-content']}>
              <div className={styles['pipeline-content__row']}>
                <h4 className={styles['heading']}>Data Type</h4>
                <Controller
                  name="dataType"
                  control={control}
                  render={({
                    field: { value, name },
                    formState: { errors },
                  }) => (
                    <>
                      <Select
                        {...props}
                        name={name}
                        value={value}
                        onChange={handleDataTypeChange}
                        variant="standard"
                        size="small"
                        placeholder={'Select a Data Type'}
                        fullWidth
                        MenuProps={{ MenuListProps: { disablePadding: true } }}
                        className={styles['input']}
                      >
                        <MenuItem disabled value="" key="">
                          <em>Select a Data Type</em>
                        </MenuItem>
                        {pipelinesExtractTypesKeys?.map(type => {
                          return (
                            <MenuItem value={type} key={type}>
                              <Typography
                                style={{
                                  textTransform: 'capitalize',
                                  fontSize: '13px',
                                }}
                              >
                                {type}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {!!errors.dataType && (
                        <p className={styles['error-msg']}>
                          {errors.dataType.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
              <div className={styles['pipeline-content__row']}>
                <h4 className={styles['heading']}>Extraction Type</h4>
                <Controller
                  name="extractionType"
                  control={control}
                  render={({
                    field: { value, name },
                    formState: { errors },
                  }) => (
                    <>
                      <Select
                        {...props}
                        name={name}
                        value={value}
                        onChange={handleExtractionTypeChange}
                        variant="standard"
                        size="small"
                        placeholder={'Select an Extraction Type'}
                        fullWidth
                        MenuProps={{ MenuListProps: { disablePadding: true } }}
                        className={styles['input']}
                      >
                        <MenuItem disabled value="" key="">
                          <em>{getExtractionsMenuDisabledItemText()}</em>
                        </MenuItem>
                        {extractionsForDataTypes?.map(el => {
                          return (
                            <MenuItem
                              value={el?.extraction}
                              key={el?.extraction}
                            >
                              <Typography style={{ fontSize: '13px' }}>
                                {el?.extractionName + ' | ' + el?.model}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {!!errors.extractionType && (
                        <p className={styles['error-msg']}>
                          {errors.extractionType.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
            </form>
            {showLabelsForExtraction && (
              <div className={styles['pipeline-content__labels-row']}>
                {renderAddLabels()}
              </div>
            )}
          </div>
        </DialogContent>
        {/* <DialogActions> */}
        <div className={styles['action-buttons-row']}>
          <Button
            className={styles['action-buttons-row__btn']}
            onClick={() => {
              if (props.pipelineFields) {
                setShowPipelineForm(false);
              } else {
                props.setModalClose();
              }
            }}
            autoFocus
          >
            Back
          </Button>
          <Button
            className={[
              styles['action-buttons-row__btn'],
              styles['action-buttons-row__btn--blue'],
            ].join(' ')}
            onClick={handleAddPipeline}
            autoFocus
          >
            Attach Enrichment
          </Button>
        </div>
        {/* </DialogActions> */}
      </>
    );
  };

  return (
    <Dialog
      open={props.isPipelineModalOpen || false}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={styles['pipelines-dialog']}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-title">
        <div className={styles['pipelines-dialog__heading']}>Enrichments</div>
      </DialogTitle>
      {props.pipelineFields && !showPipelineForm
        ? listPipelines()
        : editPipeline()}
    </Dialog>
  );
};

export default SchemaPipelineModal;
