import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [disable, setDisable] = React.useState(false);

  React.useEffect(() => {
    setOpen(props.showAlertForEdit);
    setDisable(false);
  }, [props.showAlertForEdit]);

  const handleClose = () => {
    setOpen(false);
    props.setOpen();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          {props.enableQueryBuilder ? 'Enable Query Editor' : 'Enable Query Builder'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.enableQueryBuilder ? 'If you enable, Query Builder will be disabled. Are you sure to continue' : 'If you enable, Query Editor will be disabled. Are you sure to continue'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} autoFocus>No</Button>
          <Button disabled={disable} variant="contained" onClick={() => { props.onConfirmEdit(); setDisable(true); }}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
