/* eslint-disable */
import React, { useState, useCallback, useEffect, useRef } from 'react';
// import { Path } from '../types';
import BooleanType from '../object/BooleanType';
import ObjectType from '../object/ObjectType';
import { DataType, getType, cast } from '../object/DataType';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { dataSlice } from '../features/data/dataSlice';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import InsightsIcon from '@mui/icons-material/Insights';
import SchemaPipelineModal from './SchemaPipelineModal';

export const sampleJsonForDate = '<date>';

export const ValueEditor = ({
  path,
  schemaDataType,
  defaultValue,
  onCancel,
  onUpdate,
  onUpdateFilter,
  onUpdateFTSFilter,
  schemaInputTypeList,
  getTypeRawJson,
  fetchAllEmbeddedSchemaTypes,
  getPipelinesExtractTypes,
  pipelinesExtractTypes,
  handleAttachPipeline,
  handleRemovePipeline,
  pipelineFields,
  hideFilters = false,
}) => {
  // Value
  const [value, setValue] = useState(defaultValue);
  const [ftstoggle, setToggle] = useState('Off');
  const [filtertoggle, setFilterToggle] = useState('Off');
  const [showPipelineModal, setShowPipelineModal] = useState(false);

  const filter = useSelector(state => state.data.filter);
  const ftsfilter = useSelector(state => state.data.ftsfilter);
  const displayTextFieldPath = useSelector(
    state => state.data.displayTextField
  );
  const displayImageFieldPath = useSelector(
    state => state.data.displayImageField
  );
  const pathDetails = useSelector(state => state.data?.editMode?.path);
  // const dataTypes = useSelector(state => state.schema.schemaDetails[0].schemaDataTypes);
  const dataTypes = useSelector(state => state.data.newSchemaDataTypes);
  const [showOverlay, setShowOverlay] = useState(false);

  const dispatch = useDispatch();
  const {
    setAlterSchemaDataTypes,
    setDisplayTextField,
    setDisplayImageField,
  } = dataSlice.actions;

  useEffect(() => {
    if (filter && filter.includes(sanitizePath(path))) {
      setFilterToggle('On');
    } else {
      setFilterToggle('Off');
    }
    if (ftsfilter && ftsfilter.includes(sanitizePath(path))) {
      setToggle('On');
    } else {
      setToggle('Off');
    }
  }, []);

  const sanitizePath = path => {
    if (!path) {
      return null;
    }
    return path.join('.').replace(/.0/g, '[0]');
  };

  const displayTextFieldHandler = checked => {
    dispatch(
      setDisplayTextField({
        displayTextField: checked ? sanitizePath(path) : null,
      })
    );
  };

  const displayImageFieldHandler = checked => {
    dispatch(
      setDisplayImageField({
        displayImageField: checked ? sanitizePath(path) : null,
      })
    );
  };

  useEffect(() => {
    const arrayToObj = Object.assign({}, path);
    if (Object.keys(arrayToObj).length > 0) {
      const res = Object.values(arrayToObj);
      const keyToCompare = res.join('.');
      dataTypes &&
        Object.keys(dataTypes).map(el => {
          if (el === keyToCompare) {
            const dataTypeToStore = dataTypes[el];
            setType(dataTypeToStore);
          }
        });
    }
  }, [pathDetails]);

  const onValueChanged = useCallback(
    event => {
      // set value
      const { value: newValue } = event.target;
      setValue(newValue);
    },
    [setValue]
  );

  const getDefaultSchemaForType = async dataType => {
    console.log('getDefaultSchemaForType', dataType);
    const schema = schemaInputTypeList.find(s => s.name === dataType);
    if (!schema) {
      return null;
    }
    if (schema.primitive) {
      return schema.default;
    }
    setShowOverlay(true);
    try {
      return await getTypeRawJson(schema.id);
    } finally {
      setShowOverlay(false);
    }
  };

  // Type
  const [type, setType] = useState(getType(defaultValue));
  const onTypeChanged = useCallback(
    async event => {
      const newType = event.target.value;
      const key = path.toString();
      let defaultSchema = await getDefaultSchemaForType(newType);
      const lowCaseNewType = newType.toLowerCase();
      if (
        lowCaseNewType == 'image' ||
        lowCaseNewType == 'audio' ||
        lowCaseNewType == 'video' ||
        lowCaseNewType == 'text'
      ) {
        defaultSchema = [defaultSchema];
      }

      console.log('***path: ', path, ', schema: ', defaultSchema);
      onUpdate(path, defaultSchema, defaultSchema);
      dispatch(setAlterSchemaDataTypes({ key, type: newType }));
      setValue(cast(newType, value));
      setType(newType);
      console.log('newType: ', newType, ' ,key: ', key, ' ,value: ', value);
      return;
    },
    [value, setType]
  );

  // onFilterToggleChanged
  const onFilterToggleChanged = useCallback(
    event => {
      const newType = event.target.value;
      setFilterToggle(newType);
    },
    [filtertoggle]
  );

  // ToggleChanged
  const onToggleChanged = useCallback(
    event => {
      const newType = event.target.value;
      // if (newType === 'On') {
      //   window.alert(`Key : ${path} and Data Type : ${type}`);
      // }
      setToggle(newType);
    },
    [ftstoggle]
  );

  const onOKClicked = useCallback(() => {
    // window.alert(
    //   `Selected Key : ${path.toString().replaceAll(',', '->')}
    //     Data Type : ${type},
    //     Filter : ${filtertoggle},
    //     FTS Filter : ${ftstoggle}`
    // );
    onUpdate(path, cast(type, value));
    const key = path.toString();
    if (filtertoggle === 'On' || filtertoggle === 'Off')
      onUpdateFilter({ key, filtertoggle });
    if (ftstoggle === 'On' || ftstoggle === 'Off')
      onUpdateFTSFilter({ key, ftstoggle });
    dispatch(setAlterSchemaDataTypes({ key, type }));
  }, [
    onUpdate,
    onUpdateFilter,
    onUpdateFTSFilter,
    path,
    type,
    value,
    ftstoggle,
    filtertoggle,
  ]);
  const onCancelClicked = useCallback(() => {
    onCancel();
  }, [onCancel, ftstoggle]);

  // Boolean (checkbox)
  const onCheckboxClicked = useCallback(
    event => {
      setValue(event.target.checked);
    },
    [setValue]
  );

  // Focus on editing
  const textFieldRef = useRef(null);
  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [textFieldRef, type]);

  // Keyboard handling
  const [keyCode, setKeyCode] = useState(null);
  const onKeyDown = useCallback(event => {
    setKeyCode(event.keyCode);
  }, []);

  const onKeyUp = useCallback(
    event => {
      if (
        (event.key === 'Enter' || event.keyCode === 13) &&
        keyCode === event.keyCode
      ) {
        onOKClicked();
        event.preventDefault();
      } else if (
        (event.key === 'Escape' || event.keyCode === 27) &&
        keyCode === event.keyCode
      ) {
        onCancelClicked();
        event.preventDefault();
      } else {
        //
      }
    },
    [onOKClicked, onCancelClicked, keyCode]
  );

  const renderSchemaPipelineModal = () => {
    return showPipelineModal ? (
      <SchemaPipelineModal
        path={sanitizePath(path)}
        setModalClose={() => setShowPipelineModal(false)}
        isPipelineModalOpen={showPipelineModal}
        getPipelinesExtractTypes={getPipelinesExtractTypes}
        pipelinesExtractTypes={pipelinesExtractTypes}
        handleAttachPipeline={handleAttachPipeline}
        handleRemovePipeline={handleRemovePipeline}
        pipelineFields={pipelineFields?.[sanitizePath(path)]}
      />
    ) : (
      <></>
    );
  };

  return (
    <>
      <div className="value-editor mr-1">
        {type === DataType.Object && (
          <>
            <ObjectType
              data={cast(type, defaultValue)}
              path={[]}
              insert={false}
              hideFilters={hideFilters}
              schemaInputTypeList={schemaInputTypeList}
              getTypeRawJson={getTypeRawJson}
              fetchAllEmbeddedSchemaTypes={fetchAllEmbeddedSchemaTypes}
            />
          </>
        )}
        {type === DataType.Array && (
          <ObjectType
            data={cast(type, defaultValue)}
            path={[]}
            insert={false}
            hideFilters={hideFilters}
            schemaInputTypeList={schemaInputTypeList}
            getTypeRawJson={getTypeRawJson}
            fetchAllEmbeddedSchemaTypes={fetchAllEmbeddedSchemaTypes}
          />
        )}
        {type === DataType.String && (
          <input
            type="text"
            className="text-editor form-control form-control-sm"
            value={cast(type, value)}
            onChange={onValueChanged}
            ref={textFieldRef}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
          />
        )}
        {type === DataType.Number && (
          <input
            type="number"
            className="text-editor form-control form-control-sm"
            value={cast(type, value)}
            onChange={onValueChanged}
            ref={textFieldRef}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
          />
        )}
        {type === DataType.Boolean && (
          <BooleanType
            data={cast(type, value)}
            readOnly={false}
            onChange={onCheckboxClicked}
          />
        )}
        {type === DataType.Null && <span className="null">null</span>}
        <div className="actions-row">
          {!hideFilters && (
            <>
              <div className="action-item">
                <select
                  value={type}
                  onChange={onTypeChanged}
                  className="form-control form-control-sm type-selector"
                >
                  {schemaInputTypeList.map(type => (
                    <option
                      value={type.name}
                      key={type.name}
                      style={{ textTransform: 'capitalize' }}
                    >
                      {type.name}
                    </option>
                  ))}
                </select>
                <RefreshIcon
                  className="refresh"
                  onClick={fetchAllEmbeddedSchemaTypes}
                />
              </div>
              {type !== DataType.Array && type !== DataType.Object && (
                <div className="action-item">
                  <label style={{ fontFamily: 'inherit' }}>Filter :</label>
                  <select
                    value={filtertoggle}
                    onChange={onFilterToggleChanged}
                    className="form-control form-control-sm type-selector"
                  >
                    <option value="Off">Off</option>
                    <option value="On">On</option>
                  </select>
                </div>
              )}
            </>
          )}
          {!hideFilters && type === DataType.String && (
            <>
              <div className="action-item">
                <label style={{ fontFamily: 'inherit' }}>FTS :</label>
                <select
                  value={ftstoggle}
                  onChange={onToggleChanged}
                  className="form-control form-control-sm type-selector inline"
                >
                  <option value="Off">Off</option>
                  <option value="On">On</option>
                </select>
              </div>
              <div className="action-item">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="displayTextField"
                      type="checkbox"
                      color="primary"
                      size="small"
                      checked={displayTextFieldPath === sanitizePath(path)}
                      onChange={e => displayTextFieldHandler(e.target.checked)}
                    />
                  }
                  label="Display Text"
                  style={{ fontSize: '15px' }}
                />
              </div>
              <div className="action-item">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="displayImageField"
                      type="checkbox"
                      color="primary"
                      size="small"
                      checked={displayImageFieldPath === sanitizePath(path)}
                      onChange={e => displayImageFieldHandler(e.target.checked)}
                    />
                  }
                  label="Display Image"
                  style={{ fontSize: '15px' }}
                />
              </div>
              <div className="action-item" title="Attach Enrichment">
                <InsightsIcon
                  className="icon-btn"
                  onClick={() => setShowPipelineModal(true)}
                />
              </div>
            </>
          )}
        </div>
        <span className="buttons">
          <button
            className="ok-button btn btn-sm btn-link"
            onClick={onOKClicked}
          >
            <i className="fas fa-check-circle" />
          </button>
          <button
            className="cancel-button btn btn-sm btn-link"
            onClick={onCancelClicked}
          >
            <i className="fas fa-times-circle" />
          </button>
        </span>
      </div>
      {renderSchemaPipelineModal()}
    </>
  );
};
