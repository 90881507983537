import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import Header from '@containers/common/Header';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { getVideoViewsCount } from '@reducers/videoViews';
// import { setSelectedFriend } from '@reducers/friendsFans';

class ScrollToTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // showHeader: false
    };
  }

  componentDidMount() {
    // if(window.localStorage.getItem('userDetails') && JSON.parse(window.localStorage.getItem('userDetails')).userSurrogateId !== null && JSON.parse(window.localStorage.getItem('userDetails')).userSurrogateId !== undefined) {
    //   this.props.getVideoViewsCount();
    // }
    // this.setHeader();
  }

  componentDidUpdate() {
    this.props.currentUrl(window.location.pathname);
  }

  render() {
    return (
      <>
        {this.props.children}
      </>
    );
  }
}

const mapStateToProps = () => {
  return {
    // views: state.videoViews.viewsCount,
    // reactions: state.videoViews.reactionCount,
    // credits: state.videoViews.creditsCount,
    // selectedFriend: state.friendsFans.selectedFriend
  };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
  // getVideoViewsCount,
  // setSelectedFriend

}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScrollToTop));
